import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { useApi } from "../../utils/Api";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import YesOrNoDialog from "../YesOrNoDialog";

export default function UnpackInOrderLineDialog({
  inOrderLineId,
  part,
  orderedQty,
  receivedQty,
}) {
  orderedQty = orderedQty ? orderedQty : 0;
  receivedQty = receivedQty ? receivedQty : 0;

  const [formData, setFormData] = useState({ qty: orderedQty - receivedQty });
  const [locations, setLocations] = useState([]);
  const [showStoreQuestion, setShowStoreQuestion] = useState(false);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  const closeAndReload = useCallback(() => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  }, [navigate, location]);

  const close = useCallback(() => {
    navigate(-1);
  }, [navigate, location]);

  const storeInOrderLine = useCallback(
    async (inOrderLineId, locationId, qty) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}inorders/lines/store`,
        { inOrderLineId, locationId, delQty: qty },
        "POST"
      );
      if (response.isSuccessful) {
        closeAndReload();
      }
    },
    [api, closeAndReload]
  );

  useEffect(() => {
    async function loadData() {
      const locationsResponse = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/locations`,
        false,
        "GET"
      );
      if (locationsResponse.isSuccessful) {
        setLocations(locationsResponse.value);
      }
    }
    loadData();
  }, [api]);

  return (
    // <Dialog
    //   maxWidth={false}
    //   scroll="paper"
    //   open={true}
    //   onClose={onClose}
    //   className="mediumCourseDialog"
    // >
    <>
      <DialogTitle>{Translate.get("UnpackInOrder")}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={3}>
          <Stack direction="column" spacing={2}>
            <Box>{`${Translate.get("PartLabel")}: ${part?.description}`}</Box>
            <Box>{`${Translate.get("OrderedQuantity")}: ${orderedQty}`}</Box>
            <Box>{`${Translate.get("AlreadyReceived")}: ${receivedQty}`}</Box>
          </Stack>
          <FormControl fullWidth>
            <InputLabel id="location-label">
              {Translate.get("ToStUnitLocation")}
            </InputLabel>
            <Select
              labelId="location-label"
              id="location-select"
              value={formData.locationId ?? ""}
              label={Translate.get("ToStUnitLocation")}
              onChange={(event) =>
                setFormData((prev) => ({
                  ...prev,
                  locationId: event.target.value,
                }))
              }
            >
              {locations &&
                locations.map((x) => (
                  <MenuItem key={x.locationId} value={x.locationId}>
                    {x.area}
                    {x.rack ? ` (${x.rack.trim()})` : ""}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            value={formData.qty ?? ""}
            onChange={(event) =>
              setFormData((prev) => ({
                ...prev,
                qty: event.target.value,
              }))
            }
            label={Translate.get("Quantity")}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              type: "number",
              min: 0,
              max: orderedQty - receivedQty,
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{Translate.get("Cancel")}</Button>
        <Button onClick={() => setShowStoreQuestion(true)}>
          {Translate.get("Ok")}
        </Button>
      </DialogActions>
      <YesOrNoDialog
        open={showStoreQuestion}
        text={Translate.get("SureStoreInOrderLine")
          .replace("{qty}", formData.qty)
          .replace("{location}", formData.locationId)}
        onYes={() =>
          storeInOrderLine(inOrderLineId, formData.locationId, formData.qty)
        }
        onNo={() => setShowStoreQuestion(false)}
      />
    </>
    // </Dialog>
  );
}
