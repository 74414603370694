import { Divider, Grid } from "@mui/material";
import Translate from "../../utils/Translate";
import StUnitField from "../orders/StUnitField";
import InOrderGridItem from "./InOrderGridItem";

export default function PartDetailsPanel({
  part,
  stUnits,
  inOrders,
  needInOrder,
  onShowInOrder,
}) {
  return (
    <Grid container padding={2} spacing={2} direction="column">
      {(needInOrder || (inOrders && inOrders.length > 0)) && (
        <Grid item container spacing={1} xs={12} direction="column">
          <Grid item>
            <h4>{Translate.get("InOrdersLabel")}</h4>
          </Grid>
          {inOrders && inOrders.length > 0 && (
            <Grid item container spacing={1}>
              {inOrders.map((x, index) => (
                <Grid item container key={x.inOrderId}>
                  <InOrderGridItem
                    inOrder={x}
                    part={part}
                    onShowInOrder={onShowInOrder}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {(!inOrders || inOrders.length === 0) && <Grid item>-</Grid>}
        </Grid>
      )}

      <Grid item container spacing={2} direction="column">
        <Grid item>
          <h4>{Translate.get("StorageInfoLabel")}</h4>
        </Grid>

        {stUnits && stUnits.length > 0 && (
          <Grid item container spacing={1}>
            {stUnits.map((s) => (
              <Grid item key={s.stUnitId}>
                <StUnitField {...s} />
              </Grid>
            ))}
          </Grid>
        )}
        {(!stUnits || stUnits.length === 0) && <Grid item>-</Grid>}
      </Grid>
    </Grid>
  );
}
