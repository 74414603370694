import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import TooltipFields from "../TooltipFields";

export default function StUnitField({
  qty,
  isUnlimitedQty,
  locationArea,
  blocked,
  revision,
  printing,
}) {
  return (
    <Tooltip
      title={
        <TooltipFields
          fields={[
            {
              label: Translate.get("Quantity"),
              value: isUnlimitedQty ? "∞" : qty,
            },
            {
              label: Translate.get("StUnitAreaLabel"),
              value: locationArea ?? "-",
            },
            {
              label: Translate.get("StUnitRevisionLabel"),
              value: revision ?? "-",
            },
            {
              label: Translate.get("StUnitPrintingLabel"),
              value: printing ?? "-",
            },
            {
              label: Translate.get("Status"),
              value: blocked
                ? Translate.get("StUnitStatusBlocked")
                : Translate.get("Ok"),
            },
          ]}
          width="150px"
        />
      }
      arrow
    >
      <Stack
        className="stUnitField"
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Box className={"stUnitStatus " + (blocked ? "blocked" : "ok")}></Box>
        <Box>{isUnlimitedQty ? "∞" : qty}</Box>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {locationArea ?? "??"}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
