import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useState } from "react";

export default function ContactEmailDetailsDialog({
  email: emailProp,
  name: nameProp,
  onCancel,
  onSave,
}) {
  const [name, setName] = useState(nameProp);
  const [email, setEmail] = useState(emailProp);
  return (
    <Dialog onClose={onCancel} open maxWidth={false} scroll="paper">
      <DialogTitle>{Translate.get("EditContactDetails")}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={2} minWidth="300px">
          <TextField
            fullWidth
            value={name ?? ""}
            onChange={(event) => setName(event.target.value)}
            label={Translate.get("Name")}
          />
          <TextField
            fullWidth
            value={email ?? ""}
            onChange={(event) => setEmail(event.target.value)}
            label={Translate.get("Email")}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{Translate.get("Cancel")}</Button>
        <Button onClick={() => onSave({ name, email })}>
          {Translate.get("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
