import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import Logo from "../../components/Logo";
import Help from "../../pages/Help";
import LanguagePopover from "./LanguagePopover";
import { accessKeys, hasAccess, hasSomeAccess } from "../../utils/userAccess";

// ----------------------------------------------------------------------
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 1),
  [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  justifyContent: "space-between",
  [theme.breakpoints.up("sm")]: {
    minHeight: APPBAR_DESKTOP,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    //padding: theme.spacing(0, 5),
    justifyContent: "space-between",
  },
}));

export default function DashboardNavbarDlc({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        {hasAccess(accessKeys.digitalLicensesHolder) ? (
          <IconButton
            sx={{ padding: 0 }}
            className="toolBarBurger"
            onClick={onOpenSidebar}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        ) : (
          <div></div>
        )}
        <Logo sx={{ flexGrow: 1 }} navigateTo="d/licenses" />
        <Stack className="toolBarStack" direction="row" alignItems="center">
          {/* <NotificationsPopover /> */}
          {/* <Help /> */}
          <AccountPopover
            hideLogout={hasSomeAccess(
              accessKeys.digitalLicensesContact,
              accessKeys.digitalLicensesPublic
            )}
          />
          {/* <LanguagePopover isPreLogin={false} /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
