import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useMemo } from "react";
import Translate from "../../../utils/Translate";
import ImportActorsDataGrid from "./ImportActorsDataGrid";

export default function ImportActorsResultDialog({
  open,
  failedActorImports,
  onClose,
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>{Translate.get("SomethingFailed")}</DialogTitle>
      <DialogContent>
        {failedActorImports && failedActorImports.length > 0 && (
          <ImportActorsDataGrid
            actors={failedActorImports}
            description={Translate.get("FailedImportActors")}
            isErrorResult={true}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {Translate.get("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
