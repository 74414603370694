import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import Translate from "../../utils/Translate";
import { ArrowBack } from "@mui/icons-material";

export default function ConfirmationPage(props) {
  const { id: orderId } = useParams();
  const isExtraSmall = useMediaQuery(`(max-width:400px)`);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingBottom="200px"
      gap={1}
    >
      {isExtraSmall ? (
        <h2>{Translate.get("ThankYouForYourOrder")}</h2>
      ) : (
        <h1>{Translate.get("ThankYouForYourOrder")}</h1>
      )}

      <p>{Translate.get("WeWillSendAnOrderConfirmationEmail")}</p>
      <Link to="/d/licenses" style={{ textDecoration: "none" }}>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" paddingTop={3}>
            <ArrowBack />
            <Box>{Translate.get("BackToMyDigitalLicenses")}</Box>
          </Stack>
        </Typography>
      </Link>
    </Box>
  );
}
