import Translate from "../../utils/Translate";
import { DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";

export default function PreviewCompetences({ skills, onClose }) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={false}
      scroll="paper"
      style={{ top: "63px", bottom: "10px" }}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle>
        {Translate.get("EducatedOn")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="dlcCompetenceDialog">
          <div className="iconTextRows dlcBlueBoxes">
            {skills?.practicalSkills && skills.practicalSkills.length > 0 && (
              <>
                <div className="dlcTitle">
                  {Translate.get("ApprovedPracticalSkills")}
                </div>
                {skills.practicalSkills.map((row, index) => (
                  <div key={index}>
                    <div>
                      <img src={row.imageUrl} style={{ height: "16px" }} />
                    </div>
                    <div>{`${row.shortCode} - ${row.description}`}</div>
                  </div>
                ))}
              </>
            )}

            {skills?.theoreticalSkills &&
              skills.theoreticalSkills.length > 0 && (
                <>
                  <div className="dlcTitle">
                    {Translate.get("ApprovedTheorySkills")}
                  </div>
                  {skills.theoreticalSkills.map((row, index) => (
                    <div key={index}>
                      <div>
                        <img src={row.imageUrl} style={{ height: "16px" }} />
                      </div>
                      <div>{`${row.shortCode} - ${row.description}`}</div>
                    </div>
                  ))}
                </>
              )}
          </div>
          <div className="iconTextRows dlcGrayBoxes">
            <div className="dlcTitle">{Translate.get("OtherCompetencies")}</div>
            {skills.otherSkills &&
              skills.otherSkills.map((row, index) => (
                <div key={index}>
                  <div>
                    <img src={row.imageUrl} style={{ height: "16px" }} />
                  </div>
                  <div>{`${row.shortCode} - ${row.description}`}</div>
                </div>
              ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
