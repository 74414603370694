import React, { useState, useEffect } from 'react';
import Translate from '../../utils/Translate';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useApi } from '../../utils/Api';
import { useSnackbar } from "notistack";
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import TextField from '@mui/material/TextField';

export function SelfRegSettings(props) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [regSettings, setRegSettings] = useState([]);

  const [rerender, setRerender] = useState(false);
  const api = useApi();

  useEffect(async () => {
    let res = await getSelfRegFields();
    setRegSettings(res.selfRegFields);
  }, []);

  async function getSelfRegFields() {
    props.setIsLoading(true);
    let response = null;
    try {
      response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}dataIdSettings/selfReg/regFields`,
        false,
        'GET'
      );
      props.setIsLoading(false);
      if (response.isSuccessful) {
        return response;
      }
      
    }finally {
      props.setIsLoading(false);
    }
  }

  async function PostSelfRegFields() {
    props.setIsLoading(true);
    let response = null;
    let m = new Map();

    regSettings.forEach(function (item, index) {
      if (item.markedAsVisible) {
        m.set(item.fieldName, item.markedAsRequired);
      }
    });
    let mySerialMap = Object.fromEntries(m);
    let reqObj = { regFields: mySerialMap };

    try {
      response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}dataIdSettings/selfReg/setRegFields`,
        reqObj,
        'POST',
        false,
        true
      );
      props.setIsLoading(false);
    } catch (error) {
      const errorMessage = enqueueSnackbar(
        Translate.get(response.errorMessageTranslationKey || "SomethingFailed"),
        {
          variant: "error",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(errorMessage),
        }
      );
      props.setIsLoading(false);
    }
    return response;
  }

  function updateVisible(name, checked) {
    let rs = regSettings;
    let index = rs.findIndex(object => {
      return object.fieldName === name;
    });

    rs[index].markedAsVisible = checked;
    if (checked === false) {
      rs[index].markedAsRequired = false;
    }
    return rs;
  }

  function updateMandatory(name, checked) {
    let rs = regSettings;
    let index = rs.findIndex(object => {
      return object.fieldName === name;
    });

    rs[index].markedAsRequired = checked;
    return rs;
  }

  async function handleShowChange(event) {
    let arr = updateVisible(event.target.name, event.target.checked);
    setRegSettings(arr);
    setRerender(!rerender);
    let p = await PostSelfRegFields();
    if (!p.isSuccessful) {
      let res = await getSelfRegFields();
      setRegSettings(res.selfRegFields);
    }
  }

  async function handleMandatoryChange(event) {
    let arr = updateMandatory(event.target.name, event.target.checked);
    setRegSettings(arr);
    setRerender(!rerender);
    let p = await PostSelfRegFields();
    if (!p.isSuccessful) {
      let res = await getSelfRegFields();
      setRegSettings(res.selfRegFields);
    }
  }

  function GetTranslationOrOverride(translationId) {
    switch (translationId) {
      case "OrgNo":
        return Translate.get("PersonalNo")
      case "ActorEmail":
        return Translate.get("Email");
      case "ExternalActorNo":
        return Translate.get("EmployeeNo");
      case "ActorImage":
        return Translate.get("ProfilePicture");
      case "ActorAddressStreet2":
        return Translate.get("Address2");
      case "ActorAddressCity":
        return Translate.get("City");
      case "ActorAddressZipCode":
        return Translate.get("ZipCode");
      case "ActorFreeText":
        return Translate.get("FreeText");
      case "BankId":
        return Translate.get("BankIdAuthentication");
      case "Nationality":
        return Translate.get("Nationality")
      default:
        return Translate.get(translationId);
    }
  }

  return (
    <div>
      <Typography
        variant="body2"
      >
        {Translate.get("ChooseFields")}
      </Typography>
      <br />


      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <Typography variant="subtitle2" gutterBottom>
            {Translate.get("Standard").toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {Translate.get("Compulsory").toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <FormGroup>
        {regSettings.filter(r => r.required).map(setting => <div key={setting.name + 1}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={10}>
              <Switch checked={setting.markedAsVisible} onChange={handleShowChange} name={setting.fieldName} disabled={setting.required} />
              {GetTranslationOrOverride(setting.fieldName)}
            </Grid>
            <Grid item xs={2}>
              <Checkbox checked={setting.markedAsRequired} onChange={handleMandatoryChange} name={setting.fieldName} disabled={setting.required} />
            </Grid>
          </Grid>
        </div>
        )}
      </FormGroup>
      <br />

      <p style={{ marginBottom: "0.5em" }}>{Translate.get("MarkAtLeastOneField")}</p>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <Typography variant="subtitle2" gutterBottom>
            {Translate.get("KeyField").toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {Translate.get("Compulsory").toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <FormGroup>
        {regSettings.filter(r => r.optionallyRequired).map(setting => <div key={setting.fieldName + 2}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={10}>
              <Switch checked={setting.markedAsVisible} onChange={handleShowChange} name={setting.fieldName} disabled={setting.required} />
              {GetTranslationOrOverride(setting.fieldName)}
            </Grid>
            <Grid item xs={2}>
              <Checkbox checked={setting.markedAsRequired} onChange={handleMandatoryChange} name={setting.fieldName} disabled={setting.markedAsVisible !== true} />
            </Grid>
          </Grid>
        </div>
        )}
      </FormGroup>

      <br />

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <Typography variant="subtitle2" gutterBottom>
            {Translate.get("Facultative").toUpperCase()}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2" gutterBottom>
            {Translate.get("Compulsory").toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <FormGroup>
        {regSettings.filter(r => !r.required && !r.optionallyRequired).map(setting => <div key={setting.fieldName + 3}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={10}>
              <Switch checked={setting.markedAsVisible} onChange={handleShowChange} name={setting.fieldName} disabled={setting.required} />
              {GetTranslationOrOverride(setting.fieldName)}
            </Grid>
            <Grid item xs={2}>
              <Checkbox checked={setting.markedAsRequired} onChange={handleMandatoryChange} name={setting.fieldName} disabled={setting.markedAsVisible !== true || setting.fieldName === "BankId"} />
            </Grid>
          </Grid>
        </div>
        )}
      </FormGroup>
    </div>
  );
}
