import { useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import { useNavigate, useParams } from "react-router-dom";
import Translate from "../../utils/Translate";
import DigitalLicenseListItem from "../../components/digital-licenses/DigitalLicenseListItem";
import Preloader from "../../components/Preloader";

export default function MyDigitalLicenses({ isPublishGroup }) {
  const [licenses, setLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();
  const navigate = useNavigate();
  const { id: digitalLicensePublishGroupId } = useParams();

  useEffect(() => {
    async function loadLicenses() {
      setIsLoading(true);
      const url = isPublishGroup
        ? `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/groups/${digitalLicensePublishGroupId}`
        : `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses`;
      const response = await api.fetch(url, false, "GET");
      if (response.isSuccessful) {
        setLicenses(response.licenses);
      }
      setIsLoading(false);
    }

    loadLicenses();
  }, [api, isPublishGroup, digitalLicensePublishGroupId]);

  return (
    <>
      {isLoading && <Preloader />}
      <div className="dlcPageWrapper">
        <div className="dlcHeading1">
          {isPublishGroup
            ? Translate.get("ContactDigitalLicensesHeader")
            : Translate.get("MyDigitalLicenses")}
        </div>
        <div className="dlcPage">
          {licenses &&
            licenses.map((license) => (
              <DigitalLicenseListItem
                key={license.digitalLicenseId}
                license={license}
                showDigitalPermitButton={false}
                isRestrictedView={isPublishGroup}
              />
            ))}
          <div></div>
        </div>
      </div>
    </>
  );
}
