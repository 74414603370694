import Api from "./Api";

let parsedTranslations;
const Translate = {};

function getLoadedTranslations() {
  if (!parsedTranslations && sessionStorage.translation) {
    parsedTranslations = JSON.parse(sessionStorage.translation);
  }
  return parsedTranslations;
}

async function loadTranslations(url, reload = false) {
  const data = await Api.fetch(url, false, "GET");
  if (data) {
    const newTranslations = reload
      ? data
      : { ...getLoadedTranslations(), ...data };
    parsedTranslations = newTranslations;
    sessionStorage.setItem("translation", JSON.stringify(newTranslations));
  }
}

function getOrStoreLanguage(lang) {
  if (lang) {
    localStorage.setItem("lang", lang);
    return lang;
  }

  let storedLanguage = localStorage.getItem("lang");
  if (storedLanguage) {
    return storedLanguage;
  }

  let hostname = window.location.hostname;
  let hostParts = hostname.split(".");
  if (hostParts.length > 2) {
    // ta bara de sista två delarna, d.v.s t.ex. app.kortillstand.se => kortillstand.se
    hostname = hostParts.slice(hostParts.length - 2).join(".");
  }

  switch (hostname) {
    case "eport.se":
    case "kortillstand.se":
    case "instructorportal.se":
      localStorage.setItem("lang", 29);
      return 29;
    case "eportcloud.com":
    default:
      localStorage.setItem("lang", 9);
      return 9;
  }
}

Translate.loadPreLogin = (lang = false) => {
  const language = getOrStoreLanguage(lang);
  return loadTranslations(
    `${process.env.REACT_APP_MAIN_URL}translations/courseadmin/preLogin/${language}`,
    true
  );
};

Translate.loadFlyer = (lang = false) => {
  const language = getOrStoreLanguage(lang);
  return loadTranslations(
    `${process.env.REACT_APP_MAIN_URL}translations/courseadmin/flyer/${language}`,
    false
  );
};

const loadDigitalLicensesTranslation = (lang = false) => {
  const language = getOrStoreLanguage(lang);
  return loadTranslations(
    `${process.env.REACT_APP_MAIN_URL}translations/digitallicenses/${language}`,
    true
  );
};

const loadCourseAdminTranslations = (lang = false) => {
  const language = getOrStoreLanguage(lang);
  return loadTranslations(
    `${process.env.REACT_APP_MAIN_URL}translations/courseadmin/${language}/user`,
    true
  );
};

Translate.loadApp = (lang = false) => {
  if (window.global.isEcomp) {
    return loadDigitalLicensesTranslation(lang);
  } else {
    return loadCourseAdminTranslations(lang);
  }
};

Translate.loadSelfReg = (lang = false) => {
  const language = getOrStoreLanguage(lang);
  return loadTranslations(
    `${process.env.REACT_APP_MAIN_URL}translations/selfreg/${language}`,
    false
  );
};

Translate.get = (translationKey) => {
  const translations = getLoadedTranslations();
  const translationValue = translations && translations[translationKey];
  return translationValue ?? translationKey + " N/A";
};

Translate.getBrowserLanguage = () => {
  const lang = navigator.language.split("-");
  //console.log("navigator.language", navigator.language, lang);
  switch (lang[0].toLowerCase()) {
    case "sv":
      return 29;
    case "en":
      return 9;
    case "fr":
      return 12;
    case "no":
      return 20;
    default:
      return 9;
  }
};

Translate.getActorAddressType = (addressType) => {
  switch (addressType) {
    case 1:
      return Translate.get("AddressTypeEnum_Billing");
    case 2:
      return Translate.get("AddressTypeEnum_Visiting");
    case 3:
      return Translate.get("AddressTypeEnum_Postal");
    case 4:
      return Translate.get("AddressTypeEnum_Delivery");
    case 5:
      return Translate.get("AddressTypeEnum_Temporary");
    default:
      return Translate.get("StreetAddress");
  }
};

Translate.getActorPhoneType = (phoneType) => {
  switch (phoneType) {
    case 1:
      return Translate.get("PhoneType_Business");
    case 2:
      return Translate.get("PhoneType_Mobile");
    case 3:
      return Translate.get("PhoneType_Home");
    case 4:
      return Translate.get("PhoneType_Fax");
    default:
      return Translate.get("Phone");
  }
};

Translate.getMachineType = (machineType) => {
  switch (machineType) {
    case "Truck":
      return Translate.get("Truck");
    case "Lift":
      return Translate.get("Lift");
    case "Kran":
    case "Crane":
      return Translate.get("Crane");
    case "Fallskydd":
    case "FallProtection":
      return Translate.get("FallProtection");
    default:
      return Translate.get(machineType);
  }
};

Translate.getELearningType = (eLearningType) => {
  switch (eLearningType) {
    case 1:
      return Translate.get("eLearningType_eTruck");
    case 2:
      return Translate.get("eLearningType_eLift");
    case 3:
      return Translate.get("eLearningType_eTruckRepetition");
    case 4:
      return Translate.get("EFall");
    // Using 1000 for extra final exams that aren't eLearningLicenses,
    // but still are intended to be displayed as such in different places
    case 1000:
      return Translate.get("ExtraFinalExam");
    case 24:
      return Translate.get("eLearningType_MultiELiftAndEFall");
    case 0:
    default:
      return Translate.get("Undefined");
  }
};

Translate.getValidTimeDefinition = (validTimeDefinition) => {
  switch (validTimeDefinition) {
    case 1:
      return Translate.get("ValidTimeDefinition_Days");
    case 2:
      return Translate.get("ValidTimeDefinition_Weeks");
    case 3:
      return Translate.get("ValidTimeDefinition_Months");
    case 4:
      return Translate.get("ValidTimeDefinition_Years");
    default:
      return Translate.get("ValidTimeDefinition_Unknown");
  }
};

Translate.getActorType = (actorType) => {
  switch (actorType) {
    case 1:
      return Translate.get("ActorType_Client");
    case 2:
      return Translate.get("ActorType_Supplier");
    case 3:
      return Translate.get("ActorType_Contact");
    case 4:
      return Translate.get("ActorType_Student");
    case 6:
      return Translate.get("ActorType_Teacher");
    case 9:
      return Translate.get("ActorType_PrivateClient");
    default:
      return Translate.get("Unknown");
  }
};

Translate.getOrderStatus = (orderStatus) => {
  switch (orderStatus) {
    case 0:
      return Translate.get("OrderStatus_Canceled");
    case 9:
      return Translate.get("OrderStatus_MissingInfo");
    case 10:
      return Translate.get("OrderStatus_Registered");
    case 11:
      return Translate.get("OrderStatus_RegisteredAutoOrder");
    case 20:
      return Translate.get("OrderStatus_Ordered");
    case 30:
      return Translate.get("OrderStatus_UnderPicking");
    case 35:
      return Translate.get("OrderStatus_WaitingForCourseInvoice");
    case 40:
      return Translate.get("OrderStatus_Picked");
    case 41:
      return Translate.get("OrderStatus_PickedAutoOrder");
    case 50:
      return Translate.get("OrderStatus_WaitingForInvoice");
    case 60:
      return Translate.get("OrderStatus_SentToERP");
    case 65:
      return Translate.get("OrderStatus_InvoicedInERP");
    case 70:
      return Translate.get("OrderStatus_PartlyPaid");
    case 80:
      return Translate.get("OrderStatus_Paid");
    case 90:
      return Translate.get("OrderStatus_Finished");
    case 110:
      return Translate.get("OrderStatus_Credited");
    case 115:
      return Translate.get("OrderStatus_PartiallyCredited");
    default:
      return Translate.get("Unknown");
  }
};

Translate.getInOrderStatus = (inOrderStatus) => {
  switch (inOrderStatus) {
    case 0:
      return Translate.get("InOrderStatus_Canceled");
    case 10:
      return Translate.get("InOrderStatus_UnderRegistration");
    case 20:
      return Translate.get("InOrderStatus_Ordered");
    case 40:
      return Translate.get("InOrderStatus_Received");
    case 60:
      return Translate.get("InOrderStatus_StartedUnpacking");
    case 70:
      return Translate.get("InOrderStatus_FinishedUnpacking");
    default:
      return Translate.get("Unknown");
  }
};

/*
EPortCloudLicenseLineTypeEnum
Undefined = 0,
License = 1,
User = 2,
SMS = 3,
ePortReportClient = 4,
Iframe = 5,
//ePortAssist = 6,
CourseAdminHTML = 7,
ActorPermit = 8,
ActorPermitUser = 9,
CourseAdminReadOnly = 10,
UserReadOnly = 11,

*/
Translate.getEPortLicenseLineType = (lineType) => {
  switch (lineType) {
    case 7:
      return Translate.get("ePortCloudLicenseLineType_7");
    case 8:
      return Translate.get("ePortCloudLicenseLineType_8");
    case 10:
      return Translate.get("ePortCloudLicenseLineType_10");
    default:
      return Translate.get("Undefined");
  }
};

Translate.getVatType = (vatType) => {
  switch (vatType) {
    case 1:
      return Translate.get("VatType_1");
    case 2:
      return Translate.get("VatType_2");
    case 3:
      return Translate.get("VatType_3");
    default:
      return Translate.get("Undefined");
  }
};

Translate.getPartTypePlural = (partType) => {
  switch (partType) {
    case 1:
      return Translate.get("PartType_plural_1");
    case 5:
      return Translate.get("PartType_plural_5");
    case 23:
      return Translate.get("PartType_plural_23");
    case 88:
      return Translate.get("PartType_plural_88");
    default:
      return Translate.get("Undefined");
  }
};

Translate.getCountryName = (alpha2) => {
  return Translate.get(`Country_${alpha2}`);
};

Translate.getPriority = (prio) => {
  return prio > 0
    ? Translate.get(`Priority_${prio}`)
    : Translate.get("Undefined");
};

Translate.getLicenseFormatType = (licenseFormatType) => {
  switch (licenseFormatType) {
    case 1:
      return Translate.get("LicenseFormatType_1");
    case 2:
      return Translate.get("LicenseFormatType_2");
    case 3:
      return Translate.get("LicenseFormatType_3");
    default:
      return Translate.get("Undefined");
  }
};

export default Translate;
