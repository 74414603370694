import Translate from "../../utils/Translate";
import { DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";

export default function PreviewCompany({ company, onClose }) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={false}
      scroll="paper"
      style={{ top: "63px", bottom: "10px" }}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle>
        {Translate.get("TrainingCoordinator")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        {company && (
          <div className="dlcCompanyDialog">
            <div>
              <div>
                <img src={company.logoUrl} className="dlcCompanyLogo" />
              </div>
              <div className="dlcHeading1">{company.companyName}</div>
              <div>{company.companyInfo}</div>
            </div>
            <div>
              {/*  <div className="dlcCompanyLogo"></div> */}
              <div className="iconTextRows">
                <div>
                  <div>
                    <PhoneIcon />
                  </div>
                  <div>{company.phone ?? "-"}</div>
                </div>
                <div>
                  <div>
                    <EmailIcon />
                  </div>
                  <div>{company.emailAddress ?? "-"}</div>
                </div>
                <div>
                  <div>
                    <LanguageIcon />
                  </div>
                  <div>{company.webpage ?? "-"}</div>
                </div>
                <div>
                  <div>
                    <PlaceIcon />
                  </div>
                  <div>
                    {company.addressStreet1 ||
                    company.addressStreet2 ||
                    company.addressZipCode ||
                    company.addressCity ||
                    company.addressCountry ? (
                      <div>
                        <div>{company.addressStreet1}</div>
                        <div>{company.addressStreet2}</div>
                        <div>
                          {company.addressZipCode + " " + company.addressCity}
                        </div>
                        <div>{company.addressCountry}</div>
                      </div>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
