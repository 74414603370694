import { Box, Button, Grid, Stack, Tooltip } from "@mui/material";
import { formatDate } from "../../utils/dateTimeFormat";
import Translate from "../../utils/Translate";
import ArchiveIcon from "@mui/icons-material/Archive";
import ViewListIcon from "@mui/icons-material/ViewList";
import EventIcon from "@mui/icons-material/Event";
import BusinessIcon from "@mui/icons-material/Business";
import DotMenu from "../DotMenu";
import UnpackInOrderLineDialog from "./UnpackInOrderLineDialog";
import { inOrderStatus } from "../../utils/inOrder";
import { Check, DoneAll } from "@mui/icons-material";
import { useCallback } from "react";
import { useApi } from "../../utils/Api";
import { useLocation, useNavigate } from "react-router-dom";

export default function InOrderGridItem({ inOrder, part, onShowInOrder }) {
  const api = useApi();
  const navigate = useNavigate();

  const focusInOrderLine = inOrder.inOrderLines.find(
    (ol) => ol.partId === part.partId
  );

  const finishInOrderLine = useCallback(
    async (inOrderLineId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}inorders/lines/${inOrderLineId}/finish`,
        true,
        "POST"
      );
      if (response.isSuccessful) {
        navigate(0);
      }
    },
    [api, navigate]
  );

  function getDotMenuActions() {
    return [
      {
        icon: <ViewListIcon />,
        text: Translate.get("ShowInOrder"),
        onClick: () => onShowInOrder(inOrder.inOrderId),
      },
      {
        icon: <ArchiveIcon />,
        text: Translate.get("UnpackInOrder"),
        customClass: "mediumCourseDialog",
        component: (
          <UnpackInOrderLineDialog
            inOrderLineId={focusInOrderLine.inOrderLineId}
            part={part}
            orderedQty={focusInOrderLine.qty}
            receivedQty={focusInOrderLine.delQty}
          />
        ),
      },
      {
        icon: <DoneAll />,
        text: Translate.get("MarkFinishedInOrderLine"),
        disabled: focusInOrderLine.status !== inOrderStatus.startedUnpacking,
        onClick: () => finishInOrderLine(focusInOrderLine.inOrderLineId),
      },
    ];
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      className="inOrderField"
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: { xs: "100%", md: "75%", lg: "50%" } }}
    >
      <Stack direction="row" spacing={1} width="40%">
        <Tooltip title={Translate.get("PlannedDelDate")}>
          <EventIcon />
        </Tooltip>
        <Box>
          {focusInOrderLine?.plannedDelDate
            ? formatDate(focusInOrderLine?.plannedDelDate)
            : "-"}
        </Box>
      </Stack>

      <Stack direction="row" spacing={1} width="40%">
        <Tooltip
          title={`${Translate.get("ReceivedQuantity")} / ${Translate.get(
            "OrderedQuantity"
          )}`}
        >
          <ArchiveIcon />
        </Tooltip>
        <Box>{`${focusInOrderLine.delQty ?? 0} / ${
          focusInOrderLine.qty ?? 0
        }`}</Box>
      </Stack>

      <Box
        width="10%"
        display="flex"
        alignItems="center"
        className={
          "iconbox " +
          (focusInOrderLine.status === inOrderStatus.finishedUnpacking
            ? "success"
            : "blue")
        }
      >
        <Tooltip title={Translate.getInOrderStatus(focusInOrderLine.status)}>
          {focusInOrderLine.status === inOrderStatus.finishedUnpacking ? (
            <DoneAll />
          ) : (
            <Check />
          )}
        </Tooltip>
      </Box>

      <Box width="10%" display="flex" justifyContent="flex-end">
        <DotMenu content={getDotMenuActions()} horizontal />
      </Box>
    </Stack>
  );
}
