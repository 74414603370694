export const inOrderStatus = {
  canceled: 0,
  underRegistration: 10,
  ordered: 20,
  adviced: 30,
  received: 40,
  unLoaded: 50,
  startedUnpacking: 60,
  finishedUnpacking: 70,
};
