import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useRef, useState } from "react";
import AddressPrintView from "./AddressPrintView";
import { useReactToPrint } from "react-to-print";

export default function PrintAddressDialog({ address, onClose, open }) {
  const [showAtt, setShowAtt] = useState(address.attention ? true : false);

  const deliveryAddressPrintRef = useRef();
  const handlePrintDeliveryAddress = useReactToPrint({
    content: () => deliveryAddressPrintRef.current,
    onAfterPrint: onClose,
  });

  return (
    <>
      <Dialog
        open={open}
        maxWidth={false}
        onClose={onClose}
        scroll="paper"
        className="smallCourseDialog"
      >
        <DialogTitle>{`${Translate.get("Print")} ${Translate.get(
          "Address"
        ).toLowerCase()}`}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!address.attention}
                  checked={showAtt}
                  onChange={(event) => setShowAtt(event.target.checked)}
                />
              }
              label={Translate.get("ShowReference")}
            />
            <Box>
              <AddressPrintView
                {...address}
                attention={showAtt ? address.attention : null}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
          <Button onClick={handlePrintDeliveryAddress} variant="contained">
            {Translate.get("Print")}
          </Button>
        </DialogActions>
      </Dialog>
      <Box className="printView" ref={deliveryAddressPrintRef}>
        <AddressPrintView
          {...address}
          attention={showAtt ? address.attention : null}
        />
      </Box>
    </>
  );
}
