import { useTheme, useMediaQuery, Box, Dialog } from "@mui/material";
import { useState, useCallback, useEffect, useRef } from "react";
import Translate from "../../utils/Translate";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import { useApi } from "../../utils/Api";
import { useNavigate, useLocation } from "react-router-dom";
import { actorSearch, actorCompanyTypes } from "../../utils/actorSearch";
import YesOrNoDialog from "../YesOrNoDialog";
import Preloader from "../Preloader";
import { AlertDialog } from "../AlertDialog";
import { isFreightPartType, partType } from "../../utils/part";
import InOrderFormContent from "./InOrderFormContent";
import download from "downloadjs";
import AddressDialog from "../orders/AddressDialog";
import InOrderLineDialog from "./InOrderLineDialog";
import { inOrderStatus } from "../../utils/inOrder";
import { useReactToPrint } from "react-to-print";
import GroupEmailDialog from "../emails/GroupEmailDialog";
import { addressType } from "../../utils/address";
import { documentType, mediaType } from "../../utils/sentCommunication";

// We use IDs like crypto.RandomUUID() here in frontend for new orderLines
function isDummyOrderLineId(inOrderLineId) {
  return typeof inOrderLineId !== "number";
}

function getDefaultDeliveryAddress(addresses) {
  return addresses.find(
    (adr) =>
      adr.actorAddressType === addressType.delivery ||
      adr.actorAddressType === addressType.postal
  );
}

function buildOrderDto(inOrder, inOrderLines) {
  const hasTempAddress = inOrder.deliveryAddressId === -1; // -1 means tempAddress
  const deliveryAddressId = hasTempAddress ? null : inOrder.deliveryAddressId;
  const tempAddress = hasTempAddress ? inOrder.tempAddress : null;

  return {
    ...inOrder,
    deliveryAddressId: deliveryAddressId,
    tempAddress: tempAddress,
    inOrderLines: inOrderLines.map((ol) => ({
      ...ol,
      inOrderLineId: !isDummyOrderLineId(ol.inOrderLineId)
        ? ol.inOrderLineId
        : 0,
    })),
  };
}

export default function InOrderFormDialog({
  inOrderId,
  parts: partsProp,
  onClose,
}) {
  if (inOrderId && partsProp) {
    // inOrderId if existing, parts for new
    throw new Error("Use either id or parts");
  }
  const [plannedDelDate, setPlannedDelDate] = useState(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  );
  const [inOrder, setInOrder] = useState({ canUpdate: true });
  const [inOrderLines, setInOrderLines] = useState(
    partsProp
      ? partsProp.map((p) => ({
          inOrderLineId: crypto.randomUUID(),
          status: inOrderStatus.underRegistration,
          partId: p.partId,
          part: { ...p, inOrders: null },
          qty: 1,
          plannedDelDate,
        }))
      : []
  );
  const [hasOrderChanged, setHasOrderChanged] = useState(
    inOrderId ? false : true
  );
  const [hasInitialized, setHasInitialized] = useState(false);
  const [supplierCompany, setSupplierCompany] = useState(null);
  const [supplierContacts, setSupplierContacts] = useState([]);
  const [company, setCompany] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [showTempAddressDialog, setShowTempAddressDialog] = useState(false);
  const [editInOrderLineId, setEditInOrderLineId] = useState(null);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isBig = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDeliveryAddressSelected = useCallback((deliveryAddressId) => {
    if (deliveryAddressId === -1) {
      setShowTempAddressDialog(true);
    }
    setHasOrderChanged(true);
    setInOrder((prevOrder) => ({
      ...prevOrder,
      deliveryAddressId: deliveryAddressId,
    }));
  }, []);

  const handleTempAddressChanged = useCallback((updatedTempAddress) => {
    setHasOrderChanged(true);
    setInOrder((prevOrder) => ({
      ...prevOrder,
      tempAddress: { ...prevOrder.tempAddress, ...updatedTempAddress },
    }));
  }, []);

  const handlePlannedDelDateChanged = useCallback((date) => {
    setHasOrderChanged(true);
    setPlannedDelDate(date);
    setInOrderLines((prev) =>
      prev.map((ol) => ({ ...ol, plannedDelDate: date }))
    );
  }, []);

  const handleAddInOrderLine = useCallback(() => {
    const tempInOrderLineId = crypto.randomUUID();
    const newInOrderLine = {
      inOrderLineId: tempInOrderLineId,
      status: inOrderStatus.underRegistration,
      isTempOrderLine: true,
      plannedDelDate,
    };
    setInOrderLines((prev) => [...prev, newInOrderLine]);
    setEditInOrderLineId(tempInOrderLineId);
    setHasOrderChanged(true);
  }, []);

  const handleEditInOrderLine = useCallback((inOrderLineId) => {
    setEditInOrderLineId(inOrderLineId);
  }, []);

  const handleRemoveInOrderLine = useCallback((inOrderLineId) => {
    setInOrderLines((prev) => {
      const index = prev.findIndex((ol) => ol.inOrderLineId === inOrderLineId);
      if (index !== -1) {
        if (isDummyOrderLineId(inOrderLineId)) {
          // Dummy orderLines have not yet been saved in DB, and
          // hence we can simply delete them rather than cancel them
          return prev.toSpliced(index, 1);
        } else {
          return prev.with(index, {
            ...prev[index],
            qty: 0,
            status: inOrderStatus.canceled,
          });
        }
      }
      return prev;
    });
    setHasOrderChanged(true);
  }, []);

  const loadContacts = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }
      const loadedContacts = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}actors/clients/${actorId}/contacts`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (loadedContacts && Array.isArray(loadedContacts)) {
        return loadedContacts;
      }
    },
    [api]
  );

  const loadAddresses = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }
      const loadedAddresses = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/addresses`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (loadedAddresses && Array.isArray(loadedAddresses)) {
        return loadedAddresses;
      }
    },
    [api]
  );

  const loadCompanyActor = useCallback(
    async (actorId) => {
      const foundCompanies = await actorSearch(
        actorId,
        actorCompanyTypes,
        ["ActorId"],
        1,
        true
      );

      if (foundCompanies && foundCompanies.length === 1) {
        setInOrder((prev) => ({
          ...prev,
          actorId: foundCompanies[0].actorId,
        }));
        setCompany(foundCompanies[0]);
        const [loadedContacts, loadedAddresses] = await Promise.all([
          loadContacts(foundCompanies[0].actorId),
          loadAddresses(foundCompanies[0].actorId),
        ]);
        setContacts(loadedContacts ?? []);
        setAddresses(loadedAddresses ?? []);

        setInOrder((prev) => ({
          ...prev,
          deliveryAddressId:
            inOrderId || prev.inOrderId
              ? prev.deliveryAddressId
              : getDefaultDeliveryAddress(loadedAddresses ?? [])
                  ?.actorAddressId,
        }));
      }
    },
    [loadContacts, loadAddresses, inOrderId]
  );

  const loadSupplierActor = useCallback(
    async (actorId) => {
      const foundCompanies = await actorSearch(
        actorId,
        actorCompanyTypes,
        ["ActorId"],
        1,
        true
      );

      if (foundCompanies && foundCompanies.length === 1) {
        setInOrder((prev) => ({
          ...prev,
          supplierId: foundCompanies[0].actorId,
        }));
        setSupplierCompany(foundCompanies[0]);
        const [loadedContacts, loadedAddresses] = await Promise.all([
          loadContacts(foundCompanies[0].actorId),
        ]);
        setSupplierContacts(loadedContacts ?? []);
      }
    },
    [loadContacts, loadAddresses, inOrderId]
  );

  const getStUnits = useCallback(
    async (partId) => {
      return await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts/${partId}/stunits`,
        false,
        "GET"
      );
    },
    [api]
  );

  const downloadInOrderPdf = useCallback(async () => {
    const request = buildOrderDto(inOrder, inOrderLines);
    const blob = await api.fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}inorders/pdf/temp-inorder`,
      request,
      "POST"
    );

    const filename = `${Translate.get("InOrderLabel").replace(" ", "_")}.pdf`;
    download(blob, filename, blob.type);
  }, [api, inOrder, inOrderLines]);

  const handleSupplierCompanySelected = useCallback(async (newSupplier) => {
    setHasOrderChanged(true);
    setIsLoading(true);
    setInOrder((prev) => ({
      ...prev,
      supplierId: newSupplier?.actorId,
      yourRefId: null,
    }));
    setSupplierCompany(newSupplier);
    const loadedContacts = await loadContacts(newSupplier?.actorId ?? 0);
    setSupplierContacts(loadedContacts ?? []);

    setIsLoading(false);
  }, []);

  const handleSupplierContactSelected = useCallback((event) => {
    const actorId = event.target.value;
    setInOrder((prev) => ({ ...prev, yourRefId: actorId }));
    setHasOrderChanged(true);
  }, []);

  const handleContactSelected = useCallback((event) => {
    const actorId = event.target.value;
    setInOrder((prev) => ({ ...prev, ourRefId: actorId }));
    setHasOrderChanged(true);
  }, []);

  const handleSaveOrderLine = useCallback(
    async (inOrderLine) => {
      const index = inOrderLines.findIndex(
        (ol) => ol.inOrderLineId === inOrderLine.inOrderLineId
      );
      if (index !== -1) {
        setInOrderLines((prev) => prev.with(index, inOrderLine));
        setHasOrderChanged(true);
      }
    },
    [inOrder, inOrderLines]
  );

  useEffect(() => {
    async function loadData() {
      if (!inOrderId) {
        const defaultsResponse = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}inorders/defaults`,
          false,
          "GET"
        );
        if (!defaultsResponse["ActorId"]) {
          throw new Error("Missing default values");
        } else {
          await loadCompanyActor(defaultsResponse["ActorId"]);
        }
        setIsLoading(false);
      } else {
        const response = await api.fetchWithOverride(
          `${process.env.REACT_APP_MAIN_URL}inorders/${inOrderId}`,
          false,
          "GET",
          (response) => response && !!response.inOrderId
        );

        if (response && !!response.inOrderId) {
          console.log("Loaded successfully");
          response.deliveryAddressId = response.tempAddress
            ? -1
            : response.deliveryAddressId;
          setInOrder(response);
          setInOrderLines(response.inOrderLines);
          setPlannedDelDate(response.inOrderLines[0].plannedDelDate);
          await loadCompanyActor(response.actorId);
          await loadSupplierActor(response.supplierId);
        }
        setIsLoading(false);
      }
    }
    if (!hasInitialized) {
      setHasInitialized(true);
      loadData();
    }
  }, [inOrderId, loadCompanyActor, loadSupplierActor, api, hasInitialized]);

  async function addOrUpdateInOrder() {
    const request = buildOrderDto(inOrder, inOrderLines);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}inorders`,
      request,
      "POST"
    );

    if (response.isSuccessful) {
      closeAndReload();
    }
  }

  const partSearch = useCallback(
    async (searchText) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts/search?q=${searchText}&sellable=true&forActorId=${inOrder.actorId}`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        return response.parts;
      }
    },
    [api, inOrder]
  );

  const companySearch = useCallback(async (searchText) => {
    const foundCompanies = await actorSearch(
      searchText,
      actorCompanyTypes,
      ["ActorId", "ActorName", "ExternalDataId"],
      150
    );
    if (foundCompanies) {
      return foundCompanies.map((company) => ({
        actorId: company.actorId,
        externalDataId: company.externalDataId,
        defaultCustomerOrderNo: company.defaultCustomerOrderNo,
        observationNote: company.observationNote,
        actorName: company.actorName,
        orgNo: company.orgNo,
        email: company.email,
        addressStreet: company.postalAddress?.actorAddressStreet2,
        addressZipCode: company.postalAddress?.actorAddressZipCode,
        addressCity: company.postalAddress?.actorAddressCity,
      }));
    }
  }, []);

  // console.log("ORDER:", order);
  // console.log("LINES:", orderLines);

  return (
    <>
      {isLoading && <Preloader />}
      {showTempAddressDialog && (
        <AddressDialog
          addressTypeId={5}
          street1={inOrder.tempAddress?.street1}
          street2={inOrder.tempAddress?.street2}
          zipCode={inOrder.tempAddress?.cityCode}
          city={inOrder.tempAddress?.city}
          country={inOrder.tempAddress?.country}
          onCancel={() => setShowTempAddressDialog(false)}
          onSave={(updatedAddress) => {
            handleTempAddressChanged({
              ...updatedAddress,
              cityCode: updatedAddress.zipCode,
            });
            setShowTempAddressDialog(false);
          }}
        />
      )}
      {editInOrderLineId && (
        <InOrderLineDialog
          inOrderLine={inOrderLines.find(
            (ol) => ol.inOrderLineId === editInOrderLineId
          )}
          onCancel={() => {
            const isTempOrderLine = inOrderLines.find(
              (ol) => ol.inOrderLineId === editInOrderLineId
            ).isTempOrderLine;
            if (isTempOrderLine) {
              handleRemoveInOrderLine(editInOrderLineId);
            }
            setEditInOrderLineId(null);
          }}
          onSave={async (inOrderLine) => {
            if (editInOrderLineId !== inOrderLine.inOrderLineId) {
              throw new Error("InOrderLineId has changed");
            }
            await handleSaveOrderLine({
              ...inOrderLine,
              isTempOrderLine: false,
            });
            setEditInOrderLineId(null);
          }}
          partSearch={partSearch}
          getStUnits={getStUnits}
        />
      )}

      <Dialog
        maxWidth={false}
        scroll="paper"
        open={true}
        onClose={onClose}
        className={
          hasAccess(accessKeys.isMASystem)
            ? "bigCourseDialog"
            : "mediumCourseDialog"
        }
      >
        <InOrderFormContent
          inOrder={inOrder}
          inOrderLines={inOrderLines}
          company={company}
          supplierCompany={supplierCompany}
          contacts={contacts}
          supplierContacts={supplierContacts}
          addresses={addresses}
          plannedDelDate={plannedDelDate}
          handlePlannedDelDateChanged={handlePlannedDelDateChanged}
          companySearch={companySearch}
          handleSupplierCompanySelected={handleSupplierCompanySelected}
          handleContactSelected={handleContactSelected}
          handleSupplierContactSelected={handleSupplierContactSelected}
          handleAddInOrderLine={handleAddInOrderLine}
          handleEditInOrderLine={handleEditInOrderLine}
          handleRemoveInOrderLine={handleRemoveInOrderLine}
          handleDeliveryAddressSelected={handleDeliveryAddressSelected}
          handleSaveOrSendOrder={addOrUpdateInOrder}
          handleClose={onClose ?? closeAndReload}
          hasOrderChanged={hasOrderChanged}
          downloadInOrderPdf={downloadInOrderPdf}
        />
      </Dialog>
    </>
  );
}
