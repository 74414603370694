import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
} from "@mui/material";
import { useMemo, useState } from "react";
import Translate from "../../utils/Translate";
import LicenseRegistrationStudentRow from "./LicenseRegistrationStudentRow";
import AutocompleteSearchField from "../AutocompleteSearchField";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";

export default function LicenseRegistrationStep2CardBig({
  students,
  visibleCompetences,
  handleStudentAddRemoveCompetence,
  handleAddStudent,
  handleUpdateStudent,
  handleRemoveStudent,
  handleAddRemoveCompetenceAllStudents,
  handleApproveAllCompetences,
  isLicenseEditLocked,
  isPersonEditLocked,
  personSearch,
  licenseFormatType,
}) {
  const [studentToCrud, setStudentToCrud] = useState(null);

  async function handleSaveStudent(savedStudent, oldStudent) {
    console.log("handleSaveStudent", savedStudent);
    if (!savedStudent) {
      // This happens if the user presses the "Enter" key,
      // rather than selecting the "Add" from the list
      setStudentToCrud(null);
      return;
    }

    const isExistingActor = savedStudent.actorId > 0;
    const isUpdatingActor = isExistingActor && oldStudent && oldStudent.id > 0;
    if (isExistingActor) {
      savedStudent = {
        ...oldStudent,
        actorId: savedStudent.actorId,
        name: savedStudent.actorName,
        socialSecurityNumber: savedStudent.orgNo ?? "",
        actorEmail: savedStudent.actorEmail,
        phoneNumber:
          savedStudent.phoneNumber ??
          savedStudent.mobilePhone?.actorPhoneNumber,
        id: savedStudent.id ?? oldStudent?.id,
        profilePictureUrl:
          savedStudent.profilePictureUrl ?? oldStudent?.profilePictureUrl,
      };
      isUpdatingActor
        ? handleUpdateStudent(savedStudent)
        : handleAddStudent(savedStudent);
      return;
    } else {
      setStudentToCrud(savedStudent);
    }
  }

  const competenceStatsByCompetenceId = useMemo(
    () =>
      Object.fromEntries(
        visibleCompetences.map((v) => [
          v.id,
          {
            hasAllStudentsPassed: students.every((s) => s.competences[v.id]),
            hasSomeStudentsPassed: students.some((s) => s.competences[v.id]),
          },
        ])
      ),
    [visibleCompetences, students]
  );

  const allCompetencesAreApproved = useMemo(
    () =>
      visibleCompetences.every((c) =>
        students.every((s) => s.competences[c.id] === true)
      ),
    [visibleCompetences, students]
  );

  return (
    <>
      {!!studentToCrud && (
        <PersonCrudDialog
          open={!!studentToCrud}
          personCrudProps={{
            name: studentToCrud.actorName ?? "",
            onClose: () => setStudentToCrud(null),
            onActorSaved: (savedStudent) =>
              handleSaveStudent(savedStudent, studentToCrud),
            actorId: studentToCrud.actorId,
            isEditOnly: studentToCrud.actorId > 0,
            isMatchingActorsDisabled: studentToCrud.actorId > 0,
            onCancel: () => setStudentToCrud(null),
          }}
        />
      )}
      <Box marginTop="16px" marginBottom="16px">
        <h2>
          {Translate.get("Step2Students") +
            (isLicenseEditLocked ? ` ${Translate.get("Locked")}` : "")}
        </h2>
      </Box>
      <Card elevation={0} sx={{ width: "100%" }}>
        <CardContent sx={{ padding: 0 }}>
          <Grid
            container
            className="cardHeader licenseReg"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <Grid item width="408px">
              <AutocompleteSearchField
                placeholder={
                  Translate.get("Name") +
                  (isLicenseEditLocked ? ` ${Translate.get("Locked")}` : "")
                }
                label={
                  Translate.get("Name") +
                  (isLicenseEditLocked ? ` ${Translate.get("Locked")}` : "")
                }
                value={{}}
                onValueChange={handleSaveStudent}
                getOptionLabel={(option) => option?.actorName ?? ""}
                renderOption={(option) => (
                  <ActorAutocompleteOption
                    actorName={option?.actorName}
                    orgNo={option?.orgNo}
                    email={option?.actorEmail}
                  />
                )}
                keyPropName="actorId"
                requireSelection={true}
                search={personSearch}
                createNewValue={(text) => ({
                  actorName: text,
                })}
                disabled={isPersonEditLocked}
                // minWidth={isBigScreen ? "220px" : "250px"}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={handleApproveAllCompetences}
                disabled={allCompetencesAreApproved}
              >
                {Translate.get("ApproveAll")}
              </Button>
            </Grid>
          </Grid>
          <TableContainer sx={{ overflowX: "auto" }}>
            <Table
              className="gradesTable licenseReg"
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow className="gradesTableHeader licenseReg">
                  <TableCell>{/* Empty cell on purpose */}</TableCell>
                  <TableCell align="left" width="100%">
                    <FormControl>
                      <FormGroup
                        sx={{ flexWrap: "nowrap" }}
                        aria-label="position"
                        row
                      >
                        {!!students &&
                          students.length > 0 &&
                          visibleCompetences.map((competence) => (
                            <FormControlLabel
                              value="top"
                              className="gradesTableSkillCheckbox licenseReg"
                              control={
                                <Checkbox
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                  checked={
                                    competenceStatsByCompetenceId[competence.id]
                                      .hasAllStudentsPassed
                                  }
                                  indeterminate={
                                    !competenceStatsByCompetenceId[
                                      competence.id
                                    ].hasAllStudentsPassed &&
                                    !!competenceStatsByCompetenceId[
                                      competence.id
                                    ].hasSomeStudentsPassed
                                  }
                                  onChange={(event) =>
                                    handleAddRemoveCompetenceAllStudents(
                                      competence.id,
                                      event.target.checked
                                    )
                                  }
                                  disabled={isLicenseEditLocked}
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "text.secondary",
                                  }}
                                >
                                  {competence.column}
                                </Typography>
                              }
                              labelPlacement="top"
                              key={competence.id}
                            />
                          ))}
                        {/* Just an invisible dummy checkbox for UI */}
                        {(!students ||
                          students.length === 0 ||
                          !visibleCompetences ||
                          visibleCompetences.length === 0) && (
                          <FormControlLabel
                            value="top"
                            className="gradesTableSkillCheckbox"
                            sx={{ visibility: "hidden" }}
                            control={<Checkbox />}
                            label={
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "text.secondary",
                                }}
                              >
                                A1
                              </Typography>
                            }
                            labelPlacement="top"
                          />
                        )}
                      </FormGroup>
                    </FormControl>
                  </TableCell>
                  <TableCell>{/* Empty cell on purpose */}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, i) => (
                  <LicenseRegistrationStudentRow
                    key={student.id}
                    student={student}
                    visibleCompetences={visibleCompetences}
                    handleStudentAddRemoveCompetence={
                      handleStudentAddRemoveCompetence
                    }
                    handleRemoveStudent={handleRemoveStudent}
                    handleEditStudent={(student) => setStudentToCrud(student)}
                    isLicenseEditLocked={isLicenseEditLocked}
                    isPersonEditLocked={isPersonEditLocked}
                    licenseFormatType={licenseFormatType}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
