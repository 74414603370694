import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Fragment } from "react";
import Translate from "../../utils/Translate";
import { useState } from "react";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import { QRCodeSVG } from "qrcode.react";

/* Single view */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";
import { Avatar, useMediaQuery } from "@mui/material";
import PreviewCompany from "./PreviewCompany";
import PreviewCompetences from "./PreviewCompetences";
import { useEffect } from "react";
import { Warning } from "@mui/icons-material";

const Frontside = (props) => {
  //console.log("Cardfront", props);
  return (
    <>
      {props.bgImg && (
        <>
          <img className="dlcBgImg" src={props.bgImg} />
        </>
      )}
      {props.bgAnimation ? (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlmoving.svg"
        ></object>
      ) : (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlstatic.svg"
        ></object>
      )}
      <div className="dlcFrontText">
        {props.fields.map((row, index) => (
          <Fragment key={index}>
            {row.fieldName === "CompanyLogo" ? (
              row.value &&
              row.value != "-" && (
                <div>
                  <img src={row.value} className="dlcCompanyLogo" />
                </div>
              )
            ) : row.fieldName === "ProfilePicture" ? (
              <div className="dlcProfilePicContainer">
                {row.value && row.value != "-" && (
                  <Avatar src={row.value} className="dlcProfilePic" />
                )}
              </div>
            ) : row.fieldName === "gap" ? (
              <div className="dlcGap"></div>
            ) : (
              <div>
                <div style={props.fontColor.secondary}>
                  {Translate.get(row.titleTranslationKey)}
                </div>
                <div
                  style={{ ...props.fontColor.primary, fontSize: row.fontSize }}
                  className={
                    row.fieldName === "LicenseGroupDescription"
                      ? "dlcMainTitle"
                      : "dlcHeading1"
                  }
                >
                  {row.value}
                </div>
                <div style={props.fontColor.secondary}>
                  {row.subtitleTranslationKey &&
                    Translate.get(row.subtitleTranslationKey)}
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
};

const Backside = (props) => {
  function renderFieldValue(props, row) {
    if (
      (row.fieldName === "CourseCoordinator" ||
        row.fieldName === "ActorLicenseAllSkills") &&
      props.showExtraInfo
    ) {
      return (
        <div
          className="selectable"
          onClick={
            row.fieldName === "CourseCoordinator"
              ? props.handleOpenCompany
              : props.handleOpenComp
          }
        >
          <div>{row.value}</div>
          <InfoOutlinedIcon className="dlInfoIcon" />
        </div>
      );
    } else if (
      row.fieldName === "ValidTo" &&
      showField(row.fieldName, row.value) &&
      new Date(row.value) < new Date()
    ) {
      return (
        <div style={props.fontColor.primary}>
          <div>{row.value}</div>
          <Warning className="warningColor" />
        </div>
      );
    } else {
      return <div style={props.fontColor.primary}>{row.value}</div>;
    }
  }

  return (
    <>
      {props.bgImg && (
        <>
          <img className="dlcBgImg" src={props.bgImg} />
        </>
      )}
      {props.bgAnimation ? (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlmoving.svg"
        ></object>
      ) : (
        <object
          className="dlcAnimation"
          type="image/svg+xml"
          data="/static/dlstatic.svg"
        ></object>
      )}
      <div className="dlcBackText">
        {props.fields.map((row, index) => (
          <div key={index}>
            <div style={props.fontColor.secondary}>
              {Translate.get(row.titleTranslationKey)}
            </div>
            {renderFieldValue(props, row)}
          </div>
        ))}

        {props.publicShareUrl && (
          <div className="dlcQrCodeContainer">
            <QRCodeSVG
              id="qr-code"
              value={props.publicShareUrl}
              size={130}
              level="L"
              marginSize={1}
            />
          </div>
        )}
      </div>
    </>
  );
};

function showField(fieldName, value) {
  switch (fieldName) {
    case "ValidTo":
      return !!value;
    default:
      return true;
  }
}

export default function DigitalLicenseView({
  displayData,
  publicShareUrl,
  animate,
  allowSwiper,
  company,
  skills,
  showExtraInfo,
  isSmallCard,
  expired,
}) {
  const [showCompanyDialog, setShowCompanyDialog] = useState(false);
  const [showCompetencesDialog, setShowCompetencesDialog] = useState(false);
  const [frontFields, setFrontFields] = useState([]);
  const [backFields, setBackFields] = useState([]);
  const [bgColor, setBgColor] = useState({});
  const [fontColor, setFontColor] = useState({});

  const smallScreen = useMediaQuery("(max-width:750px)");

  useEffect(() => {
    const ff = displayData.frontFields.map((row) => {
      const value = displayData.fieldValues[row.fieldName]
        ? displayData.fieldValues[row.fieldName]
        : "-";
      return { ...row, value };
    });
    setFrontFields(ff);

    const bf = displayData.backFields
      .filter((row) =>
        showField(row.fieldName, displayData.fieldValues[row.fieldName])
      )
      .map((row) => {
        let value = displayData.fieldValues[row.fieldName];
        if (row.fieldName === "ValidFrom" || row.fieldName === "ValidTo") {
          value = tryFormatDate(value);
        }

        value = value ? value : "-";
        return { ...row, value };
      });
    setBackFields(bf);

    setBgColor(
      displayData?.digitalLicenseColor
        ? { backgroundColor: displayData.digitalLicenseColor }
        : {}
    );

    setFontColor({
      primary: displayData?.primaryFontColor
        ? { color: displayData.primaryFontColor }
        : {},
      secondary: displayData?.secondaryFontColor
        ? { color: displayData.secondaryFontColor }
        : {},
    });
  }, [displayData]);

  return (
    <>
      {showCompanyDialog && (
        <PreviewCompany
          company={company}
          onClose={() => setShowCompanyDialog(false)}
        />
      )}
      {showCompetencesDialog && (
        <PreviewCompetences
          skills={skills}
          onClose={() => setShowCompetencesDialog(false)}
        />
      )}
      {smallScreen && allowSwiper ? (
        <Swiper
          effect={"flip"}
          grabCursor={true}
          loop={true}
          //pagination={true}
          //navigation={true}
          modules={[EffectFlip]}
          className="swiperDL"
          initialSlide={0}
        >
          <SwiperSlide className="swiperDLSlide" style={bgColor}>
            <Frontside
              fields={frontFields}
              fontColor={fontColor}
              bgImg={displayData.frontBackgroundImageUrl || false}
              bgAnimation={animate}
              expired={expired}
            />
          </SwiperSlide>
          <SwiperSlide className="swiperDLSlide" style={bgColor}>
            <Backside
              fields={backFields}
              fontColor={fontColor}
              handleOpenCompany={() => setShowCompanyDialog(true)}
              handleOpenComp={() => setShowCompetencesDialog(true)}
              bgImg={displayData.backBackgroundImageUrl || false}
              bgAnimation={animate}
              showExtraInfo={showExtraInfo}
              expired={expired}
              publicShareUrl={publicShareUrl}
            />
          </SwiperSlide>
        </Swiper>
      ) : (
        <div className={isSmallCard ? "dlcSmallCard" : "dlcCard"}>
          <div style={bgColor}>
            <Frontside
              fields={frontFields}
              fontColor={fontColor}
              bgImg={displayData.frontBackgroundImageUrl || false}
              bgAnimation={animate}
              expired={expired}
            />
          </div>
          <div style={bgColor}>
            <Backside
              fields={backFields}
              fontColor={fontColor}
              handleOpenCompany={() => setShowCompanyDialog(true)}
              handleOpenComp={() => setShowCompetencesDialog(true)}
              bgImg={displayData.backBackgroundImageUrl || false}
              bgAnimation={animate}
              showExtraInfo={showExtraInfo}
              expired={expired}
              publicShareUrl={publicShareUrl}
            />
          </div>
        </div>
      )}
    </>
  );
}
