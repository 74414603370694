import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../utils/Api";
import { useState } from "react";
import Preloader from "../../components/Preloader";
import Translate from "../../utils/Translate";
import { formatDate } from "../../utils/dateTimeFormat";
import { formatMoney } from "../../utils/formatMoney";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import download from "downloadjs";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

export default function ReceiptPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const { orderRef } = useParams();
  const api = useApi();

  // useEffect(() => {
  //   async function loadReceipt() {
  //     setIsLoading(true);
  //     const response = await api.fetch(
  //       `${process.env.REACT_APP_MAIN_URL}v1/orders/receipts/${orderRef}`,
  //       false,
  //       "GET"
  //     );
  //     if (response.isSuccessful) {
  //       setReceipt(response);
  //     }
  //     setIsLoading(false);
  //   }

  //   loadReceipt();
  // }, [api, orderRef]);

  const downloadReceipt = useCallback(
    async (orderRef) => {
      setIsLoading(true);
      const blob = await api.fetchBlob(
        `${process.env.REACT_APP_MAIN_URL}v1/orders/receipts/${orderRef}`,
        false,
        "GET"
      );

      const filename = `kvitto_${orderRef}.pdf`;
      setIsLoading(false);
      download(blob, filename, blob.type);
      window.close();
    },
    [api]
  );

  useEffect(() => {
    if (hasAccess(accessKeys.digitalLicensesHolder)) {
      downloadReceipt(orderRef);
    }
  }, [api, orderRef, downloadReceipt]);

  return (
    <>
      {isLoading && <Preloader />}
      <Box className="receiptPageContainer">
        <h1>{Translate.get("ReceiptDownloading")}</h1>
        <Box>{Translate.get("IfDownloadDoesntStartClickHere")}</Box>
        <ButtonWithSpinner
          variant="contained"
          onClick={async () => await downloadReceipt(orderRef)}
        >
          {Translate.get("Download")}
        </ButtonWithSpinner>
      </Box>
    </>
  );
}
