import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  Button,
  Box,
  IconButton,
  Input,
} from "@mui/material";
import { CameraAlt, Close } from "@mui/icons-material";
import ImgCrop from "../imgCrop/ImgCrop";
import { useApi } from "../../utils/Api";
import { useSnackbar } from "notistack";
import Translate from "../../utils/Translate";

const tenMib = 1024 * 1024 * 10;

export default function DigitalLicenseProfilePictureDialog({
  open,
  onClose,
  currentImageUrl,
  onProfilePictureChanged,
}) {
  const [showImgCrop, setShowImgCrop] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useApi();

  async function preUploadImage(evt) {
    const file = evt.target.files[0];
    //showErrorMessage(file.size);
    //return
    if (file?.size > tenMib) {
      const errorMessage = enqueueSnackbar(Translate.get("FileIsTooLarge"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
      return;
    }
    setShowImgCrop(file);
    // if (variant === "circular") {
    //   //uploadImage(file); //Här gör vi extra upload om man inte vill beskära bilden
    //   setShowImgCrop(file);
    // } else {
    //   await uploadImage(file);
    // }
  }

  async function uploadImage(file) {
    if (file?.size > tenMib) {
      const errorMessage = enqueueSnackbar(Translate.get("FileIsTooLarge"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
      return;
    }
    setShowImgCrop(false);
    setUploadingImage(true);
    const data = new FormData();
    data.append("imageFile", file);
    data.append("type", "ecompprofilepic");

    const response = await api.fetch(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}AddOrUpdateECompProfilePic`,
      data,
      "POST"
    );

    if (response.isSuccessful) {
      await onProfilePictureChanged();
      // onClose();
    }
    setUploadingImage(false);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        {Translate.get("ChangeProfilePic")}
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {showImgCrop && (
          <ImgCrop
            img={showImgCrop}
            uploadingImage={uploadingImage}
            uploadImage={uploadImage}
            aspect={1}
          />
        )}
        {!showImgCrop && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: 3,
              gap: 3,
            }}
          >
            <Avatar
              src={currentImageUrl}
              sx={{
                width: 200,
                height: 200,
              }}
            />

            <Button
              variant="contained"
              startIcon={<CameraAlt />}
              component="label"
              htmlFor="upload-image-input"
            >
              {Translate.get("Upload")}
              <Input
                inputProps={{ accept: "image/*" }}
                id="upload-image-input"
                onInput={preUploadImage}
                type="file"
                sx={{ display: "none" }}
              />
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
