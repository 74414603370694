const langCodeLocales = {
  9: "en-GB",
  12: "fr-FR",
  29: "sv-SE",
};

const langCodeCurrencies = {
  9: "GBP",
  12: "EUR",
  29: "SEK",
};

export function formatMoney(
  amount,
  localeParam,
  currencyParam,
  minFractionDigits = null,
  maxFractionDigits = null
) {
  const locale = localeParam ?? langCodeLocales[localStorage.lang] ?? "sv-SE";
  const currency = currencyParam ?? langCodeCurrencies[localStorage.lang];

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: maxFractionDigits ?? 0,
    ...(minFractionDigits !== null && minFractionDigits !== undefined
      ? { minimumFractionDigits: minFractionDigits }
      : {}),
  });
  return formatter.format(amount);
}
