import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useEffect, useState } from "react";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { isCoursePartType, partType } from "../../utils/part";
import ButtonWithSpinner from "../ButtonWithSpinner";
import StUnitField from "../orders/StUnitField";
import { inOrderStatus } from "../../utils/inOrder";

// We use IDs like "dummyId1" or crypto.RandomUUID() here in frontend for new orderLines
function isDummyInOrderLineId(inOrderLineId) {
  return typeof inOrderLineId !== "number";
}

function sortStUnits(a, b) {
  if (!a.blocked && b.blocked) {
    return -1;
  } else if (a.blocked && !b.blocked) {
    return 1;
  }

  if (a.isUnlimitedQty && !b.isUnlimitedQty) {
    return -1;
  } else if (!a.isUnlimitedQty && b.isUnlimitedQty) {
    return 1;
  }

  return b.qty - a.qty;
}

export default function InOrderLineDialog({
  inOrderLine,
  onSave,
  onCancel,
  partSearch,
  getStUnits,
}) {
  if (inOrderLine.partId && !inOrderLine.part) {
    throw new Error("Missing part");
  }

  const [stUnits, setStUnits] = useState([]);
  const [isExistingInOrderLine] = useState(
    !isDummyInOrderLineId(inOrderLine.inOrderLineId)
  );
  const [updatedInOrderLine, setUpdatedInOrderLine] = useState({
    ...inOrderLine,
    qty: inOrderLine.qty ?? 1,
  });

  useEffect(() => {
    if (inOrderLine && inOrderLine.partId > 0) {
      getStUnits(inOrderLine.partId).then((response) =>
        setStUnits(
          response.isSuccessful ? response.stUnits.sort(sortStUnits) : []
        )
      );
    }
  }, [inOrderLine, getStUnits]);

  function isValid() {
    return (
      updatedInOrderLine.partId &&
      updatedInOrderLine.part &&
      updatedInOrderLine.qty > 0 &&
      (updatedInOrderLine.pcsPrice === null ||
        updatedInOrderLine.pcsPrice === undefined ||
        updatedInOrderLine.pcsPrice >= 0)
    );
  }

  async function handlePartSelected(selectedPart) {
    setUpdatedInOrderLine((prev) => ({
      ...prev,
      partId: selectedPart.partId,
      part: selectedPart,
    }));

    const stUnitsResponse = await getStUnits(selectedPart.partId);
    setStUnits(
      stUnitsResponse.isSuccessful
        ? stUnitsResponse.stUnits.sort(sortStUnits)
        : []
    );
  }

  function handleQtyChange(newValue) {
    const newPrice = newValue * updatedInOrderLine.discountedPcsPrice;
    setUpdatedInOrderLine((prev) => ({
      ...prev,
      qty: Number(newValue),
      price: newPrice,
    }));
  }

  function handlePcsPriceChange(newValue) {
    setUpdatedInOrderLine((prev) => ({ ...prev, pcsPrice: Number(newValue) }));
  }

  return (
    <Dialog open={true} className="mediumCourseDialog">
      <DialogTitle>{Translate.get("OrderLine")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item marginTop={2}>
            <h4>{Translate.get("PartLabel")}</h4>
          </Grid>
          <Grid item>
            <AutocompleteSearchField
              disabled={isExistingInOrderLine}
              label={Translate.get("PartLabel")}
              value={updatedInOrderLine.part ?? ""}
              groupBy={(option) => Translate.getPartTypePlural(option.partType)}
              onValueChange={(selectedPart) => handlePartSelected(selectedPart)}
              getOptionLabel={(option) => option?.description ?? ""}
              renderOption={(option) => (
                <Grid container>
                  <Grid item xs={3}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        color: "text.secondary",
                      }}
                    >
                      {option.partNo ?? ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Box>{option.description}</Box>
                  </Grid>
                </Grid>
              )}
              keyPropName="partId"
              disableAddAsNew={true}
              requireSelection={true}
              search={async (searchText) => {
                const results = await partSearch(searchText);
                // Sorting needed for groupBy of search results
                return results.sort((a, b) => {
                  const partTypeSort = Translate.getPartTypePlural(
                    a.partType
                  ).localeCompare(Translate.getPartTypePlural(b.partType));
                  if (partTypeSort != 0) {
                    return partTypeSort;
                  } else if (a.partNo && b.partNo) {
                    return !isNaN(a.partNo) && !isNaN(b.partNo)
                      ? Number(a.partNo) - Number(b.partNo)
                      : a.partNo.localeCompare(b.partNo);
                  } else if (a.partNo) {
                    return 1;
                  } else if (b.partNo) {
                    return -1;
                  } else {
                    return a.description.localeCompare(b.description);
                  }
                });
              }}
              textFieldProps={{
                required: true,
                error: false,
                helperText: "",
              }}
            />
          </Grid>
          {updatedInOrderLine.partId && (
            <Grid item>
              <TextField
                fullWidth
                required
                disabled={
                  !updatedInOrderLine.partId ||
                  inOrderLine.status >= inOrderStatus.startedUnpacking
                }
                label={Translate.get("Quantity")}
                value={
                  isNaN(updatedInOrderLine.qty) ? "" : updatedInOrderLine.qty
                }
                onChange={(event) => handleQtyChange(event.target.value)}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "number",
                  min: 1,
                }}
              />
            </Grid>
          )}

          {updatedInOrderLine.partId && (
            <Grid item>
              <TextField
                fullWidth
                disabled={
                  !updatedInOrderLine.partId ||
                  inOrderLine.status >= inOrderStatus.startedUnpacking
                }
                label={Translate.get("PricePerUnit")}
                value={updatedInOrderLine.pcsPrice ?? ""}
                onChange={(event) => handlePcsPriceChange(event.target.value)}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "number",
                  min: 0,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{"SEK"}</InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {updatedInOrderLine.partId && (
            <Grid container item spacing={2} marginTop={2} direction="column">
              <Grid item>
                <h4>{Translate.get("StorageInfoLabel")}</h4>
              </Grid>

              {stUnits && stUnits.length > 0 && (
                <Grid item container spacing={1}>
                  {stUnits.map((s) => (
                    <Grid item key={s.stUnitId}>
                      <StUnitField {...s} />
                    </Grid>
                  ))}
                </Grid>
              )}
              {(!stUnits || stUnits.length === 0) && <Grid item>-</Grid>}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {Translate.get("Cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={async () => await onSave(updatedInOrderLine)}
          disabled={
            !isValid() || inOrderLine.status >= inOrderStatus.startedUnpacking
          }
        >
          {Translate.get("Ok")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
}
