import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useApi } from "../../utils/Api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Preloader from "../Preloader";
import { orderStatus } from "../../utils/order";
import {
  DataGridPremium as DataGrid,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import {
  ArrowForwardIos,
  Check,
  Flag,
  FormatListNumbered,
  HourglassBottom,
  HourglassTop,
  Inventory,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Money,
  RequestQuote,
  School,
  ShoppingBasket,
  Tag,
  Warning,
} from "@mui/icons-material";
import { dataGridLang } from "../MemToolBar";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import {
  isCoursePartType,
  isDigitalPickPartType,
  isFreightPartType,
  isPhysicalPickPartType,
  partType,
} from "../../utils/part";
import { useReactToPrint } from "react-to-print";
import Bowser from "bowser";
import PickOrderLineDialog from "./PickOrderLineDialog";
import {
  dateCompare,
  formatDate,
  formatTime,
} from "../../utils/dateTimeFormat";
import AfterPickAction, { actionStatus } from "./AfterPickAction";
import download from "downloadjs";
import { useSnackbar } from "notistack";
import { documentType, mediaType } from "../../utils/sentCommunication";
import TooltipFields from "../TooltipFields";
import TooltipFieldsList from "../TooltipFieldsList";
import {
  getDeliveryNoteEmailActionProps,
  getDeliveryNotificationEmailActionProps,
  getDeliveryNotePrintActionProps,
  getFreightActionTooltip,
} from "./afterPickActionHelper";
import FreightDialog from "../orders/FreightDialog";

const cr80CardWidth = 2048;
const cr80cardHeight = 1300;

function sortOrderLines(a, b) {
  if (a.delQty <= a.qty && b.delQty === b.qty) {
    return -1;
  } else if (a.delQty === a.qty && b.delQty <= b.qty) {
    return 1;
  } else {
    return 0;
  }
}

function getWarning(orderLine, partStUnits) {
  if (orderLine.delQty === orderLine.qty) {
    return null;
  }

  // console.log("getWarning 0", orderLine, partStUnits);

  const stUnits = partStUnits && partStUnits[orderLine.partId];
  if (!stUnits || stUnits.length === 0) {
    // console.log("getWarning 1", stUnits);
    return Translate.get("StUnitQtyNotEnough");
  }

  const availableStUnits = stUnits.filter(
    (s) => !s.bestBeforeDate || new Date(s.bestBeforeDate) > new Date()
  );
  const availableQty = availableStUnits
    .map((s) => s.qty)
    .reduce((acc, val) => acc + val, 0);
  const neededQty = orderLine.qty - orderLine.delQty;
  if (availableQty < neededQty) {
    // console.log("getWarning 2", availableStUnits, availableQty, neededQty);
    return Translate.get("StUnitQtyNotEnough");
  }
}

export default function PickOrderDialog({
  orderId,
  startPick,
  onClose,
  invoiceOrder,
}) {
  const [hasStartedPick, setHasStartedPick] = useState(!startPick);
  const [order, setOrder] = useState(null);
  const [actorOrderInfo, setActorOrderInfo] = useState(null);
  const [partStUnits, setPartStUnits] = useState(null);
  const [printJob, setPrintJob] = useState(null);
  const [sentCommunications, setSentCommunication] = useState(null);
  const [orderLineToPick, setOrderLineToPick] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showFreightDialog, setShowFreightDialog] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isSafari = browser.isBrowser("Safari");
  const printViewRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printViewRef.current,
    // onAfterPrint: () => markAsPrinted(api, selectedActorLicenseIds),
    // I set the width and height of the page explicitly, and then do the same for the license to be printed.
    // This is because I couldn't get the license to size correctly by simply setting width and height 100%.
    // But it seems like it should be possible to make it completely dynamic using onBeforePrint and useRef.
    pageStyle: () =>
      !isSafari
        ? `
    @page {
      margin: 0 !important;
      size: ${cr80CardWidth}px ${cr80cardHeight}px;
    }
  `
        : `
    @page {
      margin: 0 !important;
      size: ${cr80CardWidth}px ${cr80cardHeight}px;
    }

    @media print {
      html,
      body {
        transform: scale(0.54);
        translate: -160px -240px;
      }
    }
  `,
  });

  const api = useApi();
  const localizedTextsMap = dataGridLang();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(900));
  const gridClassName = isSmallScreen
    ? "stdVisible dgDynIconText shiftDatagridToolbar"
    : "dgSmall";

  const loadActorOrderInfo = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/orderinfo`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setActorOrderInfo(response);
      }
    },
    [api]
  );

  const loadOrder = useCallback(async () => {
    setIsLoading(true);

    let isStartPickOk = hasStartedPick;
    if (!hasStartedPick) {
      const responseStartPick = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/start-pick`,
        true,
        "POST"
      );

      if (responseStartPick.isSuccessful) {
        isStartPickOk = true;
        setHasStartedPick(isStartPickOk);
        if (responseStartPick.status === orderStatus.picked) {
          const successMessage = enqueueSnackbar(
            Translate.get("AllItemsWereAutoPicked"),
            {
              variant: "success",
              autoHideDuration: 6000,
              onClick: () => closeSnackbar(successMessage),
            }
          );
          onClose();
        }
      } else {
        onClose();
      }
    }

    if (isStartPickOk) {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/for-pick`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        const orderModified = {
          ...response.order,
          orderLines: response.order.orderLines.sort(sortOrderLines),
        };
        setPartStUnits(response.partStUnits);
        setSentCommunication(orderModified.sentCommunications ?? []);
        setOrder(orderModified);
        setPrintJob(response.printJob);
        return orderModified;
      }
    }

    setIsLoading(false);
  }, [api, hasStartedPick, orderId, onClose, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (!order) {
      loadOrder().then((order) => loadActorOrderInfo(order.actorId));
    }
  }, [order, loadOrder]);

  const pickOrderLine = useCallback(
    async (orderLineId, qtyToPick, stUnitId) => {
      // console.log("pickOrderLine", orderLineId, qtyToPick, stUnitId);
      const orderLine = order.orderLines.find(
        (ol) => ol.orderLineId === orderLineId
      );
      const partId = orderLine.partId;

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/orderlines/pick`,
        { orderLineId, qtyToPick, stUnitId },
        "POST"
      );
      if (response.isSuccessful) {
        const updatedOrderLine = response.orderLine;
        const updatedStUnit = response.stUnit;
        setPartStUnits((prev) => {
          const index = prev[partId].findIndex((s) => s.stUnitId === stUnitId);
          const stUnitCopy = { ...prev[partId][index] };
          stUnitCopy.qty = updatedStUnit.qty;
          return { ...prev, [partId]: prev[partId].with(index, stUnitCopy) };
        });
        setOrder((prev) => {
          const index = prev.orderLines.findIndex(
            (ol) => ol.orderLineId === orderLineId
          );
          const orderLineCopy = { ...prev.orderLines[index] };
          orderLineCopy.qty = updatedOrderLine.qty;
          orderLineCopy.delQty = updatedOrderLine.delQty;
          orderLineCopy.status = updatedOrderLine.status;
          const newOrderLines = prev.orderLines
            .with(index, orderLineCopy)
            .sort(sortOrderLines);
          return {
            ...prev,
            orderLines: newOrderLines,
          };
        });

        if (updatedOrderLine.delQty === updatedOrderLine.qty) {
          setOrderLineToPick(null);
        }
      }
      return response;
    },
    [api, order]
  );

  const sendDeliveryNotificationEmail = useCallback(
    async (orderId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/deliverynotification/email`,
        true,
        "POST"
      );
      if (response.isSuccessful) {
        loadOrder();
        const successMessage = enqueueSnackbar(
          Translate.get("SendEmailSuccess"),
          {
            variant: "success",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(successMessage),
          }
        );
      }
      return response.isSuccessful;
    },
    [api, loadOrder, enqueueSnackbar, closeSnackbar]
  );

  const sendDeliveryNoteEmail = useCallback(
    async (orderId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/deliverynote/email`,
        true,
        "POST"
      );
      if (response.isSuccessful) {
        loadOrder();
        const successMessage = enqueueSnackbar(
          Translate.get("SendEmailSuccess"),
          {
            variant: "success",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(successMessage),
          }
        );
      }
      return response.isSuccessful;
    },
    [api, loadOrder, enqueueSnackbar, closeSnackbar]
  );

  const downloadDeliveryNote = useCallback(
    async (orderId, orderNo) => {
      const blob = await api.fetchBlob(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/deliverynote/pdf`,
        false,
        "GET"
      );

      const filename = `${Translate.get("DeliveryNoteLabel").replace(
        " ",
        "_"
      )}_${orderNo}.pdf`;
      download(blob, filename, blob.type);
    },
    [api]
  );

  const markDeliveryNoteAsPrinted = useCallback(
    async (orderId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/deliverynote/mark-print`,
        true,
        "POST"
      );
      return response.isSuccessful;
    },
    [api]
  );

  const handleFreightSelected = useCallback(
    async (freightId, weight, fixedPrice) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/freights`,
        { orderId: order.orderId, freightId, weight, fixedPrice },
        "POST"
      );
      if (response.isSuccessful) {
        setShowFreightDialog(false);
        const successMessage = enqueueSnackbar(Translate.get("Ready"), {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        });
      }
      loadOrder();
      return response.isSuccessful;
    },
    [api, order, loadOrder, enqueueSnackbar, closeSnackbar]
  );

  const columns = useMemo(
    () => [
      ...(isSmallScreen
        ? []
        : [
            {
              field: "partNo",
              flex: 0.4,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("PartNoLabel"),
              valueGetter: (params) => params.row.part.partNo,
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={Tag}
                  label={Translate.get("PartNoLabel")}
                />
              ),
            },
          ]),
      {
        field: "partDescription",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Product"),
        valueGetter: (params) => params.row.part.description.trim(),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ShoppingBasket}
            label={Translate.get("Product")}
          />
        ),
      },
      {
        field: "deliveredQuantity",
        type: "number",
        align: "left",
        headerAlign: "left",
        flex: 0.4,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("PickedQty"),
        valueGetter: (params) => `${params.row.delQty}/${params.row.qty}`,
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={Inventory}
            label={Translate.get("PickedQty")}
          />
        ),
      },
      {
        field: "warning",
        flex: 0.15,
        headerName: "",
        sortable: false,
        filterable: false,
        hideable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) =>
          getWarning(params.row, partStUnits) ? (
            <Tooltip
              arrow
              className="iconbox warning"
              title={getWarning(params.row, partStUnits)}
            >
              <Warning />
            </Tooltip>
          ) : null,
      },
      {
        field: "arrow",
        flex: 0.15,
        headerName: "",
        sortable: false,
        filterable: false,
        hideable: false,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: () => <KeyboardArrowRight />,
      },
    ],
    [isSmallScreen, partStUnits]
  );

  const afterPickActions = useMemo(() => {
    const result = [];
    if (!order) {
      return result;
    }

    const hasDigitalPick = order.orderLines.some((ol) =>
      isDigitalPickPartType(ol.part.partType)
    );
    const isToyotaClientWithToyotaInvoice =
      actorOrderInfo &&
      actorOrderInfo.isMAToyotaClient &&
      actorOrderInfo.isToyotaInvoice;
    if (hasDigitalPick || isToyotaClientWithToyotaInvoice) {
      const { status, tooltip } =
        getDeliveryNotificationEmailActionProps(sentCommunications);
      result.push({
        status: status,
        tooltip: tooltip,
        description: Translate.get(
          isToyotaClientWithToyotaInvoice && !hasDigitalPick
            ? "SendDeliveryNotificationForToyotaInvoiceOrder"
            : "SendDeliveryNotificationForDigitalGoodsOrder"
        ),
        buttonLabel: Translate.get("Send"),
        action: async () => {
          await sendDeliveryNotificationEmail(order.orderId);
        },
      });
    }

    const hasPhysicalPick = order.orderLines.some((ol) =>
      isPhysicalPickPartType(ol.part.partType)
    );
    if (hasPhysicalPick) {
      const freightOrderLines = order.orderLines.filter((ol) =>
        isFreightPartType(ol.part.partType)
      );
      const hasFreight = freightOrderLines.length > 0;
      const tooltip = getFreightActionTooltip(freightOrderLines);
      result.push({
        status: hasFreight ? actionStatus.done : actionStatus.notDone,
        tooltip: tooltip,
        description: Translate.get("AddFreightToPhysicalGoodsOrder"),
        buttonLabel: hasFreight ? Translate.get("Alter") : Translate.get("Add"),
        action: () => setShowFreightDialog(true),
      });

      // Normally we will print the delivery note. But there are some instances where it's
      // emailed apparently, for example if the client receives the package directly at our office.
      const { status: emailStatus, tooltip: emailTooltip } =
        getDeliveryNoteEmailActionProps(sentCommunications);
      const deliveryNoteEmailAction = {
        status: emailStatus,
        tooltip: emailTooltip,
        description: Translate.get("SendDeliveryNoteForPhysicalGoodsOrder"),
        buttonLabel: Translate.get("Send"),
        action: async () => {
          await sendDeliveryNoteEmail(order.orderId);
        },
      };

      const { status: printStatus, tooltip: printTooltip } =
        getDeliveryNotePrintActionProps(sentCommunications);
      result.push({
        status: printStatus,
        tooltip: printTooltip,
        description: Translate.get("PrintDeliveryNoteForPhysicalGoodsOrder"),
        buttonLabel: Translate.get("Print"),
        action: async () => {
          // TODO ska öppnas i ny flik
          await downloadDeliveryNote(order.orderId, order.orderNo);
          // TODO funkar inte pga. att token ligger i sessionStorage
          // window.open(
          //   `${process.env.REACT_APP_MAIN_URL}orders/${order.orderId}/deliverynote/pdf`,
          //   "_blank"
          // );
          await markDeliveryNoteAsPrinted(order.orderId);
          loadOrder();
        },
        // If isToyotaClientWithToyotaInvoice, then we will send a delivery
        // notification and will never need to email a delivery note as well.
        altActions: !isToyotaClientWithToyotaInvoice
          ? [deliveryNoteEmailAction]
          : null,
      });
    }

    const hasInvoiced = order.orderLines.some(
      (ol) => ol.status >= orderStatus.waitingForInvoice
    );
    result.push({
      disabled: hasInvoiced,
      status: hasInvoiced ? actionStatus.done : actionStatus.notDone,
      description: Translate.get("SendOrderToInvoicing") + ".",
      buttonLabel: Translate.get("Invoice"),
      action: async () => {
        await invoiceOrder(order.orderId);
        loadOrder();
        const successMessage = enqueueSnackbar(Translate.get("Ready"), {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        });
      },
    });

    return result;
  }, [
    order,
    sentCommunications,
    actorOrderInfo,
    sendDeliveryNotificationEmail,
    sendDeliveryNoteEmail,
    downloadDeliveryNote,
    markDeliveryNoteAsPrinted,
    loadOrder,
    invoiceOrder,
    enqueueSnackbar,
    closeSnackbar,
  ]);

  const rows = useMemo(
    () =>
      order
        ? order.orderLines.filter(
            (ol) =>
              !isFreightPartType(ol.part.partType) &&
              !isCoursePartType(ol.part.partType)
          )
        : [],
    [order]
  );

  function handleRowClick(params) {
    if (params.row.delQty < params.row.qty) {
      setOrderLineToPick(params.row);
    }
  }

  function handleGetRowClassName(params) {
    if (
      params.row.delQty >= params.row.qty ||
      params.row.status >= orderStatus.picked
    ) {
      return "disabled";
    }
  }

  const showAfterPickActions =
    afterPickActions &&
    afterPickActions.length > 0 &&
    rows.length > 0 &&
    rows.every((ol) => ol.status >= orderStatus.picked);

  return (
    <>
      {isLoading && <Preloader />}
      {orderLineToPick && (
        <PickOrderLineDialog
          orderLine={orderLineToPick}
          stUnits={
            partStUnits && partStUnits[orderLineToPick.partId]
              ? partStUnits[orderLineToPick.partId]
              : []
          }
          onClose={() => setOrderLineToPick(null)}
          onPickOrderLine={pickOrderLine}
          printJob={
            printJob && printJob.orderLineId === orderLineToPick.orderLineId
              ? printJob
              : null
          }
        />
      )}
      {showFreightDialog && (
        <FreightDialog
          isFirstTime={
            !order.orderLines.some((ol) => isFreightPartType(ol.part?.partType))
          }
          weight={order.weight}
          fixedPrice={order.freightFixedPrice}
          onCancel={() => setShowFreightDialog(false)}
          onSave={handleFreightSelected}
        />
      )}
      <Dialog
        open={!!order}
        onClose={onClose}
        maxWidth={false}
        className="bigCourseDialog"
        scroll="paper"
      >
        <DialogTitle>{Translate.get("Pick")}</DialogTitle>
        <DialogContent>
          <DataGrid
            className={gridClassName}
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            getRowId={(row) => row.orderLineId}
            autoHeight={true}
            componentsProps={{
              columnsPanel: { className: "customColumnPanel" },
              filterPanel: { className: "customfilterPanel" },
              panel: { className: "customPanel" },
            }}
            localeText={localizedTextsMap}
            disableDensitySelector={true}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            onRowClick={handleRowClick}
            getRowClassName={handleGetRowClassName}
          />
          {showAfterPickActions && (
            <Stack marginTop="40px" direction="column" spacing={3}>
              {afterPickActions.map((a, index) => (
                <AfterPickAction key={index} {...a} />
              ))}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            {Translate.get("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* ActorLicensePrintView is only visible when printing, via media query in ActorLicensePrint.css */}
      {/* <ActorLicensePrintView
        ref={printViewRef}
        printTemplate={getSelectedPrintTemplate()}
        studentCardsDetails={getStudentDetails().filter(
          (s) => !!selectedActorLicenseIds.find((c) => c === s.actorLicenseId)
        )}
        printFront={sidePrintMode === "both" || sidePrintMode === "front"}
        printBack={sidePrintMode === "both" || sidePrintMode === "back"}
        printWidth={printWidth}
        printHeight={printHeight}
        printType={printType}
      /> */}
    </>
  );
}
