import { Stack, TextField } from "@mui/material";
import Translate from "./Translate";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
  isValidDate,
} from "./dateTimeFormat";
import { useState } from "react";

export default function DateInterval({ item, applyValue }) {
  const [startDate, setStartDate] = useState(item.value?.[0] || null);
  const [endDate, setEndDate] = useState(item.value?.[1] || null);

  const handleChange = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    applyValue({ ...item, value: [newStartDate, newEndDate] });
  };

  return (
    <Stack spacing={2}>
      <DatePicker
        label={Translate.get("StartDate")}
        value={startDate}
        inputFormat={getCurrentInputFormat()}
        mask={getCurrentDateMask()}
        onChange={(newValue) => {
          if (isValidDate(newValue)) {
            handleChange(newValue, endDate);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: Translate.get("DatePlaceholder"),
            }}
            variant="standard"
          />
        )}
      />
      <DatePicker
        label={Translate.get("EndDate")}
        value={endDate}
        inputFormat={getCurrentInputFormat()}
        mask={getCurrentDateMask()}
        onChange={(newValue) => {
          if (isValidDate(newValue)) {
            handleChange(startDate, newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: Translate.get("DatePlaceholder"),
            }}
            variant="standard"
          />
        )}
      />
    </Stack>
  );
}
