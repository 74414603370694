import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useMemo, useEffect } from "react";
import Translate from "../../../utils/Translate";
import PersonIcon from "@mui/icons-material/Person";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import EmailIcon from "@mui/icons-material/Email";
import LabelIcon from "@mui/icons-material/Label";
import ErrorIcon from "@mui/icons-material/Error";
import { IconAndLabelColumnHeader } from "../../../utils/dataGrid";
import { MemToolBar, dataGridLang } from "../../MemToolBar";
import { Fingerprint, Numbers } from "@mui/icons-material";

export default function ImportActorsDataGrid({
  actors,
  description,
  isErrorResult,
}) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const localizedTextsMap = dataGridLang();

  const [columnVisibility, setColumnVisibility] = useState({
    actorName: true,
    actorEmail: true,
    orgNo: true,
    externalActorNo: true,
    reason: false,
  });

  const isSkip = useMemo(
    () => actors.some((a) => a?.importDetails?.action === "skip"),
    [actors]
  );

  useEffect(() => {
    if (isErrorResult) {
      setColumnVisibility({
        actorName: true,
        actorEmail: false,
        orgNo: false,
        externalActorNo: false,
        reason: true,
      });
    } else if (isSmall) {
      setColumnVisibility({
        actorName: true,
        actorEmail: false,
        orgNo: false,
        externalActorNo: false,
        reason: isSkip,
      });
    } else {
      setColumnVisibility({
        actorName: true,
        actorEmail: true,
        orgNo: true,
        externalActorNo: actors.some((a) => !!a.externalActorNo),
        reason: isSkip,
      });
    }
  }, [isSmall, actors, isSkip, isErrorResult]);

  const columns = [
    {
      field: "actorName",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={PersonIcon}
          label={Translate.get("ActorName")}
        />
      ),
    },
    {
      field: "actorEmail",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={EmailIcon}
          label={Translate.get("Email")}
        />
      ),
    },
    {
      field: "orgNo",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={Fingerprint}
          label={Translate.get("PersonalNo")}
        />
      ),
    },
    {
      field: "externalActorNo",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={Numbers}
          label={Translate.get("EmployeeNo")}
        />
      ),
    },
    {
      field: "reason",
      flex: 1,
      disableColumnMenu: true,
      headerClassName: "hideSeparator",
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={ErrorIcon}
          label={Translate.get("Reason")}
        />
      ),
      renderCell: (props) => (
        <Tooltip
          sx={{ width: "100%" }}
          title={
            isErrorResult
              ? Translate.get(
                  props.row.errorMessageTranslationKey ?? "SomethingFailed"
                )
              : props.row.importDetails.reason
          }
        >
          <Box overflow="hidden" textOverflow="ellipsis">
            {isErrorResult
              ? Translate.get(
                  props.row.errorMessageTranslationKey ?? "SomethingFailed"
                )
              : props.row.importDetails.reason}
          </Box>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box className={"importDataGridContainer" + (isSmall ? " small" : "")}>
      <h4>{`${description}:`}</h4>
      <DataGrid
        //className="dgDynIconText"
        columnVisibilityModel={columnVisibility}
        autoHeight={true}
        rowsPerPage={-1}
        hideFooter={true}
        columns={columns}
        rows={actors}
        getRowId={(row) => Object.values(row).join("_")}
        localeText={localizedTextsMap}
        disableDensitySelector={true}
        disableColumnFilter={true}
        disableColumnSelector={true}
        {...(isSkip
          ? {
              components: {
                Toolbar: MemToolBar,
              },
              componentsProps: {
                toolbar: { gridId: "SkipStudentImport" },
              },
            }
          : {})}
      />
    </Box>
  );
}
