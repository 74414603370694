import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import ActorLicensePreview from "../../components/actor-license-print/ActorLicensePreview";
import { useParams } from "react-router-dom";
import Translate from "../../utils/Translate";
import DigitalLicenseSingleItem from "../../components/digital-licenses/DigitalLicenseSingleItem";
import { accessKeys, hasAccess } from "../../utils/userAccess";

export default function DigitalLicense(props) {
  const [license, setLicense] = useState(null);
  const [company, setCompany] = useState(null);
  const [course, setCourse] = useState(null);
  const [skills, setSkills] = useState(null);
  const [otherSkills, setOtherSkills] = useState(null);
  const [skillGroupDescription, setSkillGroupDescription] = useState(null);
  const api = useApi();
  const { id: digitalLicenseId } = useParams();

  useEffect(() => {
    async function loadLicense() {
      const url = hasAccess(accessKeys.digitalLicensesHolder)
        ? `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/${digitalLicenseId}`
        : `${process.env.REACT_APP_MAIN_URL}v1/digital/licenses/${digitalLicenseId}/public`;
      const response = await api.fetch(url, false, "GET");
      if (response.isSuccessful) {
        //console.log('DIG',response);
        setLicense(response.license);
        setCompany(response.company);
        setCourse(response.course);
        setSkillGroupDescription(response.skillGroupDescription);
        setSkills(response.skills);
      }
    }

    loadLicense();
  }, [api, digitalLicenseId]);
  return (
    <>
      {!!license && (
        <DigitalLicenseSingleItem
          key={license.digitalLicenseId}
          license={license}
          showDigitalPermitButton={true}
          skillGroupDescription={skillGroupDescription}
          isRestrictedView={hasAccess(accessKeys.digitalLicensesPublic)}
          company={company}
          skills={skills}
        />
      )}
    </>
  );
}
