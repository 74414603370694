import { useApi } from "../../utils/Api";
import { useEffect } from "react";
import { useState } from "react";
import Translate from "../../utils/Translate";
import Box from "@mui/material/Box";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import DotMenu from "../DotMenu";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import Preloader from "../Preloader";
import NotesIcon from "@mui/icons-material/Notes";
import PreviewIcon from "@mui/icons-material/Preview";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Divider from '@mui/material/Divider';

export function ActorPermitSettings() {
  const [isLoading, setIsLoading] = useState(false);

  const [tasks, setTasks] = useState([]);
  const [otherEquipments, setOtherEquipments] = useState([]);
  const [areas, setAreas] = useState([]);

  const [textType, setTextType] = useState("");
  const [text, setText] = useState("");
  const [id, setId] = useState("");

  const [reviewers, setReviewers] = useState([]);
  const [selectableUsers, setSelectableUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [reviewPeriod, setReviewPeriod] = useState("");

  const [skillGroups, setSkillGroups] = useState([]);
  const [selectedSkillGroup, setSelectedSkillGroup] = useState("");

  const [open, setOpen] = useState(false);
  const [openAddReviewer, setOpenAddReviewer] = useState(false);

  const api = useApi();

  useEffect(() => {
    setIsLoading(true);
    const loadData = async () => {
      await Promise.all([
        loadStdTexts(),
        loadReviewers(),
        loadActorPermitReviewPeriod(),
        loadSkillGroups()
      ]);
      setIsLoading(false);
    };
    loadData();
  }, []);

  useEffect(async () => {
    if (openAddReviewer === false) {
      return;
    }
    await loadUsers();
  }, [openAddReviewer]);

  async function loadStdTexts() {
    
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}permits/standardtexts`,
        false,
        "GET"
      );

      if (data.isSuccessful) {
        setTasks(data.tasks);
        setOtherEquipments(data.otherEquipments);
        setAreas(data.areas);
      }
    
  }

  async function loadSkillGroups() {
      const res = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}skillgroups/for-permits?includeImageUrls=false`,
        false,
        "GET"
      );
      
      if (Array.isArray(res) && res.length > 0) {
        setSkillGroups(res);
      }
  }

  function getTypesTranslation(type) {
    switch (type) {
      case 1:
        return Translate.get("WorkTasks");
      case 2:
        return Translate.get("OtherEquipment");
      case 3:
        return Translate.get("Area");
      default:
        return "";
    }
  }

  function maxCharsForTextType(textType) {
    switch (textType) {
      case 1:
        return 250;
      case 2:
        return 100;
      case 3:
        return 250;
      default:
        return "";
    }
  }

  function getDotMenuActions(id, text, textType, skillGroupId) {
    return [
      {
        icon: <EditIcon />,
        text: Translate.get("Alter"),
        onClick: () => updateStdText(id, text, textType, skillGroupId),
      },
      {
        icon: <DeleteIcon />,
        text: Translate.get("Delete"),
        onClick: () => deleteStdText(id),
      },
    ];
  }

  function updateStdText(id, text, type, skillGroupId) {
    setId(id);
    setText(text);
    setTextType(type);

    if (skillGroupId === null) {
      setSelectedSkillGroup(-1);
    } else {
      setSelectedSkillGroup(skillGroupId)
    }
    setOpen(true);
  }

  function addStdText(textType) {
    setTextType(textType);
    setSelectedSkillGroup(-1);
    setOpen(true);
  }

  function handleClose() {
    setTextType(null);
    setText("");
    setId(null);
    setOpen(false);
  }

  function onTextChange(event) {
    setText(event.target.value);
  }

  async function postOrPutText() {
    if (id == null || id === "") {
      await postText();
    } else {
      await putText();
    }
  }

  async function postText() {
    setIsLoading(true);

    let skillGroupId;
    if (selectedSkillGroup === -1) {
      skillGroupId = null;
    } else {
      skillGroupId = selectedSkillGroup;
    }

    let reqObj = {
      textType: textType,
      text: text,
      skillGroupId: skillGroupId,
    };

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/standardtext`,
      reqObj,
      "POST"
    );

    if (res.isSuccessful) {
      await loadStdTexts();
      handleClose();
    }

    setIsLoading(false);
  }

  async function putText() {
    setIsLoading(true);

    let skillGroupId;
    if (selectedSkillGroup === -1) {
      skillGroupId = null;
    } else {
      skillGroupId = selectedSkillGroup;
    }

    let reqObj = {
      id: id,
      textType: textType,
      text: text,
      skillGroupId: skillGroupId,
    };

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/standardtext`,
      reqObj,
      "PUT"
    );

    if (res.isSuccessful) {
      await loadStdTexts();
      handleClose();
    }

    setIsLoading(false);
  }

  async function deleteStdText(id) {
    setIsLoading(true);

    let resObj = {
      id: id,
    };

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/standardtext`,
      resObj,
      "DELETE"
    );

    if (res.isSuccessful) {
      await loadStdTexts();
    }

    setIsLoading(false);
  }

  function listStdTexts(stdTexts, textType) {
    return (
      <Paper elevation={1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "1em" }}
        >
          <h4
            style={{
              fontSize: "18px",
            }}
          >
            {Translate.get("StdTexts")} - {getTypesTranslation(textType)}
          </h4>

          <Tooltip title={Translate.get("ActorPermitStdTextHelp")}>
            <IconButton>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>

        {stdTexts.length < 1 && (
          <div>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                <img
                  src="/static/no-data-empty-box.svg"
                  alt="empty state icon"
                ></img>
              </Grid>
              <Grid item xs={1}>
                <p
                  style={{
                    whiteSpace: "pre-line",
                    textAlign: "center",
                    marginTop: 3,
                  }}
                >
                  {Translate.get("StdTextEmptyState")}
                </p>
              </Grid>
            </Grid>
          </div>
        )}

        {stdTexts.length > 0 && (
          <List>
            {stdTexts.map((stdText) => (
              <ListItem
                secondaryAction={
                  <DotMenu
                    content={getDotMenuActions(
                      stdText.id,
                      stdText.text,
                      textType,
                      stdText.skillGroupId
                    )}
                    horizontal
                  />
                }
                key={stdText.id}
              >
                <ListItemAvatar>
                  <Avatar>
                    <NotesIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={stdText.text} />
              </ListItem>
            ))}
          </List>
        )}
        <Grid container justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => addStdText(textType)}
            style={{ margin: "1em" }}
          >
            {Translate.get("Add")}
          </Button>
        </Grid>
      </Paper>
    );
  }

  function listActorPermitReviewers(reviewers) {
    return (
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "1em" }}
        >
          <h4
            style={{
              fontSize: "18px",
            }}
          >
            {Translate.get("ActorPermitReviewer")}
          </h4>

          <Tooltip title={Translate.get("ActorPermitReviewerHelp")}>
            <IconButton>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>

        {reviewers.length < 1 && (
          <div>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={3}>
                <img
                  src="/static/no-data-empty-box.svg"
                  alt="empty state icon"
                ></img>
              </Grid>
              <Grid item xs={1}>
                <p
                  style={{
                    whiteSpace: "pre-line",
                    textAlign: "center",
                    marginTop: 3,
                  }}
                >
                  {Translate.get("ActorPermitReviewersEmptyState")}
                </p>
              </Grid>
            </Grid>
          </div>
        )}

        {reviewers.length > 0 && (
          <List>
            {reviewers.map((reviewer) => (
              <ListItem
                secondaryAction={
                  <DotMenu
                    content={getReviewerDotMenuActions(reviewer.ePortUserId)}
                    horizontal
                  />
                }
                key={reviewer.ePortUserId}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PreviewIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={reviewer.ePortAccountId}
                  secondary={reviewer.firstName + " " + reviewer.lastName}
                />
              </ListItem>
            ))}
          </List>
        )}
        <Grid container justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => setOpenAddReviewer(true)}
            style={{ margin: "1em" }}
          >
            {Translate.get("Add")}
          </Button>
        </Grid>
      </div>
    );
  }

  async function loadReviewers() {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}notificationSubscriber/get-subscribers/17`,
        false,
        "GET"
      );

      if (data.isSuccessful) {
        setReviewers(data.subscribers);
      }
  }

  function getReviewerDotMenuActions(ePortUserId) {
    return [
      {
        icon: <DeleteIcon />,
        text: Translate.get("Delete"),
        onClick: () => deleteReviewer(ePortUserId),
      },
    ];
  }

  async function deleteReviewer(ePortUserId) {
    setIsLoading(true);

    let reqObj = {
      notificationType: 17,
      ePortUserId: ePortUserId,
    };

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}notificationSubscriber`,
      reqObj,
      "DELETE"
    );

    if (res.isSuccessful) {
      await loadReviewers();
      document.getElementById("listActorPermitReviewers")?.scrollIntoView();
    }

    setIsLoading(false);
  }

  function handleCloseAddReviwer() {
    setOpenAddReviewer(false);
    setSelectedUser("");
    document.getElementById("listActorPermitReviewers")?.scrollIntoView();
  }

  async function loadUsers() {
    setIsLoading(true);

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/connections/nonlicenseissuer`,
      false,
      "GET"
    );

    if (res && res.isSuccessful) {
      let users = getSelectableUsers(res.connections);
      let sortedUsers = sortUsersByAccountId(users);
      setSelectableUsers(sortedUsers);
    }

    setIsLoading(false);
  }

  function getSelectableUsers(users) {
    if (!users) {
      return [];
    }

    let us = [];
    users.forEach((element) => {
      if (element.hasCourseAdmin === true) {
        us.push(element);
      }
    });

    return us;
  }

  function handleSelectUser(selectedUser) {
    setSelectedUser(selectedUser);
  }

  function sortUsersByAccountId(usersArray) {
    let sortedUsers = usersArray.sort((a, b) =>
      a.ePortAccountId > b.ePortAccountId ? 1 : -1
    );
    return sortedUsers;
  }

  async function postAddReviewer() {
    setIsLoading(true);

    let reqObj = {
      ePortUserId: selectedUser,
      emailNotice: true,
      ePortNotice: true,
      notificationType: 17,
    };

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}notificationSubscriber/addOrUpdateSubscriber`,
      reqObj,
      "POST"
    );

    if (res.isSuccessful) {
      await loadReviewers();
      handleCloseAddReviwer();
    }

    setIsLoading(false);
  }

  function actorPermitReviewPeriod() {
    return (
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: "1em" }}
        >
          <h4
            style={{
              marginBottom: 10,
              marginTop: 20,
              marginLeft: 20,
              paddingTop: 20,
              fontSize: "18px",
            }}
          >
            {Translate.get("ActorPermitReviewPeriod")}
          </h4>

          <Tooltip title={Translate.get("ReviewPeriodHelp")}>
            <IconButton>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormControl style={{ marginTop: "2em", marginBottom: "2em" }}>
            <InputLabel id="selectPeriod">{Translate.get("Period")}</InputLabel>
            <Select
              value={reviewPeriod}
              label={Translate.get("Period")}
              onChange={(event) =>
                PostActorPermitReviewPeriod(event.target.value)
              }
              style={{ minWidth: "22em" }}
            >
              <MenuItem value={12}>
                1 {Translate.get("Years").toLowerCase()}
              </MenuItem>
              <MenuItem value={24}>
                2 {Translate.get("Years").toLowerCase()}
              </MenuItem>
              <MenuItem value={36}>
                3 {Translate.get("Years").toLowerCase()}
              </MenuItem>
              <MenuItem value={60}>
                5 {Translate.get("Years").toLowerCase()}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }

  async function loadActorPermitReviewPeriod() {
    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}dataIdSettings/actorPermitReviewPeriod`,
      false,
      "GET"
    );

    if (res && res.isSuccessful) {
      let months = parseInt(res.settingValue, 10);
      if (isNaN(months)) {
        setReviewPeriod(12); // Standardvärde om inställningsvärde saknas
      } else {
        setReviewPeriod(months);
      }
    }
  }

  async function PostActorPermitReviewPeriod(reviewPeriod) {
    setIsLoading(true);

    let reqObj = {
      reviewPeriod: reviewPeriod,
    };

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}dataIdSettings/actorpermit/reviewperiod`,
      reqObj,
      "POST"
    );

    if (res.isSuccessful) {
      setReviewPeriod(reviewPeriod);
    }

    setIsLoading(false);
    document.getElementById("anchor")?.scrollIntoView();
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {isLoading && <Preloader />}

      {!isLoading && (
        <Box sx={{ minWidth: 120, maxWidth: 400 }}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                width: "100%",
                maxWidth: "720px!important",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {getTypesTranslation(textType)}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <TextField
                  required
                  id="outlined-required"
                  label={Translate.get("Text")}
                  sx={{ mt: 2 }}
                  value={text}
                  onChange={onTextChange}
                  fullWidth
                  multiline={true}
                  minRows={3}
                  inputProps={{ maxLength: maxCharsForTextType(textType) }}
                />


                <FormControl fullWidth style={{ marginTop: "2em" }}>
                  <InputLabel id="setSkillGroup">{Translate.get("MachineGroup")}</InputLabel>
                  <Select
                    labelId="setSkillGroup"
                    id="select-skill-group"
                    value={selectedSkillGroup}
                    onChange={(event) => setSelectedSkillGroup(event.target.value)}
                    label={Translate.get("SkillGroup")}
                  >
                    <MenuItem value={-1}>{Translate.get("AllLabel")}</MenuItem>
                    {skillGroups.map((group) => (
                      <MenuItem key={group.skillGroupId} value={group.skillGroupId}>
                        {group.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                {Translate.get("Close")}
              </Button>
              <Button onClick={postOrPutText}>{Translate.get("Save")}</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openAddReviewer}
            onClose={handleCloseAddReviwer}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                width: "100%",
                maxWidth: "720px!important",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {Translate.get("Add")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <FormControl fullWidth style={{ marginTop: "2em" }}>
                  <InputLabel id="setReviewer">
                    {Translate.get("ActorPermitReviewer")}
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedUser}
                    onChange={(event) => handleSelectUser(event.target.value)}
                    label={Translate.get("ActorPermitReviewer")}
                  >
                    {selectableUsers.map((user) => (
                      <MenuItem key={user.ePortUserId} value={user.ePortUserId}>
                        {user.ePortAccountId}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddReviwer} autoFocus>
                {Translate.get("Close")}
              </Button>
              <Button onClick={postAddReviewer} disabled={selectedUser === ""}>
                {Translate.get("Save")}
              </Button>
            </DialogActions>
          </Dialog>

          {listStdTexts(tasks, 1)}
          <div style={{ marginTop: 20 }}>
            {listStdTexts(otherEquipments, 2)}
          </div>
          <div style={{ marginTop: 20 }}>{listStdTexts(areas, 3)}</div>


          <Paper
            elevation={1}
            id="actorPermitReviewPeriod"
            style={{ marginTop: "1em" }}
          >
            {listActorPermitReviewers(reviewers)}
            <Divider />
            <div id="anchor">{actorPermitReviewPeriod()}</div>
          </Paper>

        </Box>
      )}
    </div>
  );
}
