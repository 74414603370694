import {
  Button,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Stack,
  Chip,
} from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import Translate from "../../utils/Translate";
import { useApi } from "../../utils/Api";
import SelectActorPermitSkillsDialog from "./SelectActorPermitSkillsDialog";
import Preloader from "../Preloader";

async function loadSkillGroups(api) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}skillgroups/for-permits?includeImageUrls=true`,
    false,
    "GET"
  );
}

async function loadAllActorSkills(api, actors) {
  const actorIds = actors.map((x) => x.actorId).join(",");
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}actors/skills/for-new-permit?actorIds=${actorIds}`,
    false,
    "GET"
  );
}

export default function SelectActorPermitSkills({
  actors,
  selectedPermitSkills,
  onSelectedPermitSkillsChanged,
  errorMessage,
  isCloneFromActorPermit,
  setSuggestions,
}) {
  const [isLoadingCount, setIsLoadingCount] = useState(0);
  const [showSkillSelectDialog, setShowSkillSelectDialog] = useState(false);
  const [skillGroups, setSkillGroups] = useState([]);
  const [selectedSkillGroup, setSelectedSkillGroup] = useState(null);
  const [actorSkills, setActorSkills] = useState([]);
  const [availableSkills, setAvailableSkills] = useState([]);

  const api = useApi();

  useEffect(() => {
    if (actors && actors.length > 0) {
      setIsLoadingCount((prevCount) => prevCount + 1);
      loadAllActorSkills(api, actors)
        .then((skills) => setActorSkills(skills))
        .finally(() => setIsLoadingCount((prevCount) => prevCount - 1));
    }
  }, [actors, api]);

  useEffect(() => {
    setIsLoadingCount((prevCount) => prevCount + 1);
    loadSkillGroups(api)
      .then((skillGroups) => {
        setSkillGroups(skillGroups);
        if (selectedPermitSkills?.[0].skillGroupId) {
          changeSkillGroup(selectedPermitSkills?.[0].skillGroupId, skillGroups);
        }
      })
      .finally(() => setIsLoadingCount((prevCount) => prevCount - 1));
  }, [api]);

  async function changeSkillGroup(skillGroupId, skillGroups) {
    if (skillGroupId) {
      const skillGroup = skillGroups.find(
        (x) => x.skillGroupId === skillGroupId
      );

      const sortedSkills = skillGroup.skills.sort((lhs, rhs) =>
        `${lhs.shortCode} ${lhs.description}`.localeCompare(
          `${rhs.shortCode} ${rhs.description}`
        )
      );

      let suggestions = await loadSuggestedValues(api, skillGroupId);
      setSuggestions(suggestions);

      setSelectedSkillGroup(skillGroup);
      setAvailableSkills(sortedSkills);
      if (isCloneFromActorPermit) {
        return;
      }
      initializePermitSkillsFromActorSkills(sortedSkills);
    }
  }

  async function loadSuggestedValues(api, skillGroupId) {
    return await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/suggested-values/${skillGroupId}`,
      false,
      "GET"
    );
  }

  // Klonade skills har inte alla properties, fyll i dessa
  function enrichClonedSelectedSkills(selectedSkills, availableSkills) {
    const availableSkillsMap = new Map();
    availableSkills.forEach((skill) => {
      availableSkillsMap.set(skill.skillId, skill);
    });

    selectedSkills.forEach((selectedSkill) => {
      const matchingSkill = availableSkillsMap.get(selectedSkill.skillId);
      if (matchingSkill) {
        Object.assign(selectedSkill, matchingSkill);
      }
    });

    return selectedSkills;
  }

  function initializePermitSkillsFromActorSkills(availableSkills) {
    const initialSkillsToSelect = availableSkills.filter((s) =>
      actorSkills.some((us) => us.skillId === s.skillId)
    );
    onSelectedPermitSkillsChanged(initialSkillsToSelect, true);
  }

  return (
    <>
      {isLoadingCount > 0 && <Preloader />}
      {showSkillSelectDialog && (
        <SelectActorPermitSkillsDialog
          availableSkills={availableSkills}
          selectedSkills={isCloneFromActorPermit ? enrichClonedSelectedSkills(selectedPermitSkills, availableSkills) : selectedPermitSkills}
          onSelectedPermitSkillsChanged={onSelectedPermitSkillsChanged}
          onClose={() => setShowSkillSelectDialog(false)}
        />
      )}
      {isLoadingCount === 0 && (
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="competence-group-label">
              {Translate.get("MachineGroup")}
            </InputLabel>
            <Select
              required
              fullWidth
              variant="outlined"
              onChange={(event) =>
                changeSkillGroup(event.target.value, skillGroups)
              }
              value={selectedSkillGroup?.skillGroupId ?? ""}
              labelId="competence-group-label"
              label={Translate.get("MachineGroup")}
              placeholder={Translate.get("MachineGroup")}
              disabled={isCloneFromActorPermit}
            >
              {skillGroups.map((sg) => (
                <MenuItem value={sg.skillGroupId} key={sg.skillGroupId}>
                  {sg.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack spacing={0.3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className="smallHeader">{`${Translate.get("MachineType")} (${
                selectedPermitSkills.length
              })`}</div>
              <Button
                className={
                  "smallHeader button" +
                  (!selectedSkillGroup ? " disabled" : "")
                }
                onClick={() => setShowSkillSelectDialog(true)}
                disabled={!selectedSkillGroup}
              >
                {Translate.get("EditMachineTypes")}
              </Button>
            </Stack>
            {/* TODO max width? */}
            <Grid
              container
              direction="row"
              spacing={1}
              sx={{ flexWrap: "wrap" }}
            >
              {selectedPermitSkills.map((skill) => (
                <Grid item xs="auto">
                  <Chip
                    key={`${skill.shortCode} ${skill.description}`}
                    label={skill.shortCode}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
          {/* TODO */}
          {errorMessage && "Error:" + errorMessage}
        </Stack>
      )}
    </>
  );
}
