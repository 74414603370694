import { Box, Stack } from "@mui/material";

export default function AddressPrintView({
  street1,
  street2,
  zipCode,
  city,
  attention,
}) {
  return (
    <Stack direction="column" color="black" marginTop={3}>
      <Box>{street1}</Box>
      {attention && <Box>Att: {attention}</Box>}
      <Box>{street2}</Box>
      <Box>{`${zipCode} ${city}`}</Box>
    </Stack>
  );
}
