import { Box, Grid, useMediaQuery } from "@mui/material";
import { useState, useEffect, useRef, useCallback } from "react";
import { useApi } from "../../utils/Api";
import LicenseRegistrationStep1Card from "./LicenseRegistrationStep1Card";
import LicenseRegistrationStep2CardBig from "./LicenseRegistrationStep2CardBig";
import LicenseRegistrationStep2CardSmall from "./LicenseRegistrationStep2CardSmall";
import LicenseRegistrationStep3Card from "./LicenseRegistrationStep3Card";
import Translate from "../../utils/Translate";
import { actorSearch, actorPersonTypes } from "../../utils/actorSearch";
import CardPrintAtMaSystemDialog from "../actor-license-print/CardPrintAtMaSystemDialog";
import Preloader from "../Preloader";
import { useSearchParams } from "react-router-dom";
import download from "downloadjs";
import { licenseFormatTypeEnum } from "../../utils/licenseFormatTypes";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import { validateDigitalLicenseReceiver } from "../actor-permits/validation/ActorValidator";

function loadLicenseFormatTypes() {
  const licenseFormatTypes = localStorage.getItem("licenseFormatTypes");
  return licenseFormatTypes ? JSON.parse(licenseFormatTypes) : {};
}

function saveLicenseFormatType(licenseFormatType) {
  // Code copied from ActorLicensePrintDialog where other printTypes are available.
  // We just hardcode "card" here, then this setting will be compatible with the other
  const printType = "card";

  const cachedLicenseFormatTypes = loadLicenseFormatTypes();
  const updatedLicenseFormatTypes = {
    ...cachedLicenseFormatTypes,
    [printType]: licenseFormatType,
  };
  localStorage.setItem(
    "licenseFormatTypes",
    JSON.stringify(updatedLicenseFormatTypes)
  );
}

function getDefaultLicenseFormatType() {
  // Code copied from ActorLicensePrintDialog where other printTypes are available.
  // We just hardcode "card" here, then this setting will be compatible with the other
  const printType = "card";

  if (!hasAccess(accessKeys.digitalLicenses)) {
    return licenseFormatTypeEnum.physical;
  }

  const cachedLicenseFormatTypes = loadLicenseFormatTypes();
  return (
    cachedLicenseFormatTypes[printType] ??
    (printType === "card"
      ? licenseFormatTypeEnum.digital
      : licenseFormatTypeEnum.physical)
  );
}

export default function LicenseRegistration() {
  const isSmallScreen = useMediaQuery("(max-width:700px)");
  const [showPrintAtMaSystem, setShowPrintAtMaSystem] = useState(false);
  const [selectedCompetenceType, setSelectedCompetenceType] = useState("");
  const [visibleCompetences, setVisibleCompetences] = useState([]);
  const [students, setStudents] = useState([]);
  const [nextStudentId, setNextStudentId] = useState(1);
  const [competences, setCompetences] = useState([]);
  const [competencesDefaults, setCompetencesDefaults] = useState([]);
  const [availableCompetences, setAvailableCompetences] = useState({});
  const [resultValidationMessage, setResultValidationMessage] = useState("");
  const [resultValidationStatus, setResultValidationStatus] = useState(0);
  const [date, setDate] = useState(null);
  const [gdprAccepted, setGdprAccepted] = useState(false);
  const [ssnValidation, setSsnValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLicenseEditLocked, setIsLicenseEditLocked] = useState(false);
  const [isPersonEditLocked, setIsPersonEditLocked] = useState(false);
  const [
    selectCompetenceInformationMessage,
    setSelectCompetenceInformationMessage,
  ] = useState("");
  const [licenseFormatType, setLicenseFormatType] = useState(
    getDefaultLicenseFormatType()
  );
  const [doValidate, setDoValidate] = useState(false);

  const api = useApi();

  const [searchParams] = useSearchParams();
  // If either of these parameters are set that means we want to load data and populate some fields.
  const courseId = searchParams.get("courseId");
  const actorId = searchParams.get("actorId");

  async function handlePrint() {
    window.analyticsEvent("eport_xtras", "eport_license_receipt");
    setIsLoading(true);

    const receiptRequest = {
      courseType: selectedCompetenceType,
      courseId: courseId,
      date: date,
      students: students,
    };
    const blob = await api.fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}license-registration/receipt`,
      receiptRequest,
      "POST"
    );
    const filename = `${Translate.get("Receipt")}.html`;
    download(blob, filename, blob.type);
    setIsLoading(false);
  }

  function dataIsInvalidForSubmit() {
    return (
      !(
        selectedCompetenceType &&
        date &&
        students.length > 0 &&
        gdprAccepted
      ) || students.some((s) => !!s.error)
    );
  }

  const handleLicenseFormatTypeChange = useCallback((licenseFormatTypeProp) => {
    setLicenseFormatType(licenseFormatTypeProp);
    saveLicenseFormatType(licenseFormatTypeProp);
    setDoValidate(true);
  }, []);

  useEffect(() => {
    async function loadSkills() {
      const loadedSkillGroups = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}skillgroups/for-license-registration?includeImageUrls=true`,
        false,
        "GET"
      );

      // The internalId here is something from the old solution, when we had a separate list
      // of skills and would send the print jobs to 1158 first, and then to internal ePort.
      // But there is still some logic here in frontend relying on these internalIds, that
      // is general for all DataIds, so we cannot rewrite it to use normal skillId instead.
      // Furthermore, need to do skillId.toString, because we will compare to object keys,
      // and don't want to re-write too much of the code right now.
      const skills = loadedSkillGroups.flatMap((skillGroup) =>
        skillGroup.skills.map((skill) => ({
          id: skill.skillId.toString(),
          internalId: `${skillGroup.description.toLowerCase().charAt(0)}_${
            skill.shortCode
          }`,
          skillGroupId: skillGroup.skillGroupId,
          category: skillGroup.description,
          column: skill.shortCode,
          description: skill.description,
          imageUrl: skill.imageUrl,
        }))
      );

      const defaultSkillValues = Object.fromEntries(
        skills.map((skill) => [skill.id, false])
      );

      setCompetences(skills);
      setCompetencesDefaults(defaultSkillValues);

      setAvailableCompetences(
        Object.fromEntries(
          loadedSkillGroups.map((sg) => [sg.description, true])
        )
      );

      if (courseId) {
        const response = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}license-registration/courses/${courseId}/all-skills`,
          false,
          "GET"
        );

        // Need to do toString here for the same reason as mentioned above
        let activeSkills = skills.filter((s) =>
          response.activeSkillIds.map((a) => a.toString()).includes(s.id)
        );

        if (response.data.date) {
          const date = new Date(response.data.date);
          setDate(date.toISOString().split("T")[0]);
        }

        let studentId = 1;
        const students = response.data.students;

        // LK/T är en delmängd av K/T. Båda får inte väljas
        let kt = activeSkills.find((i) => i.internalId === "k_K/T");
        let lkt = activeSkills.find((i) => i.internalId === "k_LK/T");

        let removeLktFromCompetences = false;
        students.forEach((s) => {
          s.id = studentId++;

          let ktChecked =
            kt?.id in s.competences && s.competences[kt.id] === true;
          let lktChecked =
            lkt?.id in s.competences && s.competences[lkt.id] === true;
          if (ktChecked && lktChecked) {
            s.competences[lkt.id] = false;
            if (!removeLktFromCompetences) {
              removeLktFromCompetences = true;
            }
          }
        });

        if (removeLktFromCompetences) {
          activeSkills = activeSkills.filter((a) => a.internalId !== "k_LK/T");
        }

        students.forEach((s) => (s.competences = { ...defaultSkillValues }));

        setSelectedCompetenceType(response.data.courseType);
        setStudents(students);
        setNextStudentId(studentId);

        setVisibleCompetences(
          activeSkills.sort((a, b) =>
            a.column > b.column ? 1 : b.column > a.column ? -1 : 0
          )
        );
        // This used to be set when this component was used for Nivå 2, to avoid editing an
        // existing actorLicense. Not sure if we will need it for anything again.
        setIsLicenseEditLocked(false);
        setIsPersonEditLocked(true);
      } else if (actorId) {
        api
          .fetch(
            `${process.env.REACT_APP_MAIN_URL}actors/${actorId}`,
            false,
            "GET"
          )
          .then((data) => {
            if (data) {
              setStudents([
                {
                  id: 0,
                  actorId: actorId,
                  name: data.actorName,
                  socialSecurityNumber: data.orgNo,
                  selectCompetenceInformationMessage: "",
                  competences: { ...defaultSkillValues },
                },
              ]);
            }
          });
      }
      setIsLoading(false);
    }

    loadSkills();
  }, [api, courseId, actorId]);

  // Validate students
  useEffect(() => {
    console.log("validate", doValidate);
    if (
      !doValidate ||
      !licenseFormatType ||
      !students ||
      students.length === 0
    ) {
      return;
    }

    setDoValidate(false);
    const needsValidation =
      licenseFormatType === licenseFormatTypeEnum.digital ||
      licenseFormatType === licenseFormatTypeEnum.both;

    if (needsValidation) {
      const validatedStudents = students.map((s) => ({
        ...s,
        error: validateDigitalLicenseReceiver(
          s.actorEmail,
          s.phoneNumber,
          s.socialSecurityNumber
        ),
      }));
      setStudents(validatedStudents);
    } else {
      setStudents((prev) => prev.map((p) => ({ ...p, error: false })));
    }
  }, [doValidate, students, licenseFormatType]);

  function handleCompetenceTypeChange(competenceType) {
    if (selectedCompetenceType === competenceType) return;

    var studentsCopy = students.slice();
    var studentsLength = studentsCopy.length;

    for (let i = 0; i < studentsLength; ++i) {
      studentsCopy[i].competences = { ...competencesDefaults };
    }
    setSelectedCompetenceType(competenceType);
    setVisibleCompetences([]);
    setStudents(studentsCopy);
    setGdprAccepted(false);
    setSsnValidation(false);
    setIsLoading(false);
  }

  function handlePrintAtMaSystemClose(isPrintDone) {
    setShowPrintAtMaSystem(false);
    if (isPrintDone) {
      setResultValidationStatus(1);
      setResultValidationMessage(Translate.get("OrderOk"));
    } else {
      setResultValidationStatus(-1);
      setResultValidationMessage(Translate.get("SomethingFailed"));
    }
  }

  function handleCompetenceAddRemove(competence) {
    setSelectCompetenceInformationMessage("");
    let visibleCompetencesCopy = visibleCompetences.slice();
    let studentsCopy = students.slice();
    let index = visibleCompetencesCopy.findIndex(
      (i) => i.internalId === competence.internalId
    );

    // Kompetensen är vald sedan tidigare. Ta bort den.
    if (index > -1) {
      visibleCompetencesCopy.splice(index, 1);
      setVisibleCompetences(visibleCompetencesCopy);

      for (let i = 0; i < studentsCopy.length; ++i) {
        studentsCopy[i].competences[competence.id] = false;
      }
      setStudents(studentsCopy);
      return;
    }

    // LK/T är en delmängd av K/T. Båda får inte väljas
    if (
      (competence.internalId === "k_LK/T" &&
        visibleCompetencesCopy.some((i) => i.internalId === "k_K/T")) ||
      (competence.internalId === "k_K/T" &&
        visibleCompetencesCopy.some((i) => i.internalId === "k_LK/T"))
    ) {
      let errorMessage = Translate.get("LKTSubsetKT");
      setSelectCompetenceInformationMessage(errorMessage);
      return;
    }

    // Lägg till kompetensen
    visibleCompetencesCopy.push(competence);
    setVisibleCompetences(visibleCompetencesCopy);

    for (let i = 0; i < studentsCopy.length; ++i) {
      studentsCopy[i].competences[competence.id] = false;
    }
    setStudents(studentsCopy);
  }

  function handleStudentAddRemoveCompetence(student, competenceId) {
    var studentsCopy = students.slice();
    var foundIndex = studentsCopy.indexOf(student);
    studentsCopy[foundIndex].competences[competenceId] =
      !studentsCopy[foundIndex].competences[competenceId];
    setStudents(studentsCopy);
  }
  function handleApproveAllCompetences() {
    const studentsCopy = students.slice();
    for (const student of studentsCopy) {
      for (const competenceId in student.competences) {
        // välj bara kompetenser som är synliga för vald kompetensgrupp
        const isSet = visibleCompetences.some(
          (comp) => comp.id === competenceId
        );
        student.competences[competenceId] = isSet;
      }
    }

    setStudents(studentsCopy);
  }

  function handleApproveAllCompetencesForStudent(studentId) {
    var studentIndex = students.findIndex((s) => s.id === studentId);
    const studentCompetencesCopy = { ...students[studentIndex].competences };
    for (const competenceId in studentCompetencesCopy) {
      // välj bara kompetenser som är synliga för vald kompetensgrupp
      const isSet = visibleCompetences.some((comp) => comp.id === competenceId);
      studentCompetencesCopy[competenceId] = isSet;
    }
    const studentCopy = {
      ...students[studentIndex],
      competences: studentCompetencesCopy,
    };
    const studentsCopy = [...students];
    studentsCopy.splice(studentIndex, 1, studentCopy);
    setStudents(studentsCopy);
  }

  function handleAddRemoveCompetenceAllStudents(competenceId, competenceSet) {
    var studentsCopy = students.slice();

    var studentsLength = studentsCopy.length;
    for (var i = 0; i < studentsLength; ++i) {
      studentsCopy[i].competences[competenceId] = competenceSet;
    }

    setStudents(studentsCopy);
  }

  function handleAddStudent(student) {
    console.log("handleAddStudent", student);
    student.id = nextStudentId;
    student.competences = { ...competencesDefaults };
    var studentsCopy = [...students, student];

    setStudents(studentsCopy);
    setNextStudentId(nextStudentId + 1);
    setDoValidate(true);
  }

  function handleUpdateStudent(student) {
    console.log("handleUpdateStudent", student);
    if (!student.id) {
      return;
    }

    var studentIndex = students.findIndex((s) => s.id === student.id);
    if (studentIndex !== -1) {
      const studentsCopy = [...students];
      studentsCopy.splice(studentIndex, 1, student);
      setStudents(studentsCopy);
      setDoValidate(true);
    }
  }

  function handleRemoveStudent(student) {
    var studentsCopy = students.slice();
    var foundIndex = studentsCopy.indexOf(student);

    if (foundIndex !== -1) {
      studentsCopy.splice(foundIndex, 1);
      setStudents(studentsCopy);
    }
  }

  async function handleTryPrintAtMaSystem() {
    if (resultValidationStatus === 1) {
      // We have already sent a result successfully
      return;
    }

    if (gdprAccepted === false) {
      setResultValidationStatus(-1);
      setResultValidationMessage(
        Translate.get("LincenseRegistrationGdprAcceptRequired")
      );
      return;
    }

    if (isLoading === false) {
      setIsLoading(true);

      const validationRequest = {
        courseType: selectedCompetenceType,
        date: date,
        students: students,
        licenseFormatType: licenseFormatType,
      };

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}license-registration/validate`,
        validationRequest
      );

      if (response.isSuccessful) {
        setShowPrintAtMaSystem(true);
        setIsLoading(false);
      } else {
        setResultValidationStatus(-1);
        setResultValidationMessage(
          response.validationErrorMessage ?? Translate.get("SomethingFailed")
        );
        setIsLoading(false);
      }
    }
  }

  function handleReset() {
    setDate(null);
    setSelectedCompetenceType("");
    setIsLoading(false);
    setVisibleCompetences([]);
    setStudents([]);
    setNextStudentId(1);
    setResultValidationMessage("");
    setResultValidationStatus(0);
    setGdprAccepted(false);
    setSsnValidation(false);
    setIsLicenseEditLocked(false);
    setIsPersonEditLocked(false);
  }

  function gdprCheckboxHandeler() {
    var studentsCopy = students.slice();

    if (gdprAccepted) {
      setGdprAccepted(false);

      studentsCopy.forEach(function (student) {
        student.hasApprovedGdpr = false;
      });
      setStudents(studentsCopy);
    } else {
      setGdprAccepted(true);

      studentsCopy.forEach(function (student) {
        student.hasApprovedGdpr = true;
      });
      setStudents(studentsCopy);
    }
  }

  function ssnValidationCheckBoxHandler() {
    var studentsCopy = students.slice();

    if (ssnValidation) {
      setSsnValidation(false);

      studentsCopy.forEach(function (student) {
        student.ssnValidation = false;
      });
      setStudents(studentsCopy);
    } else {
      setSsnValidation(true);

      studentsCopy.forEach(function (student) {
        student.ssnValidation = true;
      });
      setStudents(studentsCopy);
    }
  }

  async function personSearch(searchText) {
    const foundActors = await actorSearch(
      searchText,
      actorPersonTypes,
      ["ActorName"],
      50
    );
    if (foundActors) {
      return foundActors
        .filter((actor) => !students.some((d) => d.actorId === actor.actorId))
        .map((actor) => ({
          actorId: actor.actorId,
          actorName: actor.actorName,
          orgNo: actor.orgNo,
          actorEmail: actor.email,
          profilePictureUrl: actor.profilePictureUrl,
          phoneNumber: actor.phoneNumbers?.find((ph) => ph.type === 2)
            ?.phoneNumber,
          companies: actor.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId !== actor.actorId &&
                x.parentActor.actorName !== "ePortMaster" &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => x.parentActor.actorName),
        }));
    }
  }

  function getPrintAtMASystemRequestData() {
    return {
      courseType: selectedCompetenceType,
      date: date,
      students: students,
      publishGroup: {
        contactEmail: null, // sessionStorage.userEmail ?? null,
        contactName: null,
      },
    };
  }

  return (
    <>
      {isLoading && <Preloader />}
      <CardPrintAtMaSystemDialog
        open={showPrintAtMaSystem}
        onClose={handlePrintAtMaSystemClose}
        licenseFormatType={licenseFormatType}
        isGenericActorLicenses={true}
        extraRequestData={getPrintAtMASystemRequestData()}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={3}
          wrap="nowrap"
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          maxWidth="1200px"
        >
          <Grid item>
            <LicenseRegistrationStep1Card
              date={date}
              onDateChange={(newDate) => setDate(newDate)}
              availableCompetences={availableCompetences}
              selectedCompetenceType={selectedCompetenceType}
              onCompetenceTypeChange={handleCompetenceTypeChange}
              competenceInformationMessage={selectCompetenceInformationMessage}
              competences={competences}
              visibleCompetences={visibleCompetences}
              onCompetenceClick={handleCompetenceAddRemove}
              isLicenseEditLocked={isLicenseEditLocked}
            />
          </Grid>
          {!isSmallScreen && (
            <Grid item>
              <LicenseRegistrationStep2CardBig
                students={students}
                visibleCompetences={visibleCompetences}
                handleStudentAddRemoveCompetence={
                  handleStudentAddRemoveCompetence
                }
                handleAddStudent={handleAddStudent}
                handleUpdateStudent={handleUpdateStudent}
                handleRemoveStudent={handleRemoveStudent}
                handleAddRemoveCompetenceAllStudents={
                  handleAddRemoveCompetenceAllStudents
                }
                handleApproveAllCompetences={handleApproveAllCompetences}
                isLicenseEditLocked={isLicenseEditLocked}
                isPersonEditLocked={isPersonEditLocked}
                personSearch={personSearch}
                licenseFormatType={licenseFormatType}
              />
            </Grid>
          )}
          {isSmallScreen && (
            <Grid item>
              <LicenseRegistrationStep2CardSmall
                students={students}
                visibleCompetences={visibleCompetences}
                handleStudentAddRemoveCompetence={
                  handleStudentAddRemoveCompetence
                }
                handleAddStudent={handleAddStudent}
                handleUpdateStudent={handleUpdateStudent}
                handleRemoveStudent={handleRemoveStudent}
                handleApproveAllCompetencesForStudent={
                  handleApproveAllCompetencesForStudent
                }
                handleApproveAllCompetences={handleApproveAllCompetences}
                isLicenseEditLocked={isLicenseEditLocked}
                isPersonEditLocked={isPersonEditLocked}
                personSearch={personSearch}
                licenseFormatType={licenseFormatType}
              />
            </Grid>
          )}
          <Grid item>
            <LicenseRegistrationStep3Card
              validationStatus={resultValidationStatus}
              validationMessage={resultValidationMessage}
              gdprIsChecked={gdprAccepted}
              ssnValidationIsChecked={ssnValidation}
              handleSend={handleTryPrintAtMaSystem}
              handleReset={handleReset}
              handleGdprCheckbox={gdprCheckboxHandeler}
              handleSsnValidationCheckbox={ssnValidationCheckBoxHandler}
              handlePrint={handlePrint}
              isSmallScreen={isSmallScreen}
              dataIsInvalidForSubmit={dataIsInvalidForSubmit}
              licenseFormatType={licenseFormatType}
              handleLicenseFormatTypeChange={handleLicenseFormatTypeChange}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
