import add from "date-fns/add";
import { format, addMinutes, subMonths, addMonths } from 'date-fns';

export function formatDateTime(stringDateTime, dateTimelocale = false) {
  if (!dateTimelocale) {
    dateTimelocale = langLocale[localStorage.lang] ?? 'sv-SE';
  }
  const dateTimeFormat = new Intl.DateTimeFormat(dateTimelocale, { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" });
  return dateTimeFormat.format(new Date(stringDateTime));
}
export function formatDate(stringDateTime, dateTimelocale = false) {
  if (!dateTimelocale) {
    dateTimelocale = langLocale[localStorage.lang] ?? 'sv-SE';
  }
  const dateFormat = new Intl.DateTimeFormat(dateTimelocale, { year: "numeric", month: "numeric", day: "numeric" });
  return dateFormat.format(new Date(stringDateTime));
}

export function tryFormatDate(stringDateTime, dateTimelocale = false) {
  var parsedDate = Date.parse(stringDateTime);
  if (isNaN(parsedDate)) {
    return "";
  }
  return formatDate(stringDateTime, dateTimelocale);
}

export function formatTime(stringDateTime, dateTimelocale = false) {
  if (!dateTimelocale) {
    dateTimelocale = langLocale[localStorage.lang] ?? 'sv-SE';
  }
  const timeFormat = new Intl.DateTimeFormat(dateTimelocale, { hour: "numeric", minute: "numeric" });
  return timeFormat.format(new Date(stringDateTime));
}

export function UTCToLocal(utc) {
  let date = new Date(utc);
  return format(addMinutes(date, -date.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss');
}

export function UTCToLocalDateOnly(utc) {
  let date = new Date(utc); 
  let localDate = addMinutes(date, -date.getTimezoneOffset());
  return format(localDate, 'yyyy-MM-dd');
}

export function formatDateWithMonthOffSet(date, offset) {
    return format(addMonths(date, offset), 'yyyy-MM-dd').toString();
}

const englishDayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export function translateDayName(englishDayName) {
  const lang = langLocale[localStorage.lang] ?? 'sv-SE';
  const idx = englishDayNames.indexOf(englishDayName?.toLowerCase());
  if (idx > -1) {
    const aMonday = new Date(2023, 5, 17);
    const tmp = aMonday.setDate(17 + idx);
    return new Intl.DateTimeFormat(lang, { weekday: "long" }).format(tmp);
  }
  return englishDayName;
}


// TODO Kommer vi t.ex. ha svenskt datum format men engelskt språk? Då måste detta göras bättre.
const langLocale = {
  "9": "en-GB",
  "12": "fr-FR",
  "29": "sv-SE",
}

const dateMasks = {
  "29": '____-__-__',
  "12": '__/__/____',
  "9": '__/__/____',
}

const inputFormats = {
  "29": 'yyyy-MM-dd',
  "12": 'dd/MM/yyyy',
  "9": 'dd/MM/yyyy',
}

export function getCurrentDateMask() {
  return dateMasks[localStorage.lang];
}

export function getCurrentInputFormat() {
  return inputFormats[localStorage.lang];
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function toSameDateTimeUTC(date) {
  return !!date ? add(date, { minutes: -date.getTimezoneOffset() }) : date;
}

export function formatDateRange(fromDate, toDate) {
  if (fromDate && toDate) {
    return `${formatDate(fromDate)} – ${formatDate(toDate)}`;
  }
  if (fromDate) {
    return formatDate(fromDate);
  }
  if (toDate) {
    return formatDate(toDate);
  }
}

export function dateCompare(a, b) {
  const date1 = a && new Date(a);
  const date2 = b && new Date(b);
  if (!isValidDate(date1) || !isValidDate(date2)) {
    return 0;
  }

  if (date1 < date2) {
    return -1;
  }
  else if (date2 < date1) {
    return 1;
  }
  else {
    return 0;
  }
}