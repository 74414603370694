import { isMobile } from "is-mobile";
import Translate from "../../utils/Translate";
import Api from "../../utils/Api";

export async function addPermitToGoogleWallet(api, actorPermitId) {
  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}wallet/google/digital-permit?actorPermitId=${actorPermitId}`,
    false,
    "GET"
  );
  if (response.isSuccessful) {
    window.open(response.addToWalletUrl, "_blank", "rel=noopener noreferrer");
  }
}

export async function addPermitToAppleWallet(
  actorPermitId,
  enqueueSnackbar,
  closeSnackbar
) {
  if (!isMobile({ tablet: true, featureDetect: true })) {
    const errorMessage = enqueueSnackbar(
      Translate.get("OnlyMobileSupportBody"),
      {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      }
    );
    return;
  }

  const blob = await Api.fetchBlob(
    `${process.env.REACT_APP_MAIN_URL}wallet/apple/digital-permit?actorPermitId=${actorPermitId}`,
    false,
    "GET"
  );
  var a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = `digitalpermit${actorPermitId}.pkpass`;
  a.click();
}

// export function canShareDigitalLicense() {
//   return (
//     isMobile({ tablet: true, featureDetect: true }) && navigator.canShare()
//   );
// }

// export async function shareDigitalLicenseOrCopyLink(
//   publicShareUrl,
//   enqueueSnackbar,
//   closeSnackbar
// ) {
//   if (isMobile({ tablet: true, featureDetect: true }) && navigator.canShare()) {
//     await navigator.share({
//       title: Translate.get("DigitalLicenseLabel"),
//       url: publicShareUrl,
//     });
//   } else {
//     navigator.clipboard.writeText(publicShareUrl);
//     const infoMessage = enqueueSnackbar(Translate.get("CopiedToClipboard"), {
//       variant: "info",
//       autoHideDuration: 6000,
//       onClick: () => closeSnackbar(infoMessage),
//     });
//   }
// }
