import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import StUnitListItem from "./StUnitListItem";
import { useRef, useState } from "react";
import { partType } from "../../utils/part";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ActorLicensePreview from "../actor-license-print/ActorLicensePreview";
import ActorLicensePrintView from "../actor-license-print/ActorLicensePrintView";
import { useReactToPrint } from "react-to-print";
import Bowser from "bowser";
import YesOrNoDialog from "../YesOrNoDialog";

const cr80CardWidth = 2048;
const cr80cardHeight = 1300;
const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.isBrowser("Safari");

function disableStUnit({ bestBeforeDate }) {
  return bestBeforeDate ? new Date(bestBeforeDate) < new Date() : false;
}

function getStUnitSelectedByDefault(stUnits, isLicensePrint) {
  if (isLicensePrint) {
    // Not sure if this is important, but in internal ePort the stUnit for license
    // print was selected as the first one, sorted by descending fifoDate order
    stUnits = [...stUnits];
    stUnits.sort((a, b) => {
      if (!a.fifoDate) return 1; // a is null, move to end
      if (!b.fifoDate) return -1; // b is null, move to end
      return new Date(b.fifoDate) - new Date(a.fifoDate);
    });
  }

  return stUnits && stUnits.filter((s) => !disableStUnit(s)).length > 0
    ? stUnits.filter((s) => !disableStUnit(s))[0].stUnitId
    : null;
}

export default function PickOrderLineDialog({
  orderLine,
  stUnits,
  printJob,
  onPickOrderLine,
  onClose,
}) {
  if (
    orderLine.part.partType === partType.plasticCardPrintJob &&
    (orderLine.delQty > 0 || !printJob)
  ) {
    throw new Error("PickOrderLineDialog invalid props");
  }

  const [isLicensePrint] = useState(
    orderLine.part.partType === partType.plasticCardPrintJob
  );
  const [pickQty, setPickQty] = useState(null);
  const [selectedStUnitId, setSelectedStUnitId] = useState(
    getStUnitSelectedByDefault(stUnits, isLicensePrint)
  );
  const [selectedActorLicenseIds, setSelectedActorLicenseIds] = useState(
    printJob ? printJob.studentsPrintData.map((s) => s.actorLicenseId) : []
  );
  const [showPrintDoneQuestion, setShowPrintDoneQuestion] = useState(false);

  const printViewRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printViewRef.current,
    // onAfterPrint: () => markAsPrinted(api, selectedActorLicenseIds),
    // I set the width and height of the page explicitly, and then do the same for the license to be printed.
    // This is because I couldn't get the license to size correctly by simply setting width and height 100%.
    // But it seems like it should be possible to make it completely dynamic using onBeforePrint and useRef.
    pageStyle: () =>
      !isSafari
        ? `
      @page {
        margin: 0 !important;
        size: ${cr80CardWidth}px ${cr80cardHeight}px;
      }
    `
        : `
      @page {
        margin: 0 !important;
        size: ${cr80CardWidth}px ${cr80cardHeight}px;
      }
  
      @media print {
        html,
        body {
          transform: scale(0.54);
          translate: -160px -240px;
        }
      }
    `,
  });

  return (
    <>
      <Dialog
        open={!!orderLine}
        onClose={onClose}
        maxWidth={false}
        className={isLicensePrint ? "" : "mediumCourseDialog"}
        scroll="paper"
      >
        <DialogTitle>
          {`${Translate.get("Pick")}: ${orderLine.part.description}`}
        </DialogTitle>
        <DialogContent>
          {isLicensePrint && (
            <Stack direction="column" spacing={3} marginTop={2}>
              {printJob.studentsPrintData.map((s) => (
                <ActorLicensePreview
                  key={s.actorLicenseId}
                  printTemplate={printJob.printTemplate}
                  cardDetails={s}
                  isSelected={selectedActorLicenseIds.includes(
                    s.actorLicenseId
                  )}
                  onSelectedChanged={() =>
                    setSelectedActorLicenseIds((prev) =>
                      prev.includes(s.actorLicenseId)
                        ? prev.filter((id) => id !== s.actorLicenseId)
                        : [...prev, s.actorLicenseId]
                    )
                  }
                  showFront={true}
                  showBack={true}
                  printType="card"
                  showPrintSideTitleOverride={false}
                />
              ))}
            </Stack>
          )}

          {!isLicensePrint &&
            (stUnits && stUnits.length > 0 ? (
              <List>
                {stUnits.map((st) => (
                  <StUnitListItem
                    key={st.stUnitId}
                    {...st}
                    selected={st.stUnitId === selectedStUnitId}
                    onSelect={() => setSelectedStUnitId(st.stUnitId)}
                    disabled={disableStUnit(st)}
                  />
                ))}
              </List>
            ) : (
              <Stack
                width="100%"
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {Translate.get("NoStUnitAvailable")}
                </Typography>
              </Stack>
            ))}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            direction="column"
            spacing={1}
            marginTop="10px"
            marginLeft="0px"
            marginRight="16px"
            marginBottom="8px"
          >
            {isLicensePrint && (
              <Grid
                container
                item
                justifyContent="flex-end"
                alignItems="stretch"
                direction="row"
              >
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    disabled={
                      !selectedActorLicenseIds ||
                      selectedActorLicenseIds?.length === 0
                    }
                    onClick={handlePrint}
                  >
                    {`${Translate.get("Print")} (${
                      selectedActorLicenseIds.length
                    }/${printJob.studentsPrintData.length})`}
                  </Button>
                </Grid>
              </Grid>
            )}

            {!isLicensePrint && (
              <>
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  alignItems="stretch"
                  direction="row"
                  spacing={1}
                >
                  <Grid item xs={3}>
                    <ButtonWithSpinner
                      sx={{ height: "40px" }}
                      variant="outlined"
                      fullWidth
                      disabled={
                        pickQty > orderLine.qty - orderLine.delQty ||
                        !selectedStUnitId
                      }
                      onClick={async () =>
                        pickQty > 0
                          ? await onPickOrderLine(
                              orderLine.orderLineId,
                              pickQty,
                              selectedStUnitId
                            )
                          : null
                      }
                    >
                      {Translate.get("Pick")}:{" "}
                    </ButtonWithSpinner>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      value={pickQty ?? ""}
                      disabled={!stUnits || stUnits.length === 0}
                      onChange={(ev) => setPickQty(ev.target.value)}
                      placeholder={Translate.get("Quantity")}
                      fullWidth
                      size="small"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        type: "number",
                        min: 1,
                        max: orderLine.qty - orderLine.delQty,
                      }}
                      error={
                        pickQty > orderLine.qty - orderLine.delQty ||
                        (selectedStUnitId &&
                          stUnits.find((st) => st.stUnitId === selectedStUnitId)
                            .qty < pickQty)
                      }
                      helperText={
                        pickQty > orderLine.qty - orderLine.delQty
                          ? `${Translate.get("Max")} ${
                              orderLine.qty - orderLine.delQty
                            }`
                          : selectedStUnitId &&
                            stUnits.find(
                              (st) => st.stUnitId === selectedStUnitId
                            ).qty < pickQty
                          ? Translate.get("StUnitQtyNotEnough")
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignItems="flex-end"
                  direction="row"
                >
                  <Grid item>{/* Empty on purpose */}</Grid>
                  <Grid item container xs={6} justifyContent="center">
                    <Grid item>{Translate.get("Or").toLowerCase()}</Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="flex-end"
              direction="row"
            >
              <Grid item>
                <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
              </Grid>
              <Grid item xs={6}>
                {isLicensePrint && (
                  <ButtonWithSpinner
                    fullWidth
                    disabled={
                      !selectedStUnitId ||
                      stUnits.find((st) => st.stUnitId === selectedStUnitId)
                        .qty < pickQty
                    }
                    variant="contained"
                    onClick={() => setShowPrintDoneQuestion(true)}
                  >
                    {Translate.get("PrintDone")}
                  </ButtonWithSpinner>
                )}
                {!isLicensePrint && (
                  <ButtonWithSpinner
                    fullWidth
                    disabled={
                      !selectedStUnitId ||
                      stUnits.find((st) => st.stUnitId === selectedStUnitId)
                        .qty < pickQty
                    }
                    variant="contained"
                    onClick={async () =>
                      await onPickOrderLine(
                        orderLine.orderLineId,
                        orderLine.qty - orderLine.delQty,
                        selectedStUnitId
                      )
                    }
                  >{`${Translate.get("Pick")} ${Translate.get("All")} (${
                    orderLine.qty - orderLine.delQty
                  })`}</ButtonWithSpinner>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* ActorLicensePrintView is only visible when printing, via media query in ActorLicensePrint.css */}
      {isLicensePrint && (
        <ActorLicensePrintView
          ref={printViewRef}
          printTemplate={printJob.printTemplate}
          studentCardsDetails={printJob.studentsPrintData.filter((s) =>
            selectedActorLicenseIds.includes(s.actorLicenseId)
          )}
          printFront={true}
          printBack={true}
          printWidth={cr80CardWidth}
          printHeight={cr80cardHeight}
          printType="card"
        />
      )}
      <YesOrNoDialog
        open={showPrintDoneQuestion}
        text={Translate.get("SurePrintDone")}
        onYes={async () =>
          await onPickOrderLine(
            orderLine.orderLineId,
            orderLine.qty,
            selectedStUnitId
          )
        }
        onNo={() => setShowPrintDoneQuestion(false)}
      />
    </>
  );
}
