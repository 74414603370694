import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import Translate from "../../utils/Translate";
import { useSnackbar } from "notistack";
import { useApi } from "../../utils/Api";
import SelectActorPermitSkills from "./SelectActorPermitSkills";
import SelectActorPermitDetails from "./SelectActorPermitDetails";
import SelectActorPermitResponsibleActor from "./SelectActorPermitResponsibleActor";
import { validateResponsibleActor } from "./validation/ActorValidator";
import {
  validateActorPermitSkills,
  validateActorPermitValidFrom,
} from "./validation/ActorPermitValidator";
import ButtonWithSpinner from "../ButtonWithSpinner";

export default function CreateActorPermitDialog({
  actors,
  onBack,
  onPermitGroupSaved,
  hasStep1,
  cloneFromActorPermit
}) {
  const [responsibleActor, setResponsibleActor] = useState(cloneFromActorPermit ? cloneResponsibleActor(cloneFromActorPermit) : null);
  const [selectedPermitSkills, setSelectedPermitSkills] = useState(cloneFromActorPermit?.skills ?? []);
  const [otherEquipment, setOtherEquipment] = useState(cloneFromActorPermit?.otherEquipment);
  const [task, setTask] = useState(cloneFromActorPermit?.task);
  const [area, setArea] = useState(cloneFromActorPermit?.area);
  const [permitStartDate, setPermitStartDate] = useState(new Date());
  const [permitEndDate, setPermitEndDate] = useState(null);

  const [suggestions, setSuggestions] = useState([]);

  const [errors, setErrors] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const api = useApi();

  function validate() {
    const validationError1 = validateActorPermitSkills(selectedPermitSkills);
    const validationError2 = validateResponsibleActor(responsibleActor);
    const validationError3 = validateActorPermitValidFrom(permitStartDate);
    const validationError =
      validationError1 || validationError2 || validationError3;

    if (validationError) {
      const errorMessage = enqueueSnackbar(validationError, {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
      return false;
    }

    return true;
  }

  function cloneResponsibleActor(cloneFromActorPermit) {
    if (cloneFromActorPermit == null) {
      return null;
    }
    
    return ( {
      actorId: cloneFromActorPermit.responsibleActorId,
      actorName: cloneFromActorPermit.responsibleActorName,
      email: cloneFromActorPermit.responsibleActorEmail,
      orgNo: cloneFromActorPermit.responsibleActorOrgNo,
      phoneNumber: cloneFromActorPermit.responsibleActorPhoneNumber
    });
  }

  async function handleClickSave() {
    // Validation is already done when the values are selected and set.
    // But might as well do one last check before saving everything.
    if (!validate()) {
      return;
    }

    const dto = {
      actorPermitGroupId: 0,
      responsibleActorId: responsibleActor?.actorId,
      skillGroupId: selectedPermitSkills && selectedPermitSkills.length && selectedPermitSkills[0].skillGroupId || null,
      actorPermits: actors.map((a) => ({
        actorPermitId: 0,
        actorId: a.actorId,
        responsibleActorId: responsibleActor?.actorId,
        area: area,
        otherEquipment: otherEquipment,
        task: task,
        skills: selectedPermitSkills ?? [],
        validFrom: permitStartDate,
        validTo: permitEndDate,
      })),
    };

    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}permits/groups`,
      dto,
      "POST"
    );

    if (response && response.isSuccessful) {
      onPermitGroupSaved(response.actorPermitGroup.actorPermitGroupId);
    }
  }

  // We need to skip validation on initial selection of the default skills for the actors
  function handleSelectPermitSkills(skills, skipValidation = false) {
    const validationError = !skipValidation
      ? validateActorPermitSkills(skills)
      : null;
    setErrors({ ...errors, selectedPermitSkills: validationError });
    const sortedSkills = skills.sort((lhs, rhs) =>
      `${lhs.shortCode} ${lhs.description}`.localeCompare(
        `${rhs.shortCode} ${rhs.description}`
      )
    );
    setSelectedPermitSkills(sortedSkills);
  }

  function handleChangeResponsibleActor(actor) {
    if (actor) {
      const validationError = validateResponsibleActor(actor);
      setErrors({ ...errors, responsibleActor: validationError });
    }
    setResponsibleActor(actor);
  }

  function handleValidFromDateChange(date) {
    const validationError = validateActorPermitValidFrom(date);
    setErrors({ ...errors, permitStartDate: validationError });
    setPermitStartDate(date);
  }

  return (
    <>
      <DialogContent className="stdDialogContent">
        <Stack spacing={3}>
          <Stack direction="row">
            <Box
              className="textbox smallHeader blue"
              sx={{ padding: "4px 8px" }}
            >{`${Translate.get("Step")} 2 ${Translate.get("Of")} 2`}</Box>
          </Stack>
          <h2>{Translate.get("FillOutInfo")}</h2>
          <SelectActorPermitSkills
            actors={actors}
            selectedPermitSkills={selectedPermitSkills}
            onSelectedPermitSkillsChanged={handleSelectPermitSkills}
            errorMessage={errors.selectedPermitSkills}
            isCloneFromActorPermit={cloneFromActorPermit}
            setSuggestions={setSuggestions}
          />
          <Box>
            <SelectActorPermitDetails
              otherEquipment={otherEquipment}
              onOtherEquipmentChange={(value) => setOtherEquipment(value ?? "")}
              task={task}
              onTaskChange={(value) => setTask(value ?? "")}
              area={area}
              onAreaChange={(value) => setArea(value ?? "")}
              permitStartDate={permitStartDate}
              onPermitStartDateChange={handleValidFromDateChange}
              permitEndDate={permitEndDate}
              onPermitEndDateChange={setPermitEndDate}
              startDateErrorMessage={errors.permitStartDate}
              suggestedValues={suggestions}
            />
          </Box>
          <Box>
            <SelectActorPermitResponsibleActor
              responsibleActor={responsibleActor}
              onResponsibleActorChange={handleChangeResponsibleActor}
              errorMessage={errors.responsibleActor}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack}>
          {hasStep1 ? Translate.get("Back") : Translate.get("Cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={handleClickSave}
          disabled={
            Object.values(errors).some((v) => !!v) ||
            !responsibleActor ||
            !selectedPermitSkills ||
            selectedPermitSkills.length <= 0 ||
            !permitStartDate ||
            !task
          }
        >
          {Translate.get("Save")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
