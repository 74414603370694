import { useState, useEffect } from 'react';
import { useApi } from '../../utils/Api';
import Translate from '../../utils/Translate';
import Box from '@mui/material/Box';
import * as React from 'react';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';

const GdprText = () => {
    const [open, setOpen] = useState(true);
    const [gdprText, setGdprText] = useState("");
    const [oldGdprText, setOldGdprText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const api = useApi();

    useEffect(async () => {
        await getGdprText();
    }, []);


    async function getGdprText() {
        setIsLoading(true);
        let response = null;
        try {
            response = await api.fetch(
                `${process.env.REACT_APP_MAIN_URL}gdpr/text`,
                false,
                'GET',
            );

            if (response.isSuccessful) {
                setOldGdprText(response.text);
                setGdprText(response.text);
            }

        } finally {
            setIsLoading(false);
        }
    }

    async function postGdprText() {
        setIsLoading(true);
        
        let response = null;
        try {
            let reqObj = {
                text: gdprText
            }

            response = await api.fetch(
                `${process.env.REACT_APP_MAIN_URL}gdpr/text`,
                reqObj,
                'POST',
            );

            if (response.isSuccessful) {
                setOldGdprText(gdprText);
            }

        } finally {
            setIsLoading(false);
        }
    }


    function handleGdprTextChange(event) {
        setGdprText(event.target.value);
    }

    return (
        <>
            <Box sx={{ minWidth: 120 }}>
                <TextField
                    label={Translate.get("GDPRText2")}
                    multiline
                    rows={6}
                    variant="outlined"
                    value={gdprText || ""}
                    onChange={handleGdprTextChange}
                    fullWidth
                    inputProps={{ maxLength: 1048 }}
                ></TextField>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "1em" }}>
                    <Button
                        variant="outlined"
                        disabled={oldGdprText === gdprText}
                        onClick={postGdprText}
                    >
                        {Translate.get("Save")}
                    </Button>
                </div>
            </Box>
        </>
    );
};
export default GdprText;
