import { useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import { useNavigate, useParams } from "react-router-dom";
import Translate from "../../utils/Translate";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  CardMedia,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
  Paper,
} from "@mui/material";
import { paymentProviders } from "../../utils/paymentProvider";
import ActorLicensePreview from "../../components/actor-license-print/ActorLicensePreview";
import Preloader from "../../components/Preloader";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentDialog from "../../components/checkout/PaymentDialog";

export default function CheckoutPage(props) {
  // TODO Just nu är det bara digitala intyg som använder denna, därav id: digitalLicenseId.
  // Men senare skulle man kunna ändra så att man t.ex. har en shopping cart i localStorage,
  // och inte ens behöva något id. Alternativt om beställningen måste vara persistent så
  // kanske vi lagrar den i DB via backend, och isf. skulle detta id kunna vara något annat
  const { id: digitalLicenseId } = useParams();
  const api = useApi();
  const [isLoadingCount, setIsLoadingCount] = useState(0);
  const [license, setLicense] = useState(null);
  const [preliminaryOrderLine, setPreliminaryOrderLine] = useState(null);
  const [orderDetails, setOrderDetails] = useState({
    name: null,
    email: null,
    phoneNumber: null,
    digitalLicenseId: digitalLicenseId,
    tempAddress: {
      street1: null,
      street2: null,
      cityCode: null,
      city: null,
    },
  });
  const [paymentProviderId, setPaymentProviderId] = useState(
    paymentProviders.swish
  );
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [initialHash] = useState(window.location.hash);

  useEffect(() => {
    async function loadLicense() {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}digital/licenses/${digitalLicenseId}/print-preview`,
        false,
        "GET"
      );
      setLicense(response);
      setIsLoadingCount((prev) => prev - 1);
    }

    async function loadPrice() {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}digital/licenses/${digitalLicenseId}/price`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setPreliminaryOrderLine(response);
      }
      setIsLoadingCount((prev) => prev - 1);
    }

    setIsLoadingCount(2);
    loadLicense();
    loadPrice();
  }, [api, digitalLicenseId]);

  useEffect(() => {
    const onHashChange = () =>
      setShowPaymentDialog(window.location.hash === `${initialHash}#payment`);
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, [initialHash]);

  async function createOrder() {
    setIsLoadingCount((prev) => prev + 1);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}digital/licenses/order`,
      { ...orderDetails, paymentProviderId },
      "POST"
    );
    setIsLoadingCount((prev) => prev - 1);
    if (response.isSuccessful) {
      setOrderDetails((prev) => ({
        ...prev,
        orderId: response.orderId,
        paymentId: response.paymentId,
      }));
      window.history.pushState(false, "", `${initialHash}#payment`);
      setShowPaymentDialog(true);
    }
  }

  async function restartPayment() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/${orderDetails.orderId}/payment/${orderDetails.paymentId}/restart`,
      false,
      "GET"
    );
    if (response.isSuccessful) {
      setOrderDetails((prev) => ({
        ...prev,
        paymentId: response.newPaymentId,
      }));
    }
  }

  return (
    <>
      {isLoadingCount > 0 && <Preloader />}
      {showPaymentDialog && (
        <PaymentDialog
          orderId={orderDetails.orderId}
          paymentId={orderDetails.paymentId}
          onClose={() => window.history.back()}
          onRestartPayment={restartPayment}
        />
      )}
      <Container maxWidth="md" className="checkoutContainer">
        <Grid container direction="column" spacing={5}>
          {/* Card Preview */}
          {!!license && (
            <Grid item xs={12}>
              <Stack alignItems="center">
                <ActorLicensePreview
                  printTemplate={license.printTemplate}
                  cardDetails={license.studentsPrintData[0]}
                  isSelected={true}
                  onSelectedChanged={() => {}}
                  showFront={true}
                  showBack={true}
                  printType={"card"}
                  showPrintSideTitleOverride={false}
                />
              </Stack>
            </Grid>
          )}

          {/* Price Information */}
          {preliminaryOrderLine && (
            <Grid item xs={12}>
              <Stack
                direction="column"
                spacing={1}
                borderRadius={1}
                mt={1}
                padding="12px"
                bgcolor="grey.300"
              >
                <Stack direction="row" justifyContent="space-between">
                  <Box>{Translate.get("Price")}</Box>
                  <Box>{`${preliminaryOrderLine.price.toFixed(2)} kr`}</Box>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Box>{`${Translate.get("Moms")} (${
                    preliminaryOrderLine.vatPercent * 100
                  }%)`}</Box>
                  <Box>{`${preliminaryOrderLine.vatPrice.toFixed(2)} kr`}</Box>
                </Stack>
                {preliminaryOrderLine.centRounded && (
                  <Stack direction="row" justifyContent="space-between">
                    <Box>{Translate.get("RoundUpValue")}</Box>
                    <Box>{`${preliminaryOrderLine.centRounded.toFixed(
                      2
                    )} kr`}</Box>
                  </Stack>
                )}
                <Stack
                  borderTop="1px solid rgba(0, 0, 0, 0.12)"
                  paddingTop={1}
                  marginTop={1}
                  direction="row"
                  justifyContent="space-between"
                  fontWeight={600}
                >
                  <Box>{Translate.get("ToPay").toUpperCase()}</Box>
                  <Box>{`${preliminaryOrderLine.totalPrice.toFixed(
                    2
                  )} kr`}</Box>
                </Stack>
              </Stack>
            </Grid>
          )}

          {/* User Details Form */}
          <Grid item>
            <div className="checkoutSectionHeader">
              <h2>{Translate.get("CheckoutYourDetailsLable")}</h2>
            </div>
            <Card className="checkoutSectionCard">
              <TextField
                fullWidth
                margin="normal"
                label={Translate.get("Name")}
                variant="outlined"
                value={orderDetails.name ?? ""}
                onChange={(event) =>
                  setOrderDetails((prev) => ({
                    ...prev,
                    name: event.target.value,
                    tempAddress: {
                      ...prev.tempAddress,
                      street1: event.target.value,
                    },
                  }))
                }
                required
              />
              <TextField
                fullWidth
                margin="normal"
                label={Translate.get("Email")}
                variant="outlined"
                required
                type="email"
                value={orderDetails.email ?? ""}
                onChange={(event) =>
                  setOrderDetails((prev) => ({
                    ...prev,
                    email: event.target.value,
                  }))
                }
              />
              <TextField
                fullWidth
                margin="normal"
                label={Translate.get("Phone")}
                variant="outlined"
                required
                value={orderDetails.phoneNumber ?? ""}
                onChange={(event) =>
                  setOrderDetails((prev) => ({
                    ...prev,
                    phoneNumber: event.target.value,
                  }))
                }
              />

              {/* Address fields */}
              <TextField
                fullWidth
                margin="normal"
                label={Translate.get("Address")}
                variant="outlined"
                required
                value={orderDetails.tempAddress.street2 ?? ""}
                onChange={(event) =>
                  setOrderDetails((prev) => ({
                    ...prev,
                    tempAddress: {
                      ...prev.tempAddress,
                      street2: event.target.value,
                    },
                  }))
                }
              />

              <Grid container rowSpacing={0.3} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label={Translate.get("ZipCode")}
                    variant="outlined"
                    required
                    value={orderDetails.tempAddress.cityCode ?? ""}
                    onChange={(event) =>
                      setOrderDetails((prev) => ({
                        ...prev,
                        tempAddress: {
                          ...prev.tempAddress,
                          cityCode: event.target.value,
                        },
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label={Translate.get("City")}
                    variant="outlined"
                    required
                    value={orderDetails.tempAddress.city ?? ""}
                    onChange={(event) =>
                      setOrderDetails((prev) => ({
                        ...prev,
                        tempAddress: {
                          ...prev.tempAddress,
                          city: event.target.value,
                        },
                      }))
                    }
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* Payment Options */}
          <Grid item>
            <div className="checkoutSectionHeader">
              <h2>{Translate.get("PaymentLabel")}</h2>
            </div>
            <Card className="checkoutSectionCard">
              <FormControl component="fieldset" fullWidth>
                <RadioGroup defaultValue={paymentProviders.swish}>
                  <FormControlLabel
                    value={paymentProviders.swish}
                    control={<Radio />}
                    label={
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <img
                          src="https://eportpublic.blob.core.windows.net/others/swish/swish_logo_secondary_light.svg?sp=r&st=2024-07-22T16:41:04Z&se=2059-07-23T00:41:04Z&spr=https&sv=2022-11-02&sr=b&sig=j9dhNuNrcng%2Fij3UJzNYtRtYfnVg2USwhtB6mH142yw%3D"
                          alt="Credit Card Logo"
                          style={{
                            height: "32px",
                            marginLeft: "8px",
                          }}
                        />
                      </Box>
                    }
                    sx={{ width: "100%", m: 0 }}
                  />
                </RadioGroup>
              </FormControl>
            </Card>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                marginTop: "20px",
                minWidth: "200px",
                width: { xs: "100%", sm: "initial" },
              }}
              disabled={
                !orderDetails ||
                !orderDetails.tempAddress ||
                Object.values(orderDetails).some((v) => !v) ||
                Object.values(orderDetails.tempAddress).some((v) => !v)
              }
              onClick={createOrder}
            >
              {Translate.get("PlaceOrder")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
