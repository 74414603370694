import { Avatar, Stack, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import { zipWith } from "lodash";
import { stringToColor } from "./formatString";
import SingleSelectInterval from "./SingleSelectInterval";
import Translate from "./Translate";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
  isValidDate,
} from "./dateTimeFormat";
import DateInterval from "./DateInterval";

export function IconAndLabelColumnHeader(props) {
  return (
    <div className="stdFlexLeft">
      <props.icon />
      <div>{props.label}</div>
    </div>
  );
}

export function TableAvatar({
  src = "",
  variant = "circular",
  title = "",
  subtitle = "",
  href = false,
  hideImage,
  boldTitle = true,
}) {
  if (!Array.isArray(subtitle)) {
    subtitle = [subtitle];
  } else {
    //Om vi kanske vill topställa text (tableAvatararTop finns inte i nuläget)
    //className='tableAvatar tableAvatararTop';
  }
  /*  const attr = {
    className: 'tableAvatar',
  };
  if (href) {
    //attr.to = href;
  } */

  function avatarSx(src, title) {
    if ((src === "null" || src === null) && title?.length) {
      // blir ibland strängen "null"
      return { bgcolor: stringToColor(title) };
    }
    return null;
  }

  return href ? (
    <div className="tableAvatar">
      {!hideImage && (
        <Link to={href}>
          <Avatar
            src={src}
            variant={variant}
            alt={title}
            sx={avatarSx(src, title)}
          >
            {/* visa intial om det inte finns någon url */}
            {(src && null) || (title.length && title[0]) || null}
          </Avatar>
        </Link>
      )}
      <div>
        <Link
          to={href}
          className={"tableAvatarTitle" + (boldTitle ? " bold" : "")}
        >
          {title}
        </Link>
        {subtitle.map((row, index) => (
          <div className="tableAvatarSubs" key={index}>
            {row}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="tableAvatar">
      {!hideImage && (
        <Avatar
          src={src}
          variant={variant}
          alt={title}
          sx={avatarSx(src, title)}
        >
          {/* visa intial om det inte finns någon url */}
          {(src && null) || (title.length && title[0]) || null}
        </Avatar>
      )}
      <div>
        <div className={"tableAvatarTitle" + (boldTitle ? " bold" : "")}>
          {title}
        </div>
        {subtitle.map((row, index) => (
          <div className="tableAvatarSubs" key={index}>
            {row}
          </div>
        ))}
      </div>
    </div>
  );
}

export function getGridColDefForSingleSelect(
  filterValueOptions,
  addBetweenOperator
) {
  // Just modify the normal filter operators for singleSelect
  const operators = getGridSingleSelectOperators()
    .filter((op) => op.value === "is")
    .map((op) => ({
      ...op,
      label: Translate.get("Is"),
      getApplyFilterFn: (filterItem, column) => {
        if (
          !filterItem.field ||
          !filterItem.value || // Note this means filters for value 0 will be skipped
          !filterItem.operator
        ) {
          return null;
        }

        return (rowParams) => {
          return rowParams.value && rowParams.value === filterItem.value;
        };
      },
    }));

  if (addBetweenOperator) {
    operators.push({
      label: Translate.get("Between"),
      value: "between",
      getApplyFilterFn: (filterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }
        return (value) => {
          return (
            value != null &&
            filterItem.value[0] <= value &&
            value <= filterItem.value[1]
          );
        };
      },
      InputComponent: SingleSelectInterval,
      InputComponentProps: { valueOptions: filterValueOptions },
    });
  }

  return {
    type: "singleSelect",
    valueOptions: filterValueOptions,
    filterOperators: operators,
  };
}

export function getGridColDefForArrayValues(
  filterValueOptions,
  compareValues = null
) {
  // Just modify the normal filter operators for singleSelect
  const isInArrayValueFilterOpreator = getGridSingleSelectOperators()
    .filter((op) => op.value === "is")
    .map((op) => ({
      ...op,
      getApplyFilterFn: (filterItem, column) => {
        if (
          !filterItem.field ||
          !filterItem.value || // Note this means filters for value 0 will be skipped
          !filterItem.operator
        ) {
          return null;
        }

        return (rowParams) => {
          return rowParams.value && rowParams.value.includes(filterItem.value);
        };
      },
      // Denna verkar inte funka. Borde göra så att filter-tooltip säger ex: "Roll är Kontaktperson" istället för "Roll är 3"
      // getValueAsString: (value) => {
      //   return Translate.getActorType(value);
      // },
    }));

  const defaultCompareValues = (value1, value2) => {
    value1 = value1 ?? 0;
    value2 = value2 ?? 0;

    if (value1 > value2) {
      return 1;
    }

    if (value1 < value2) {
      return -1;
    }

    return 0;
  };

  // Will sort based on comparison of the first element, then by the second etc.
  const arrayComparator = (array1, array2) => {
    const compare = compareValues ?? defaultCompareValues;
    const zippedArrays = zipWith(array1, array2, compare);
    const result =
      zippedArrays.length > 0
        ? zippedArrays
            .map(
              (value, index) =>
                Math.pow(10, zippedArrays.length - 1 - index) * value
            )
            .reduce((acc, val) => acc + val)
        : 0;
    return result;
  };

  return {
    type: "singleSelect",
    valueOptions: filterValueOptions,
    filterOperators: isInArrayValueFilterOpreator,
    sortComparator: arrayComparator,
  };
}

export function getGridColDefForDateRange() {
  const operators = [
    {
      label: Translate.get("DateRange"),
      value: "between",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.value || !filterItem.value.length) {
          return null;
        }

        const [start, end] = filterItem.value;
        return (params) => {
          if (!params.value) {
            return false;
          }

          const date = new Date(params.value);
          return date >= start && date <= end;
        };
      },
      InputComponent: DateInterval,
    },
  ];

  return {
    type: "date",
    filterOperators: operators,
  };
}
