import Button from "@mui/material/Button";
import {
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Menu,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { DataGridPro as DataGrid, GridToolbar } from "@mui/x-data-grid-pro";
import { useState } from "react";
import Translate from "../../utils/Translate";
import AutocompleteSearchField from "../AutocompleteSearchField";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import { LoadingButton } from "@mui/lab";
import { isFreightPartType } from "../../utils/part";
import DotMenu from "../DotMenu";
import {
  AccountBalance,
  Add,
  AttachMoney,
  Clear,
  Computer,
  Delete,
  Download,
  Edit,
  Email,
  Error,
  MarkEmailRead,
  Money,
  MoreHoriz,
  Numbers,
  Percent,
  Print,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { dataGridLang } from "../MemToolBar";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import { inOrderStatus } from "../../utils/inOrder";
import { formatMoney } from "../../utils/formatMoney";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
  isValidDate,
  toSameDateTimeUTC,
} from "../../utils/dateTimeFormat";
import MenuToggleButton from "../MenuToggleButton";
import { getIcon, priority } from "../../utils/priority";
import { documentType, mediaType } from "../../utils/sentCommunication";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function InOrderFormContent({
  inOrder,
  inOrderLines,
  company,
  contacts,
  supplierCompany,
  supplierContacts,
  addresses,
  plannedDelDate,
  handlePlannedDelDateChanged,
  handleDeliveryAddressSelected,
  companySearch,
  handleSupplierCompanySelected,
  handleContactSelected,
  handleSupplierContactSelected,
  handleAddInOrderLine,
  handleEditInOrderLine,
  handleRemoveInOrderLine,
  handleSaveOrSendOrder,
  handleClose,
  hasOrderChanged,
  downloadInOrderPdf,
}) {
  // console.log("supplierCompany", supplierCompany, supplierContacts)
  const localizedTextsMap = dataGridLang();
  const theme = useTheme();
  const isLargeUp = useMediaQuery(theme.breakpoints.up("lg"));

  function getDotMenuActions(inOrderLine) {
    return [
      {
        icon: <Edit />,
        text: Translate.get("EditLabel"),
        disabled: inOrderLine.status >= inOrderStatus.startedUnpacking,
        onClick: () => handleEditInOrderLine(inOrderLine.inOrderLineId),
      },
      {
        icon: <Delete />,
        text: Translate.get("Delete"),
        disabled: inOrderLine.status >= inOrderStatus.startedUnpacking,
        onClick: () => handleRemoveInOrderLine(inOrderLine.inOrderLineId),
      },
    ];
  }

  const columns = [
    {
      field: "partNo",
      flex: 0.3,
      headerName: Translate.get("PartNoLabel"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={Numbers}
          label={Translate.get("PartNoLabel")}
        />
      ),
      valueGetter: (params) => params.row.part?.partNo ?? "",
    },
    {
      field: "partId",
      flex: 1,
      headerName: Translate.get("Product"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={ShoppingBasketIcon}
          label={Translate.get("Product")}
        />
      ),
      valueGetter: (params) => params.row.part?.description ?? "",
    },
    {
      field: "qty",
      flex: 0.3,
      headerName: Translate.get("Quantity"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={ScatterPlotIcon}
          label={Translate.get("Quantity")}
        />
      ),
    },
    {
      field: "pcsPrice",
      flex: 0.4,
      headerName: Translate.get("PricePerUnit"),
      renderHeader: () => (
        <IconAndLabelColumnHeader
          icon={AttachMoney}
          label={Translate.get("PricePerUnit")}
        />
      ),
      valueGetter: (params) =>
        params.row.pcsPrice ? params.row.pcsPrice.toFixed(2) : "",
    },
    {
      field: "dotMenu",
      flex: 0.2,
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      headerClassName: "hideSeparator",
      align: "right",
      hide: !getDotMenuActions,
      hideable: false,
      renderCell: (params) =>
        getDotMenuActions && (
          <DotMenu content={getDotMenuActions(params.row)} horizontal />
        ),
    },
  ];

  function isEmptyTempAddress() {
    return (
      !inOrder.tempAddress ||
      (!inOrder.tempAddress.street1 &&
        !inOrder.tempAddress.street2 &&
        !inOrder.tempAddress.cityCode &&
        !inOrder.tempAddress.city)
    );
  }

  return (
    <>
      <DialogTitle>
        {inOrder.inOrderId > 0
          ? Translate.get("InOrderLabel")
          : Translate.get("CreateInOrder")}
      </DialogTitle>
      <DialogContent sx={{ width: { lg: "1160px", xl: "1460px" } }}>
        <Grid container spacing={4}>
          <Grid item container rowSpacing={2} columnSpacing={1}>
            <Grid
              item
              container
              columnSpacing={1}
              rowSpacing={2}
              xs={12}
              marginTop={1}
            >
              <Grid item xs={12} md={6}>
                <AutocompleteSearchField
                  disabled={!inOrder.canUpdate}
                  label={Translate.getActorType(2)}
                  value={supplierCompany ?? ""}
                  onValueChange={async (selectedCompany) =>
                    await handleSupplierCompanySelected(selectedCompany)
                  }
                  getOptionLabel={(option) => option?.actorName ?? ""}
                  renderOption={(option) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <ActorAutocompleteOption
                        actorName={option?.actorName}
                        externalActorNo={
                          option?.actorId.toString() +
                          (option?.externalActorNo
                            ? ` (${option?.externalActorNo})`
                            : "")
                        }
                        addressStreet={option?.addressStreet}
                        addressZipCode={option?.addressZipCode}
                        addressCity={option?.addressCity}
                      />
                      {(option.externalDataId || option.observationNote) && (
                        <Stack
                          direction="column"
                          justifyContent={
                            option.externalDataId && option.observationNote
                              ? "space-between"
                              : option.externalDataId
                              ? "flex-start"
                              : "flex-end"
                          }
                        >
                          {option.externalDataId && (
                            <Box display="flex" width="80px" gap={1}>
                              <Computer />
                              <Box>{option.externalDataId ?? ""}</Box>
                            </Box>
                          )}
                          {option.observationNote && (
                            <Tooltip title={option.observationNote} arrow>
                              <Error sx={{ color: "red" }} />
                            </Tooltip>
                          )}
                        </Stack>
                      )}
                    </Box>
                  )}
                  keyPropName="actorId"
                  requireSelection={true}
                  search={companySearch}
                  createNewValue={(text) => ({
                    actorName: text,
                  })}
                  textFieldProps={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="supplier-ref-select-label">
                    {Translate.get("SupplierRef")}
                  </InputLabel>
                  <Select
                    disabled={!inOrder.canUpdate}
                    labelId="supplier-ref-select-label"
                    id="supplier-ref-select"
                    value={inOrder?.yourRefId ?? ""}
                    label={Translate.get("SupplierRef")}
                    onChange={handleSupplierContactSelected}
                  >
                    {supplierContacts &&
                      supplierContacts.map((contact) => (
                        <MenuItem key={contact.actorId} value={contact.actorId}>
                          {contact.actorName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <AutocompleteSearchField
                  disabled={true}
                  label={Translate.get("Receiver")}
                  value={company ?? ""}
                  getOptionLabel={(option) => option?.actorName ?? ""}
                  renderOption={(option) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <ActorAutocompleteOption
                        actorName={option?.actorName}
                        externalActorNo={
                          option?.actorId.toString() +
                          (option?.externalActorNo
                            ? ` (${option?.externalActorNo})`
                            : "")
                        }
                        addressStreet={option?.addressStreet}
                        addressZipCode={option?.addressZipCode}
                        addressCity={option?.addressCity}
                      />
                      {(option.externalDataId || option.observationNote) && (
                        <Stack
                          direction="column"
                          justifyContent={
                            option.externalDataId && option.observationNote
                              ? "space-between"
                              : option.externalDataId
                              ? "flex-start"
                              : "flex-end"
                          }
                        >
                          {option.externalDataId && (
                            <Box display="flex" width="80px" gap={1}>
                              <Computer />
                              <Box>{option.externalDataId ?? ""}</Box>
                            </Box>
                          )}
                          {option.observationNote && (
                            <Tooltip title={option.observationNote} arrow>
                              <Error sx={{ color: "red" }} />
                            </Tooltip>
                          )}
                        </Stack>
                      )}
                    </Box>
                  )}
                  keyPropName="actorId"
                  requireSelection={true}
                  search={companySearch}
                  createNewValue={(text) => ({
                    actorName: text,
                  })}
                  textFieldProps={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="contact-select-label">
                    {Translate.get("ReceiverRef2")}
                  </InputLabel>
                  <Select
                    disabled={!inOrder.canUpdate}
                    labelId="contact-select-label"
                    id="contact-select"
                    value={inOrder?.ourRefId ?? ""}
                    label={Translate.get("ReceiverRef2")}
                    onChange={handleContactSelected}
                  >
                    {contacts &&
                      contacts.map((contact) => (
                        <MenuItem key={contact.actorId} value={contact.actorId}>
                          {contact.actorName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container columnSpacing={1} rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="del-address-select-label">
                    {Translate.getActorAddressType(4)}
                  </InputLabel>
                  <Select
                    sx={{ height: "130px" }}
                    multiline
                    rows={4}
                    labelId="del-address-select-label"
                    id="del-address-select"
                    value={inOrder.deliveryAddressId ?? ""}
                    label={Translate.getActorAddressType(4)}
                    onChange={(event) =>
                      handleDeliveryAddressSelected(event.target.value)
                    }
                    disabled={!inOrder.canUpdate}
                  >
                    {addresses &&
                      addresses.map((address) => (
                        <MenuItem
                          divider
                          key={address.actorAddressId}
                          value={address.actorAddressId}
                        >
                          <Grid container whiteSpace="pre">
                            <Grid item xs={10}>
                              {address.actorAddressStreet1?.trim()
                                ? address.actorAddressStreet1
                                : " "}
                            </Grid>
                            <Grid item xs={2}>
                              <span
                                style={{
                                  backgroundColor: "#EDEFF1",
                                  padding: "3px",
                                  borderRadius: "3px",
                                  fontSize: "smaller",
                                }}
                              >
                                {Translate.getActorAddressType(
                                  address.actorAddressType
                                )}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              {address.actorAddressStreet2?.trim()
                                ? address.actorAddressStreet2
                                : " "}
                            </Grid>
                            <Grid item xs={12}>
                              {`${
                                address.actorAddressZipCode?.trim()
                                  ? address.actorAddressZipCode
                                  : " "
                              } ${
                                address.actorAddressCity?.trim()
                                  ? address.actorAddressCity
                                  : " "
                              }`}
                            </Grid>
                            <Grid item xs={12}>
                              {address.actorAddressCountry?.trim()
                                ? address.actorAddressCountry
                                : " "}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    <MenuItem value={-1}>
                      <Grid container whiteSpace="pre">
                        <Grid item container xs={9}>
                          {isEmptyTempAddress() ? (
                            <Grid item xs={12}>
                              <Box
                                height="96px"
                                display="flex"
                                alignItems="center"
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "text.secondary",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {Translate.getActorAddressType(5)}
                                </Typography>
                              </Box>
                            </Grid>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                {inOrder.tempAddress?.street1?.trim()
                                  ? inOrder.tempAddress?.street1
                                  : " "}
                              </Grid>
                              <Grid item xs={12}>
                                {inOrder.tempAddress?.street2?.trim()
                                  ? inOrder.tempAddress?.street2
                                  : " "}
                              </Grid>
                              <Grid item xs={12}>
                                {`${
                                  inOrder.tempAddress?.cityCode?.trim()
                                    ? inOrder.tempAddress?.cityCode
                                    : " "
                                } ${
                                  inOrder.tempAddress?.city?.trim()
                                    ? inOrder.tempAddress?.city
                                    : " "
                                }`}
                              </Grid>
                              <Grid item xs={12}>
                                {inOrder.tempAddress?.country?.trim()
                                  ? inOrder.tempAddress.country
                                  : " "}
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid item container xs={3}>
                          <Grid item xs={12}>
                            <span
                              style={{
                                backgroundColor: "#EDEFF1",
                                padding: "3px",
                                borderRadius: "3px",
                                fontSize: "smaller",
                              }}
                            >
                              {Translate.getActorAddressType(5)}
                            </span>
                          </Grid>
                          {(!isEmptyTempAddress() ||
                            inOrder.deliveryAddressId === -1) && (
                            <Grid
                              item
                              xs={12}
                              justifyContent="center"
                              className="showWhenMenuOpen"
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                startIcon={<Edit />}
                                onClick={() =>
                                  handleDeliveryAddressSelected(-1)
                                }
                              >
                                {Translate.get("EditLabel")}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <DatePicker
                disabled={!inOrder.canUpdate}
                mask={getCurrentDateMask()}
                inputFormat={getCurrentInputFormat()}
                value={plannedDelDate ?? null}
                onChange={(newDate) => {
                  if (isValidDate(newDate)) {
                    handlePlannedDelDateChanged(toSameDateTimeUTC(newDate));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Translate.get("PlannedDelDate")}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: Translate.get("PlannedDelDate"),
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <DataGrid
                className={
                  "dgDynIconText dgSmall" +
                  (inOrderLines.length === 0 ? " hideOverlayWrapper" : "")
                }
                rows={inOrderLines.filter(
                  (ol) =>
                    ol.status === undefined ||
                    ol.status === null ||
                    ol.status > inOrderStatus.canceled
                )}
                columns={columns}
                disableSelectionOnClick
                getRowId={(row) => `${row.partId}_${row.inOrderLineId}`}
                autoHeight={true}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: () => <div></div>,
                }}
                componentsProps={{
                  columnsPanel: { className: "customColumnPanel" },
                  filterPanel: { className: "customfilterPanel" },
                  panel: { className: "customPanel" },
                }}
                localeText={localizedTextsMap}
                disableColumnMenu
                disableDensitySelector={true}
                density="comfortable"
                hideFooter={true}
              />
            </Grid>
            <Grid item xs={12} marginLeft={2}>
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={!inOrder.canUpdate || !inOrder.actorId}
                  onClick={handleAddInOrderLine}
                  startIcon={<Add />}
                >
                  {Translate.get("PartLabel")}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonWithSpinner onClick={downloadInOrderPdf}>PDF</ButtonWithSpinner>
        <Button onClick={handleClose}>{Translate.get("Cancel")}</Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={handleSaveOrSendOrder}
          disabled={
            !company ||
            !supplierCompany ||
            !inOrder.canUpdate ||
            !hasOrderChanged ||
            !inOrderLines ||
            inOrderLines.length === 0
          }
        >
          {inOrder.inOrderId
            ? Translate.get("Save")
            : Translate.get("CreateInOrder")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
