import {
  DataGridPremium as DataGrid,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useState, useMemo, useCallback, useEffect } from "react";
import DotMenu from "../DotMenu";
import {
  getGridColDefForSingleSelect,
  IconAndLabelColumnHeader,
} from "../../utils/dataGrid";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { dataGridLang } from "../MemToolBar";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Translate from "../../utils/Translate";
import { useApi } from "../../utils/Api";
import {
  Box,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatDate } from "../../utils/dateTimeFormat";
import { useNavigate, useSearchParams } from "react-router-dom";
import usePagination from "../../utils/pagination";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import DataGridEasyFilters from "../DataGridEasyFilters";
import LinkMenu from "../LinkMenu";
import ClearIcon from "@mui/icons-material/Clear";
import { orderStatus } from "../../utils/order";
import {
  CheckBox,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Numbers,
} from "@mui/icons-material";
import { groupBy } from "lodash";
import { AlertDialog } from "../AlertDialog";
import { formatMoney } from "../../utils/formatMoney";
import {
  isAutoPickPartType,
  isCoursePartType,
  isFreightPartType,
} from "../../utils/part";
import PartDetailsPanel from "./PartDetailsPanel";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CheckIcon from "@mui/icons-material/Check";
import InOrderFormDialog from "./InOrderFormDialog";

const getStatusFilterOptions = () =>
  Object.values(orderStatus).map((status) => ({
    value: status,
    label: Translate.getOrderStatus(status),
  }));

function buildPaginationFilter(filter) {
  if (!filter) {
    return null;
  }

  switch (filter) {
    case "needInOrder":
      return {
        items: [
          {
            id: 1,
            field: "needInOrder",
            operator: "is",
            value: true,
          },
        ],
        logicOperator: "and",
      };
    default:
      return null;
  }
}

function sortStUnits(a, b) {
  if (!a.blocked && b.blocked) {
    return -1;
  } else if (a.blocked && !b.blocked) {
    return 1;
  }

  if (a.isUnlimitedQty && !b.isUnlimitedQty) {
    return -1;
  } else if (!a.isUnlimitedQty && b.isUnlimitedQty) {
    return 1;
  }

  return b.qty - a.qty;
}

export default function PartsGrid({ searchText, onLoaded }) {
  const [selectedPartIds, setSelectedPartIds] = useState([]);
  const [partsForCreateInOrder, setPartsForCreateInOrder] = useState(null);
  const [showInOrderId, setShowInOrderId] = useState(null);
  const [longErrorMessage, setLongErrorMessage] = useState(null);
  const localizedTextsMap = dataGridLang();
  const api = useApi();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(900));
  const gridClassName = isSmallScreen
    ? "dgSmall"
    : "stdVisible dgDynIconText shiftDatagridToolbar";

  const [searchParams] = useSearchParams();
  const filter = searchParams.get("filter");

  const loadParts = useCallback(
    async (queryModel) => {
      // console.log("loadParts", queryModel);
      const url = `${process.env.REACT_APP_MAIN_URL}warehouse/parts/get-page`;
      // const response = { rows: [], rowCount: 0 };
      const response = await api.fetch(url, queryModel, "POST");

      if (onLoaded) {
        onLoaded();
      }

      if (response && response.isSuccessful) {
        setSelectedPartIds([]); // LEt's clear the selection on data load
        const partsWithSortedStUnits = response.rows.map((part) => ({
          ...part,
          stUnits: part.stUnits?.sort(sortStUnits),
        }));
        return { rows: partsWithSortedStUnits, rowCount: response.rowCount };
      }
    },
    [api, onLoaded]
  );

  const { localUpdateRows, reloadData, resetPagination, ...pagination } =
    usePagination(
      loadParts,
      searchText,
      [
        {
          field: "partNo",
          sort: "asc",
        },
      ],
      null,
      [25, 50, 100],
      "PARTS_GRID",
      buildPaginationFilter(filter)
    );

  useEffect(() => {
    if (isSmallScreen) {
      // Most columns will not be available on small screens, so reset
      // the pagination, in case there was any saved state in session
      resetPagination();
    }
  }, [resetPagination, isSmallScreen]);

  const getDotMenuActions = useCallback((part, isMulti) => {
    const menudata = [];

    if (hasAccess(accessKeys.isMASystem)) {
      menudata.push({
        id: "create-in-order-single",
        hide: isMulti, // There is a multi-version of this in getMultiMenuActions
        icon: <ProductionQuantityLimitsIcon />,
        text: Translate.get("CreateInOrder"),
        onClick: () => setPartsForCreateInOrder([part]),
      });
    }

    return menudata;
  }, []);

  const getMultiMenuActions = useCallback((parts) => {
    const menudata = [];

    if (hasAccess(accessKeys.isMASystem)) {
      menudata.push({
        id: "create-in-order-multi",
        icon: <ProductionQuantityLimitsIcon />,
        text: Translate.get("CreateInOrder"),
        onClick: () => setPartsForCreateInOrder(parts),
      });
    }

    return menudata;
  }, []);

  const columns = useMemo(
    () => [
      {
        field: "partNo",
        flex: 0.3,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("PartNoLabel"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={Numbers}
            label={Translate.get("PartNoLabel")}
          />
        ),
      },
      {
        field: "description",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("PartLabel"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ShoppingBasketIcon}
            label={Translate.get("PartLabel")}
          />
        ),
      },
      {
        field: "needInOrder",
        flex: 0.3,
        type: "boolean",
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("PartNeedInOrderFilterLabel"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ProductionQuantityLimitsIcon}
            label={Translate.get("PartNeedInOrderFilterLabel")}
          />
        ),
        renderCell: (params) => (params.row.needInOrder ? <CheckIcon /> : null),
      },
      {
        field: "hasInOrder",
        flex: 0.3,
        type: "boolean",
        hide: true,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("PartHasInOrderFilterLabel"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={CheckBox}
            label={Translate.get("PartHasInOrderFilterLabel")}
          />
        ),
        renderCell: (params) => (params.row.needInOrder ? <CheckIcon /> : null),
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        hideable: false,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu content={getDotMenuActions(params.row, false)} horizontal />
        ),
      },
    ],
    [getDotMenuActions, isSmallScreen]
  );

  const easyFilters = useMemo(
    () => [
      {
        id: "default-need-in-order",
        name: Translate.get("PartNeedInOrderFilterLabel"),
        description: Translate.get("PartNeedInOrderFilterDescription"),
        filterModel: {
          items: [
            {
              id: 1003,
              field: "needInOrder",
              operator: "is",
              value: true,
            },
          ],
          logicOperator: "and",
        },
      },
      {
        id: "default-has-in-order",
        name: Translate.get("PartHasInOrderFilterLabel"),
        description: Translate.get("PartHasInOrderFilterDescription"),
        filterModel: {
          items: [
            {
              id: 1004,
              field: "hasInOrder",
              operator: "is",
              value: true,
            },
          ],
          logicOperator: "and",
        },
      },
    ],
    []
  );

  async function handleMultiDotMenuActionOnClick(actions) {
    const results = await Promise.all(
      actions.map(async (action) => {
        const result = await action.onClick();
        return { ...result, partId: action.partId };
      })
    );

    const failedResults = results.filter((r) => !r.isSuccessful);
    const failedResultsAndParts = failedResults.map((r) => ({
      result: r,
      part: pagination.rows.find((part) => part.partId === r.partId),
    }));
    if (failedResultsAndParts.length > 0) {
      const errorMessage = failedResultsAndParts
        .map(
          ({ result, part }) =>
            `${part.partNo}, ${part.description}:\n${Translate.get(
              result.errorMessageTranslationKey
            )}`
        )
        .join("\n\n");
      setLongErrorMessage(errorMessage);
    }

    if (results.some((r) => r.isSuccessful)) {
      setSelectedPartIds([]);
    }
  }

  function getSelectionActions() {
    const selectedParts = pagination.rows.filter((part) =>
      selectedPartIds.includes(part.partId)
    );

    // We get the normal per-part dot menu actions and group them
    // for simultaneous exection of each action individually
    const dotMenuActions = selectedParts
      .flatMap((part) =>
        getDotMenuActions(part, true).map((action) => ({
          ...action,
          partId: part.partId,
        }))
      )
      .filter((action) => !action.hide);
    const groupedActions = groupBy(dotMenuActions, (action) => action.id);
    const mergedActions = Object.entries(groupedActions).map(
      ([id, actions]) => ({
        icon: actions[0].icon,
        text: actions[0].text,
        onClick: async () => await handleMultiDotMenuActionOnClick(actions),
        disabled:
          actions.length !== selectedParts.length ||
          actions.some((a) => a.disabled),
        disabledTooltip: Translate.get("GroupActionUnavailableForSome"),
      })
    );

    // Then we also have some actions wich are specifically for the multi
    // scenario, i.e. these are not available in an individual parts dot menu
    const multiActions = getMultiMenuActions(selectedParts);
    return [...mergedActions, ...multiActions];
  }

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <PartDetailsPanel
        part={row}
        stUnits={row.stUnits}
        inOrders={row.inOrders}
        onShowInOrder={(inOrderId) => setShowInOrderId(inOrderId)}
      />
    ),
    []
  );
  // console.log("PartsGrid render pagination", pagination);
  return (
    <>
      {((partsForCreateInOrder && partsForCreateInOrder.length > 0) ||
        !!showInOrderId) && (
        <InOrderFormDialog
          inOrderId={showInOrderId}
          parts={partsForCreateInOrder}
          onClose={() => {
            setPartsForCreateInOrder(null);
            setShowInOrderId(null);
          }}
        />
      )}
      {hasAccess(accessKeys.isMASystem) && (
        <Box paddingTop={2} paddingLeft={1} paddingBottom={1}>
          <DataGridEasyFilters
            filters={easyFilters}
            currentFilterModel={pagination.filterModel}
            onSelectFilter={(filter) =>
              pagination.onFilterModelChange(filter?.filterModel)
            }
          />
        </Box>
      )}
      {selectedPartIds && selectedPartIds.length > 0 && (
        <Toolbar className="partToolbar">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box>
                {`${selectedPartIds.length} ${Translate.get("ePort2Selected")}`}
              </Box>
              <LinkMenu content={getSelectionActions()} />
            </Stack>
            <Tooltip title={Translate.get("DeselectAll")}>
              <IconButton onClick={() => setSelectedPartIds([])}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      )}
      <DataGrid
        className={gridClassName}
        columns={columns}
        disableSelectionOnClick
        getRowId={(row) => row.partId}
        autoHeight={true}
        componentsProps={{
          columnsPanel: { className: "customColumnPanel" },
          filterPanel: { className: "customfilterPanel" },
          panel: { className: "customPanel" },
        }}
        localeText={localizedTextsMap}
        disableDensitySelector={true}
        disableColumnMenu
        onRowSelectionModelChange={(newSelectedPartIds) => {
          setSelectedPartIds(newSelectedPartIds);
        }}
        rowSelectionModel={selectedPartIds}
        hideFooterSelectedRowCount
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => "auto"}
        slots={{
          detailPanelExpandIcon: KeyboardArrowRight,
          detailPanelCollapseIcon: KeyboardArrowDown,
          toolbar: GridToolbar,
          noRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
        }}
        initialState={{
          columns: {
            columnVisibilityModel: { hasInOrder: false },
          },
        }}
        // For some reason if I did {...pagination} then filters wouldn't work.
        // As soon as I set a filter it'd switch back to defaults. But adding
        // the props individually worked. It had something to do with pageSizeOptions
        // it seemed. If the first page was 12 or smaller it worked, but 13 or above did not
        filterMode={pagination.filterMode}
        sortingMode={pagination.sortingMode}
        paginationMode={pagination.paginationMode}
        filterModel={pagination.filterModel}
        sortModel={pagination.sortModel}
        onFilterModelChange={pagination.onFilterModelChange}
        onSortModelChange={pagination.onSortModelChange}
        onPaginationModelChange={pagination.onPaginationModelChange}
        rows={pagination.rows}
        rowCount={pagination.rowCount}
        loading={pagination.isLoading}
        pagination={pagination.pagination}
        pageSizeOptions={pagination.pageSizeOptions}
        paginationModel={pagination.paginationModel}
      />
      <AlertDialog
        titleText={Translate.get("SomethingFailed")}
        bodyText={longErrorMessage}
        buttonText={Translate.get("Ok")}
        open={!!longErrorMessage}
        onClose={() => {
          setLongErrorMessage(null);
          reloadData();
        }}
      />
    </>
  );
}
